import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Member,
  ProjectMembersIncludeDocument,
  useProjectRolesQuery,
  useSetProjectRoleForMembersMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import { Sidebar } from '@pro4all/shared/ui/sidebar';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';

import {
  EditRolesForm,
  FormValues,
} from '../components/EditRolesForm/EditRolesForm';

import { projectMembersIncludeProps } from './getIncludeProps';

interface Props {
  onClose: () => void;
  open: boolean;
}

export const EditRolesSidebar: React.FC<Props> = ({ open, onClose }) => {
  const { checkedRows, uncheckAllRows } = useTableCheck<Member>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [setProjectRoleForMembers] = useSetProjectRoleForMembersMutation();

  const {
    params: { projectId },
  } = useRouting();
  const { userId } = AuthService.getProfile();
  const { data, loading, error } = useProjectRolesQuery({
    skip: !open,
    variables: { id: projectId },
  });

  const {
    state: { items },
  } = useOptimisticResponseContext<Member>();

  const options = data?.project?.availableRoles?.map((role) => ({
    id: role.id,
    label: t(role.name),
    tooltip: t(`Api.project.roles.${role.name}`),
  }));

  const includesMe = Boolean(checkedRows.find((user) => user.id === userId));

  const onSubmit = async (values: FormValues) => {
    await setProjectRoleForMembers({
      variables: {
        members: checkedRows.map((member) => ({
          id: member.id,
          type: member.__typename === 'User' ? 0 : 1,
        })),
        projectId,
        roleId: values.role,
      },
    });

    const roletoAdd =
      data?.project?.availableRoles?.find((role) => role.id === values.role) ??
      null;

    const updatedItems = checkedRows
      .filter((member) => member.id !== userId)
      .map((member) => ({
        ...member,
        roles: roletoAdd
          ? [{ ...roletoAdd, permissions: member.roles[0]?.permissions }]
          : member.roles,
      }));

    const updatedItemsIds = updatedItems.map((item) => item.id);
    const itemsMinusUpdated = items.filter(
      (item) => !updatedItemsIds.includes(item.id)
    );
    client.writeQuery({
      data: {
        projectMembers: [...itemsMinusUpdated, ...updatedItems],
      },
      query: ProjectMembersIncludeDocument,
      variables: {
        ...projectMembersIncludeProps,
        projectId,
      },
    });

    enqueueSnackbar(t('Member roles updated'));
    onClose();
    uncheckAllRows();
  };

  return (
    <Sidebar onClose={onClose} open={open}>
      <ResponseWrapper error={error} isLoading={loading}>
        <Sidebar.Header title={t('Edit roles')} />

        {data && (
          <EditRolesForm
            includesMe={includesMe}
            onCancel={onClose}
            onSubmit={onSubmit}
            options={options}
          />
        )}
      </ResponseWrapper>
    </Sidebar>
  );
};
