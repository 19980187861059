import { useOfficeOnlineInfoQuery } from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';

import { DocumentActionProps } from '../../types';

export const useGetOfficeOnlineInfo = ({
  isOfficeExtension,
  selection,
}: Pick<DocumentActionProps, 'selection'> & {
  isOfficeExtension: boolean;
}) => {
  const hasReadOnlyFlag = useFeatureFlag('office-read-only');

  const { data, loading: loadingOfficeOnlineData } = useOfficeOnlineInfoQuery({
    fetchPolicy: 'network-only',
    skip: !selection || selection.length === 0 || !isOfficeExtension,
    variables: {
      id: selection[0]?.versionId || '',
    },
  });

  const { canOpen, editUrl, readOnly, viewUrl } = data?.officeOnlineInfo || {};

  const hideOficeAction =
    loadingOfficeOnlineData || !canOpen || (readOnly && !hasReadOnlyFlag);
  const officeActionUrl = readOnly ? viewUrl || '' : editUrl || '';

  return { hideOficeAction, officeActionUrl, readOnly: Boolean(readOnly) };
};
