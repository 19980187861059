import React from 'react';

import { useId } from '@pro4all/shared/hooks';

import { Dimensions, Position } from '../types';

import type { Rect } from './rect';
import * as Styled from './Shape.styles';
import { useRect } from './useRect';

export interface Props {
  canvas: Dimensions;
  children?: React.ReactNode;
  container: HTMLElement | null;
  dimensions: Dimensions;
  id?: string;
  onDimensionsChange: (dimensions: Dimensions) => void;
  onPositionChange: (position: Position) => void;
  position: Position;
  rect: Rect;
}

export const Shape: React.FC<Props> = ({
  canvas,
  children,
  container,
  id,
  rect,
  position,
  dimensions,
  onDimensionsChange,
  onPositionChange,
}) => {
  const _id = useId(id);

  const outOfBounds = (nextRect: Partial<Rect>) => {
    if (!nextRect) return false;

    return (
      (nextRect.bottom && nextRect.bottom > canvas.height) ||
      (nextRect.left && nextRect.left < 0) ||
      (nextRect.right && nextRect.right > canvas.width) ||
      (nextRect.top && nextRect.top < 0)
    );
  };

  const handleUpdate = (nextRect: Partial<Rect>) => {
    if (outOfBounds(nextRect)) return;

    rect.set(nextRect);

    // Check if position has changed
    const hasPositionChanged =
      rect.style.left !== position.x || rect.style.top !== position.y;

    // Check if dimensions have changed
    const hasDimensionsChanged =
      rect.style.height !== dimensions.height ||
      rect.style.width !== dimensions.width;

    // Only update position if it changed
    if (hasPositionChanged) {
      onPositionChange({
        x: rect.style.left,
        y: rect.style.top,
      });
    }

    // Only update dimensions if they changed
    if (hasDimensionsChanged) {
      onDimensionsChange({
        height: rect.style.height,
        width: rect.style.width,
      });
    }
  };

  const {
    move,
    resizeBottomLeft,
    resizeBottomRight,
    resizeTopLeft,
    resizeTopRight,
  } = useRect({
    container,
    rect,
    update: handleUpdate,
  });

  const style = {
    height: dimensions.height,
    left: position.x,
    top: position.y,
    width: dimensions.width,
  };

  return (
    <Styled.Guide aria-grabbed="false" {...move} id={_id} style={style}>
      {children}
      <Styled.Handle
        $position="topLeft"
        aria-grabbed="false"
        title="Resize top-left"
        {...resizeTopLeft}
      />
      <Styled.Handle
        $position="topRight"
        aria-grabbed="false"
        title="Resize top-right"
        {...resizeTopRight}
      />
      <Styled.Handle
        $position="bottomLeft"
        aria-grabbed="false"
        title="Resize bottom-left"
        {...resizeBottomLeft}
      />
      <Styled.Handle
        $position="bottomRight"
        aria-grabbed="false"
        title="Resize bottom-right"
        {...resizeBottomRight}
      />
    </Styled.Guide>
  );
};
