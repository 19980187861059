import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Text } from '@pro4all/shared/ui/typography';

export const InitialsTitle = styled(Text)`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

export const StyledBox = styled(Box)`
  && {
    width: 400px;
    overflow: auto;
    height: 100%;
  }
`;
