import { IdentityService } from '@pro4all/shared/api-services';
import { IdentityTagType, ProfilePictureEntity } from '@pro4all/shared/types';

export const getIdentityUrl = ({
  id,
  type,
}: {
  id: string;
  type: IdentityTagType;
}) => {
  const url =
    type === IdentityTagType.User
      ? IdentityService.getProfileUrl({
          id,
          profilePictureEntity: ProfilePictureEntity.User,
        })
      : type === IdentityTagType.Project
      ? IdentityService.getProfileUrl({
          id,
          profilePictureEntity: ProfilePictureEntity.Project,
        })
      : '';

  return url;
};
