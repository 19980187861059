import React from 'react';

import { Project } from '@pro4all/graphql';
import { IdentityTagType } from '@pro4all/shared/types';

import { IdentityTag } from './IdentityTag';

export const ProjectTag = ({ project }: { project: Project }) => {
  const { name, id } = project || {};
  return <IdentityTag id={id} label={name} type={IdentityTagType.Project} />;
};
