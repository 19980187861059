import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionProps } from '@pro4all/shared/types';
import { ActionBar } from '@pro4all/shared/ui/action-bar';

export const SidebarActionBar = ({
  sidebarActions,
}: {
  sidebarActions: ActionProps[];
}) => (
  <Box mt={2}>
    <ActionBar hideLabels mainActions={sidebarActions} />
  </Box>
);
