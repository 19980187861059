import React, { useMemo, useState } from 'react';
import { AutoResizer } from 'react-base-table';
import { useTranslation } from 'react-i18next';

import { useDocumentsContext } from '@pro4all/documents/ui/share';
import { Document, Folder } from '@pro4all/graphql';
import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  getLastVisitedProjectRoute,
  updateLsLastVisitedOrganizationFolder,
  updateLsLastVisitedProjectFolder,
  updateLsLastVisitedProjectRoute,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { StylingDefaults } from '@pro4all/shared/themes';
import { ExpandIcon } from '@pro4all/shared/ui/general';
import {
  ColumnProps,
  StyledTable,
  TableCell,
  useOptimisticResponseContext,
} from '@pro4all/shared/ui/table';
import { Text } from '@pro4all/shared/ui/typography';

import { useFolderPath } from '../utils/useFolderPath';

import { filterFolderTree } from './helpers/filterFolderTree';
import { FolderName } from './FolderName';
import { useFolderTreeContextInner } from './FolderTreeContext';
import { useFolderTreeContextOuter } from './FolderTreeProviderOuter';
import { LinkName } from './LinkName';
import { DMSItem, isFolder, TreeFolder } from './types';
import { getJustFoldersTree } from './useFolderTree';
import { useGetFilteredFolders } from './useGetFilteredFolders';

interface Props {
  allowDragAndDrop?: boolean;
  extraColumns?: ColumnProps<TreeFolder>[];
  navigateOnClick?: boolean;
  onContextMenu?: TreeFolder['onContextMenu'];
  onProjectLinkContextMenu?: TreeFolder['onProjectLinkContextMenu'];
  projectId?: string;
  rowHeight?: number;
  showHeaders?: boolean;
  showOnlyFolders?: boolean;
  userId?: string;
}

const columns = ({
  allowDragAndDrop,
  hasMoreOptions,
}: {
  allowDragAndDrop: boolean;
  hasMoreOptions: boolean;
}) => [
  {
    cellRenderer: TableCell,
    dataKey: 'name',
    flexGrow: 1,
    flexShrink: 1,
    key: 'name',
    minWidth: 265,
    render: (row: TreeFolder) =>
      isFolder(row) ? (
        <FolderName
          allowDragAndDrop={allowDragAndDrop}
          hasDocuments={row.hasDocuments}
          hasMoreOptions={hasMoreOptions}
          id={row.id}
          isParentOfSelected={row.isParentOfSelected}
          name={row.name}
          parentFolderId={row.parentFolderId}
          rowPath={row.path}
        />
      ) : (
        <LinkName name={row.name} />
      ),
    title: '',
    width: 0,
  },
];

const expandColumnKey = 'name';

const components = {
  ExpandIcon,
};

const expandIconProps = ({
  rowData: { hasSubFolders, id, isParentOfSelected },
}: {
  rowData: Folder & TreeFolder;
}) => ({
  expandable: hasSubFolders,
  id,
  isParentOfSelected,
});

export const Tree: React.FC<Props> = ({
  allowDragAndDrop = false,
  extraColumns = [],
  navigateOnClick = true,
  onContextMenu,
  onProjectLinkContextMenu,
  rowHeight = StylingDefaults.tableRowHeight,
  showHeaders = false,
  showOnlyFolders = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { openFolder } = useFolderPath();
  const { expandedRowKeys, toggle } = useFolderTreeContextInner();
  const { goTo, params } = useRouting();
  const {
    path,
    state: { folderTree, hideEmptyFolders },
  } = useFolderTreeContextOuter();
  const { projectId } = params;

  const [doubleClickTimeout, setDoubleClickTimeout] = useState<number>(null);

  const { setDocTableColumnSizes } = useDocumentsContext();

  const { resetInitialItems } = useOptimisticResponseContext<Document>() || {};

  const folderTreeFiltered = hideEmptyFolders
    ? filterFolderTree(folderTree)
    : folderTree;

  const folderTreeWithoutLinks = useMemo(
    () =>
      showOnlyFolders
        ? folderTreeFiltered.map((tree) => getJustFoldersTree(tree))
        : [],
    [folderTreeFiltered, showOnlyFolders]
  );

  const folderTreeWithoutLinksFiltered = useGetFilteredFolders(
    folderTreeWithoutLinks
  );

  const onClick = ({ rowData: item }: { rowData: DMSItem }) => {
    if (isFolder(item)) {
      if (navigateOnClick) {
        // If we click on a folder we have to update localStorage to store the last visited folder.
        // With that we can re-open the last visited folder when we navigate back to the folder view.
        // We do this both for organization and project context.
        if (projectId) {
          updateLsLastVisitedProjectFolder({
            params: { path: item.path },
            projectId: item.projectId,
            route: 'projectDocs',
          });
        } else {
          updateLsLastVisitedOrganizationFolder({
            params: { path: item.path },
          });
        }
        setDocTableColumnSizes(null);
        openFolder(item.path);
      }
    } else {
      updateLsLastVisitedProjectRoute({
        overwriteCurrentValue: false,
        projectId: item.projectId,
        route: 'projectWorkflows',
      });
      const { params, route } =
        getLastVisitedProjectRoute(item.projectId) || {};
      goTo(route || 'projectWorkflows', {
        params: { ...params, projectId: item.projectId },
      });
    }
  };

  /*  We have to use state and using window timeouts otherwise
  the corresponding id's will not be valid, and the timeout
  will not be canceled */
  const handleOnClick = ({ rowData }: { rowData: DMSItem }) => {
    resetInitialItems && resetInitialItems(); // This is for column filtering. To reset the 'itemsInitial' state prop.
    window.clearTimeout(doubleClickTimeout);
    setDoubleClickTimeout(
      window.setTimeout(() => {
        onClick({ rowData });
      }, 250)
    );
  };

  const handleDoubleClick = ({ rowData }: { rowData: DMSItem }) => {
    window.clearTimeout(doubleClickTimeout);
    toggle(rowData.id);
  };

  const handleContextMenu = ({
    event,
    rowData: dmsItem,
  }: {
    event: React.MouseEvent<Element, MouseEvent>;
    rowData: DMSItem;
  }) => {
    if (onContextMenu)
      if (isFolder(dmsItem)) {
        onContextMenu(event, dmsItem, dmsItem.path.split('/'));
      } else {
        onProjectLinkContextMenu(event, dmsItem, dmsItem.path.split('/'));
      }
  };

  return (
    <Box data-testid="tree" flex="1" minHeight="0">
      {!folderTreeFiltered?.length && (
        <Box display="flex" justifyContent="center" p={1}>
          <Text variant="body2">{t('No folders found')}</Text>
        </Box>
      )}
      <AutoResizer>
        {({ width, height }) => (
          <StyledTable
            clickable
            columns={[
              ...columns({
                allowDragAndDrop,
                hasMoreOptions: Boolean(onContextMenu),
              }),
              ...extraColumns,
            ]}
            components={components}
            data={
              showOnlyFolders
                ? folderTreeWithoutLinksFiltered
                : folderTreeFiltered
            }
            expandColumnKey={expandColumnKey}
            expandIconProps={expandIconProps}
            expandedRowKeys={expandedRowKeys}
            folderTree={folderTreeFiltered}
            headerHeight={showHeaders ? undefined : 0}
            height={height}
            margin={0}
            onRowExpand={({
              expanded,
              rowKey,
            }: {
              expanded: boolean;
              rowKey: string;
            }) => toggle(rowKey, expanded)}
            rowClassName={({ rowData }: { rowData: TreeFolder }) =>
              rowData.isSelected && path === rowData.path && 'row-selected'
            }
            rowEventHandlers={{
              onClick: handleOnClick,
              onContextMenu: handleContextMenu,
              onDoubleClick: handleDoubleClick,
            }}
            rowHeight={isMobile ? 40 : rowHeight}
            width={width - 4}
          />
        )}
      </AutoResizer>
    </Box>
  );
};
