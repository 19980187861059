import React from 'react';

import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const FolderSelectedWithDocuments: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16.3 8.73963V4.03906C16.3 3.75332 16.1858 3.50871 15.9573 3.30523C15.7288 3.10174 15.4542 3 15.1333 3H6.96667C6.64584 3 6.37118 3.10174 6.14271 3.30523C5.91424 3.50871 5.8 3.75332 5.8 4.03906V6.95833H6.96667V4.03906H15.1333V8.73963H16.3Z"
      fill="#1C1B1F"
    />
    <path
      d="M11.5498 8.19532H13.3833C13.5486 8.19532 13.6872 8.14553 13.799 8.04595C13.9108 7.94638 13.9667 7.82299 13.9667 7.67579C13.9667 7.52859 13.9108 7.4052 13.799 7.30562C13.6872 7.20604 13.5486 7.15626 13.3833 7.15626H10.2364C10.5935 7.32704 11.0432 7.63806 11.5498 8.19532Z"
      fill="#1C1B1F"
    />
    <path
      d="M13.3833 6.11719H8.71667C8.55139 6.11719 8.41285 6.0674 8.30104 5.96783C8.18924 5.86825 8.13334 5.74486 8.13334 5.59766C8.13334 5.45046 8.18924 5.32707 8.30104 5.22749C8.41285 5.12792 8.55139 5.07813 8.71667 5.07813H13.3833C13.5486 5.07813 13.6872 5.12792 13.799 5.22749C13.9108 5.32707 13.9667 5.45046 13.9667 5.59766C13.9667 5.74486 13.9108 5.86825 13.799 5.96783C13.6872 6.0674 13.5486 6.11719 13.3833 6.11719Z"
      fill="#1C1B1F"
    />
    <path d="M4 22C3.45 22 2.97917 21.811 2.5875 21.4331C2.19583 21.0553 2 20.601 2 20.0703V8.49217C2 7.9615 2.19583 7.50722 2.5875 7.12932C2.97917 6.75142 3.45 6.56248 4 6.56248H9.175C9.44167 6.56248 9.69583 6.61072 9.9375 6.7072C10.1792 6.80369 10.3917 6.94037 10.575 7.11726L12 8.49217H20C20.55 8.49217 21.0208 8.68111 21.4125 9.05901C21.8042 9.43691 22 9.89119 22 10.4219V20.0703C22 20.601 21.8042 21.0553 21.4125 21.4331C21.0208 21.811 20.55 22 20 22H4ZM4 20.0703H20V10.4219H11.175L9.175 8.49217H4V20.0703Z" />
  </SvgIcon>
);
