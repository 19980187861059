import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Box, Popover, Typography } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { ActionProps, IdentityTagType } from '@pro4all/shared/types';
import { AvatarSizeXS } from '@pro4all/shared/ui/avatar';
import { getInitials } from '@pro4all/shared/utils';

import { getIdentityUrl } from './getIdentityUrl';
import { GroupCard } from './GroupCard';
import { useGetUsersAction } from './useGetUsersAction';
import { UserCard } from './UserCard';

type IdentityAvatarProps = {
  id: string;
  label: string;
  totalUsers?: number;
  type: IdentityTagType;
};

export const IdentityAvatar = ({
  id,
  label,
  totalUsers,
  type,
}: IdentityAvatarProps) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);

  const { getShowUsersAction } = useGetUsersAction();
  const cardIcons: ActionProps[] = [
    getShowUsersAction({ id, totalUsers: totalUsers || 0 }),
  ];

  const url = getIdentityUrl({ id, type });

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
      }}
    >
      <Avatar
        onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) =>
          setAnchorEl(event.currentTarget)
        }
        onMouseLeave={() => setAnchorEl(null)}
        src={url}
        sx={{
          backgroundColor: customColors.grey500,
          fontSize: `${AvatarSizeXS.fontSize}px`,
          height: `${AvatarSizeXS.avatarSize}px`,
          width: `${AvatarSizeXS.avatarSize}px`,
        }}
      >
        <Typography sx={{ color: 'white' }}>
          {/* Somehow the color prop is overwritten, so we control it via `Typography` */}
          {getInitials({ fullName: label })}
        </Typography>
      </Avatar>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        id={id}
        open={open}
        sx={{ pointerEvents: 'none' }}
      >
        <Box sx={{ p: 2 }}>
          {type === IdentityTagType.Group ? (
            <GroupCard
              // cardIcons={cardIcons} TODO: re-implement this after BE has finished endpoints to fetch all direct and nested users for a group.
              group={{
                displayName: label,
                id,
                totalUsers,
              }}
            />
          ) : (
            <UserCard userId={id} />
          )}
        </Box>
      </Popover>
    </Box>
  );
};
