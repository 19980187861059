import { Redirect, Route } from 'react-router-dom';
import styled from 'styled-components';

import { Integration } from '@pro4all/graphql';
import { IntegrationsTable } from '@pro4all/settings/ui/src/integrations';
import {
  OrganizationDomains,
  OrganizationSettings,
} from '@pro4all/settings/ui/src/settings';
import {
  CancelSubscription,
  ChangeSubscription,
  CompanyDataRegistration,
  LandingPage,
  OrganizationSubscription,
} from '@pro4all/settings/ui/src/subscription';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { OrganizationOrganizationTabs } from '../components/tabs/OrganizationOrganizationTabs';
import { OrganizationProvisioning } from '../settings/src/lib/OrganizationProvisioning';
import { DocumentSettingsForm } from '../status-naming/src';

import { OrganizationTabContent } from './OrganizationTabContent';

export const OrganizationOrganization = () => {
  const { searchParams } = useRouting();

  const changeSubscriptionContext = searchParams.is(
    'action',
    'changeSubscription'
  );
  const cancelSubscriptionContext = searchParams.is(
    'action',
    'cancelSubscription'
  );
  const companyDataRegistrationContext = searchParams.is(
    'action',
    'companyDataRegistration'
  );

  const showLanding = searchParams.is('landing', 'true');

  const hasScim = useFeatureFlag('scim');

  return (
    <Main>
      <Column>
        <Route exact path={Routes.settingsOrganization}>
          <Redirect to={Routes.settingsOrganizationOrganization} />
        </Route>

        <Route path={Routes.settingsOrganizationOrganization}>
          <>
            <OrganizationOrganizationTabs />
            <OrganizationTabContent />
          </>
        </Route>

        <Route path={Routes.settingsOrganizationLicense}>
          {showLanding ? (
            <LandingPage />
          ) : changeSubscriptionContext ? (
            <ChangeSubscription />
          ) : cancelSubscriptionContext ? (
            <CancelSubscription />
          ) : companyDataRegistrationContext ? (
            <CompanyDataRegistration />
          ) : (
            <>
              <OrganizationOrganizationTabs />
              <OrganizationSubscription />
            </>
          )}
        </Route>

        <Route path={Routes.settingsOrganizationIntegrations}>
          <>
            <OrganizationOrganizationTabs />
            <OptimisticResponseProvider<Integration>>
              <IntegrationsTable />
            </OptimisticResponseProvider>
          </>
        </Route>

        <Route path={Routes.settingsOrganizationDocuments}>
          <>
            <OrganizationOrganizationTabs />
            <DocumentSettingsForm />
          </>
        </Route>

        <Route path={Routes.settingsOrganizationSettings}>
          <>
            <OrganizationOrganizationTabs />
            <OrganizationSettings />
          </>
        </Route>

        {hasScim && (
          <>
            <Route path={Routes.settingsOrganizationDomains}>
              <StyledBox>
                <OrganizationOrganizationTabs />
                <StyledBox maxWidth="850px">
                  <OrganizationDomains />
                </StyledBox>
              </StyledBox>
            </Route>

            <Route path={Routes.settingsOrganizationProvisioning}>
              <StyledBox>
                <OrganizationOrganizationTabs />
                <StyledBox maxWidth="850px">
                  <OrganizationProvisioning />
                </StyledBox>
              </StyledBox>
            </Route>
          </>
        )}
      </Column>
    </Main>
  );
};

export const StyledBox = styled(Box)`
  && {
    overflow: auto;
  }
`;
