import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import type { Group } from '@pro4all/graphql';
import {
  GroupIncludeDocument,
  GroupsIncludeDocument,
  useEditGroupMutation,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  EntityTypeTranslation,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { sortBy } from '@pro4all/shared/utils';

import { groupIncludeProps, groupsIncludeProps } from './getIncludeProps';
import { FormFields } from './Types';

export const useEditGroup = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { showMutationMessage } = useShowMessages();
  const [edit] = useEditGroupMutation();
  const { projectId } = useRouting().params;

  const {
    state: { items },
  } = useOptimisticResponseContext<Group>();

  const editGroup = async ({
    initialValues,
    onClose,
    group,
    values,
  }: {
    group: Group;
    initialValues: FormFields;
    onClose: () => void;
    values: FormFields;
  }) => {
    const { displayName: name, subgroups, members: memberOptions } = values;

    const selectedMemberIds = memberOptions.map((mo) => mo.id);

    const {
      displayName: initialName,
      subgroups: initialSubgroups,
      members: initialMemberOptions,
    } = initialValues;
    const initialMemberIds = initialMemberOptions.map((im) => im.id);

    const newName = name !== initialName ? name : null;

    const toUserInput = (id: string) => ({
      id,
      type:
        [...initialMemberOptions, ...memberOptions].find((mo) => mo.id === id)
          .type === 'Group'
          ? 1
          : 0,
    });

    const membersToAdd = memberOptions
      .filter(({ id }) => !initialMemberIds.includes(id))
      .map(({ id }) => toUserInput(id));
    const membersIdsToDelete = initialMemberIds.filter(
      (id) => !selectedMemberIds.includes(id)
    );
    const membersToDelete = membersIdsToDelete
      .map((id) => ({ displayName: '', id }))
      .map(({ id }) => toUserInput(id));

    const subgroupIds = subgroups.map((subgroup) => subgroup.id);
    const initialSubgroupIds = initialSubgroups.map((subgroup) => subgroup.id);
    const subgroupsToAdd = subgroupIds.filter(
      (subgroupId) => !initialSubgroupIds.includes(subgroupId)
    );
    const subgroupsToDelete = initialSubgroupIds.filter(
      (subgroupId) => !subgroupIds.includes(subgroupId)
    );

    try {
      const { data } = await edit({
        variables: {
          groupId: group.id,
          membersToAdd,
          membersToDelete,
          name: newName,
          subgroupsToAdd,
          subgroupsToDelete,
        },
      });
      if (data.editGroup.success) {
        showMutationMessage({
          action: MessageAction.Update,
          entityType: EntityTypeTranslation.OrganizationGroup,
          name,
        });

        const selectedGroup = items.find((item) => item.id === group.id);
        const updatedGroup = {
          ...selectedGroup,
          displayName: name,
          membersCount:
            membersToAdd || membersToDelete
              ? memberOptions.length
              : selectedGroup.members.length,
          subgroupIds:
            subgroupsToAdd || subgroupsToDelete
              ? subgroupIds
              : selectedGroup.subgroupIds,
        };
        const otherGroups = items.filter((item) => item.id !== group.id);
        const updatedItems = [...otherGroups, updatedGroup].sort(
          sortBy({ key: 'displayName' })
        );

        // Apollo cache update for the groups table.
        client.writeQuery({
          data: {
            groups: updatedItems,
          },
          query: GroupsIncludeDocument,
          variables: {
            ...groupsIncludeProps,
            projectId: projectId ? projectId : null,
          },
        });

        // Apollo cache update for the individual group.
        const membersUpdated = memberOptions.map((member) => ({
          __typename: 'User', //TODO: this can also be `Group` if it is an organization group. But we don't have that info here!!
          displayName: member.label,
          id: member.id,
        }));
        const subgroupsUpdated = subgroups.map((subgroup) => ({
          __typename: 'Group',
          displayName: subgroup.label,
          id: subgroup.id,
        }));

        const singleGroupUpdate = {
          displayName: name,
          id: group.id,
          members: membersUpdated,
          subgroups: subgroupsUpdated,
        };

        const variables = {
          ...groupIncludeProps,
          groupId: group.id,
          projectId: projectId ? projectId : undefined,
        };

        client.writeQuery({
          data: {
            group: singleGroupUpdate,
          },
          query: GroupIncludeDocument,
          variables,
        });
      } else {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
      }
      onClose();
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };

  return { editGroup };
};
