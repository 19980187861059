import React from 'react';

import { RenderCustomInputProps } from '@pro4all/shared/types';
import { IdentityTagType } from '@pro4all/shared/types';

import { IdentityTag } from './IdentityTag';

export const useIdentityTagJsx = () => {
  const getIdentityTagJsx = ({
    hasBorder,
    onDelete,
    option,
  }: RenderCustomInputProps) => {
    const { groupOption, id, label, type } = option;
    const { totalUsers } = groupOption || {};

    return (
      <IdentityTag
        hasBorder={hasBorder}
        id={id}
        label={label}
        onDelete={onDelete}
        totalUsers={totalUsers || 0}
        type={type as IdentityTagType}
      />
    );
  };

  return { getIdentityTagJsx };
};
