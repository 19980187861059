import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { Column } from '@pro4all/shared/ui/layout';

import * as Styled from './Stamper.styles';
import type { StamperDocument } from './store';
import { Status } from './store';
import { useStamper } from './useStamper';

export interface Props {
  children?: never;
  queue: StamperDocument[];
}

export const Stamper: React.FC<Props> = ({ queue = [] }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const [applyToAllPages, setApplyToAllPages] = useState(false);
  const [applyToAllDocuments, setApplyToAllDocuments] = useState(false);
  const {
    canvas,
    count,
    current,
    dimensions,
    handleDimensionsChange,
    handlePositionChange,
    hasQRCode,
    next,
    position,
    revokeAll,
    revokeOne,
    stampOne,
    status,
    total,
    stampAll,
    background,
  } = useStamper(queue, applyToAllPages);

  const goBack = useCallback(
    () => searchParams.delete('action'),
    [searchParams]
  );

  const hasMore = total > 1 && count < total;

  useEffect(() => {
    if (status === Status.Done) goBack();
  }, [goBack, status]);

  // Format display values to show only one decimal place
  const displayPosition = {
    x: Math.round(position.x * 10) / 10,
    y: Math.round(position.y * 10) / 10,
  };

  const displayDimensions = {
    height: Math.round(dimensions.height * 10) / 10,
    width: Math.round(dimensions.width * 10) / 10,
  };

  return (
    <Styled.BoxContainer>
      <Styled.StamperContainer>
        <Styled.Header>
          <Button
            color="inherit"
            onClick={goBack}
            startIcon="arrowBack"
            variant="outlined"
          >
            {t('Back')}
          </Button>

          <Styled.Title>{current?.name}</Styled.Title>

          {total > 1 && (
            <Box sx={{ mr: 1 }}>
              <Button
                color="inherit"
                disabled={!hasMore || !hasQRCode}
                onClick={revokeAll}
                startIcon="qrRemove"
                variant="outlined"
              >
                {t('Revoke remaining QR codes')}
              </Button>
            </Box>
          )}

          {(total > 1 || hasQRCode) && (
            <Box sx={{ mr: 1 }}>
              <Button
                color="inherit"
                disabled={!hasQRCode}
                onClick={revokeOne}
                startIcon="qrRemove"
                variant="outlined"
              >
                {t('Revoke QR code')}
              </Button>
            </Box>
          )}

          {total > 1 && count < total && (
            <Box sx={{ mr: 1 }}>
              <Button color="inherit" onClick={next} variant="outlined">
                {t('Skip')}
              </Button>
            </Box>
          )}

          <Box sx={{ mr: 1 }}>
            <Button
              disabled={!background.src}
              onClick={applyToAllDocuments ? stampAll : stampOne}
              startIcon="stamp"
              variant="contained"
            >
              {t(
                applyToAllDocuments || count >= total
                  ? 'Stamp QR code'
                  : 'Save and next'
              )}
            </Button>
          </Box>
        </Styled.Header>

        <Styled.CheckboxContainer>
          <FormControlLabel
            control={
              <Checkbox
                checked={applyToAllDocuments}
                onChange={(e) => setApplyToAllDocuments(e.target.checked)}
              />
            }
            label={t('Apply position to all documents')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={applyToAllPages}
                onChange={(e) => setApplyToAllPages(e.target.checked)}
              />
            }
            label={t('Apply position to all pages within a document')}
          />
        </Styled.CheckboxContainer>

        <Styled.CanvasContainer>
          <Column>{canvas} </Column>
        </Styled.CanvasContainer>
      </Styled.StamperContainer>

      <Box
        sx={{ borderLeft: `1px solid ${customColors.grey400}`, width: '360px' }}
      >
        <Styled.SectionTitle>{t('QR-Code configuration')}</Styled.SectionTitle>
        <Styled.SidebarSection>
          <Styled.SectionSubtitle>{t('Position (mm)')}</Styled.SectionSubtitle>
          <Styled.SectionContent>
            <Box sx={{ mr: 1 }}>
              <TextField
                fullWidth
                label={t('X')}
                name="x"
                onChange={handlePositionChange}
                onKeyPress={(event) => {
                  if (event?.key === '-' || event?.key === '+') {
                    event.preventDefault();
                  }
                }}
                type="number"
                value={displayPosition.x.toString()}
                variant="outlined"
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                label={t('Y')}
                name="y"
                onChange={handlePositionChange}
                type="number"
                value={displayPosition.y.toString()}
                variant="outlined"
              />
            </Box>
          </Styled.SectionContent>
        </Styled.SidebarSection>

        <Styled.SidebarSection>
          <Styled.SectionSubtitle>
            {t('Dimensions (mm)')}
          </Styled.SectionSubtitle>
          <Styled.SectionContent>
            <Box sx={{ mr: 1 }}>
              <TextField
                fullWidth
                label={t('Width')}
                name="width"
                onChange={handleDimensionsChange}
                type="number"
                value={displayDimensions.width.toString()}
                variant="outlined"
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                label={t('Height')}
                name="height"
                onChange={handleDimensionsChange}
                type="number"
                value={displayDimensions.height.toString()}
                variant="outlined"
              />
            </Box>
          </Styled.SectionContent>
        </Styled.SidebarSection>
      </Box>
    </Styled.BoxContainer>
  );
};
