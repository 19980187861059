import React, { useEffect, useState } from 'react';

import {
  MoveUserModel,
  useConfirmMoveExistingUserMutation,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

import { ActiveSubscriptionFound } from './ActiveSubscriptionFound';
import { AlreadyMoved } from './AlreadyMoved';
import { JoinedNewOrganization } from './JoinedNewOrganization';
import { LastAdmin } from './LastAdmin';
import { MoveInvitationExpired } from './MoveInvitationExpired';
import { MoveToNewOrganization } from './MoveToNewOrganization';
import { MoveUserState } from './types';
import { UnexpectedError } from './UnexpectedError';

export const MoveUser = () => {
  const { token } = useRouting().params;
  const [confirmMoveExistingUser] = useConfirmMoveExistingUserMutation();
  const [moveData, setMoveData] = useState<MoveUserModel>({
    invitedUserId: '',
    invitedUserOrganizationId: '',
    invitedUserOrganizationName: '',
    inviterEmail: '',
    inviterName: '',
    newOrganizationId: '',
    newOrganizationName: '',
    state: MoveUserState.None,
  });

  const updateMoveState = (newState: MoveUserState): void =>
    setMoveData({ ...moveData, state: newState });

  const {
    invitedUserId,
    invitedUserOrganizationId,
    invitedUserOrganizationName,
    inviterEmail,
    inviterName,
    newOrganizationId,
    newOrganizationName,
    state,
  } = moveData;

  useEffect(() => {
    if (!token) return;

    sessionStorage.setItem(StorageKeys.MOVE_ORG_TOKEN, token);

    const confirmEmail = async () => {
      try {
        const result = await confirmMoveExistingUser({
          variables: {
            token,
          },
        });

        const {
          invitedUserId,
          invitedUserOrganizationId,
          invitedUserOrganizationName,
          inviterEmail,
          inviterName,
          newOrganizationId,
          newOrganizationName,
          state,
        } = result.data.confirmMoveExistingUser;

        setMoveData({
          invitedUserId,
          invitedUserOrganizationId,
          invitedUserOrganizationName,
          inviterEmail,
          inviterName,
          newOrganizationId,
          newOrganizationName,
          state,
        });
      } catch (e) {
        console.log(`error: ${e}`);
        setMoveData({
          invitedUserId,
          invitedUserOrganizationId,
          invitedUserOrganizationName,
          inviterEmail,
          inviterName,
          newOrganizationId,
          newOrganizationName,
          state: MoveUserState.Error,
        });
      }
    };
    confirmEmail();
  }, [
    confirmMoveExistingUser,
    inviterEmail,
    inviterName,
    invitedUserId,
    invitedUserOrganizationId,
    invitedUserOrganizationName,
    newOrganizationId,
    newOrganizationName,
    token,
  ]);

  switch (state) {
    case MoveUserState.ActiveSubscriptionFound:
      return (
        <ActiveSubscriptionFound
          currentOrganizationName={invitedUserOrganizationName}
        />
      );
    case MoveUserState.AlreadyMoved:
      return <AlreadyMoved newOrganizationName={newOrganizationName} />;
    case MoveUserState.EligibleForMove:
      return (
        <MoveToNewOrganization
          currentOrganizationName={invitedUserOrganizationName}
          inviterEmail={inviterEmail}
          inviterName={inviterName}
          newOrganizationName={newOrganizationName}
          updateMoveState={updateMoveState}
        />
      );
    case MoveUserState.LastAdmin:
      return (
        <LastAdmin currentOrganizationName={invitedUserOrganizationName} />
      );
    case MoveUserState.MoveInvitationExpired:
      return (
        <MoveInvitationExpired newOrganizationName={newOrganizationName} />
      );
    case MoveUserState.Error:
      return <UnexpectedError />;
    case MoveUserState.MoveSuccesful:
      return (
        <JoinedNewOrganization newOrganizationName={newOrganizationName} />
      );
    default:
      return null;
  }
};
