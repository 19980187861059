export const getInitials = ({
  fullName,
  noOfCharacters = 2,
}: {
  fullName: string;
  noOfCharacters?: number;
}) => {
  if (!fullName) {
    return '-';
  }

  // Take only the part in front of the e-mailadres.
  const nameOnly = fullName.split(/[<(@]/)[0].trim();

  // Split the name in words.
  const names = nameOnly.split(/\s+/);

  const charactersToPrint =
    noOfCharacters > fullName.length ? fullName.length : noOfCharacters;

  if (names.length < charactersToPrint) {
    if (names[0].length >= charactersToPrint) {
      // Return the first two character of the word.
      return names[0].substring(0, charactersToPrint).toUpperCase();
    } else if (names.length > 0) {
      return names[0].charAt(0).toUpperCase();
    } else {
      return '-';
    }
  }

  const firstInitial = names[0].charAt(0).toUpperCase();
  const lastInitial = names[names.length - 1].charAt(0).toUpperCase();

  return firstInitial + lastInitial;
};
