import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  QcPermissionCategory,
  Status,
  Task as TaskType,
  TaskType as TaskTypeType,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  QcAndTaskPermissionsType,
  useQCPermissions,
} from '@pro4all/quality-control/data-access';
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { getPermissionPrefix } from '@pro4all/workflow/ui/utils';

import { TaskQuickActions } from '../task-quick-actions/TaskQuickActions';

interface Props {
  hideProject?: boolean;
  hideUser?: boolean;
  onSelectTask: (task: TaskType) => void;
  task: TaskType;
}

export const TaskListItemMobile: React.FC<Props> = ({
  hideProject = true,
  hideUser = false,
  task,
  onSelectTask,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { userId } = useOrganizationContext();

  const permissions = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    taskProjectId: task?.project?.id,
  });

  const showTaskQuickActions = () => {
    const typeActionPrefix = getPermissionPrefix(
      'Update',
      task?.type ?? TaskTypeType.QualityControl
    );
    const all =
      permissions[`${typeActionPrefix}All` as keyof QcAndTaskPermissionsType];
    const ownAssigned =
      permissions[
        `${typeActionPrefix}OwnAssigned` as keyof QcAndTaskPermissionsType
      ];
    const createdByOrAssignedToUser =
      task?.assignment?.[0]?.id === userId ||
      task?.createdByUser?.id === userId;
    return all || (ownAssigned && createdByOrAssignedToUser);
  };

  const TaskIcon = (
    <Icon
      iconName={
        task.type === TaskTypeType.QualityControl
          ? 'qcTask'
          : task.type === TaskTypeType.Tbq
          ? 'tbqBrandcheck'
          : 'file'
      }
      style={{ marginRight: theme.spacing(1) }}
    />
  );

  function getColorForStatus(status: Status) {
    switch (status) {
      case 'Done':
        return theme.palette.success.main;
      case 'InProgress':
        return theme.palette.primary.main;
      case 'Paused':
        return theme.palette.warning.main;
      default:
        return theme.palette.grey[500];
    }
  }

  return (
    <ListItem dense disablePadding divider onClick={() => onSelectTask(task)}>
      <ListItemButton dense>
        <ListItemIcon>{TaskIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography
                color="text.primary"
                sx={{ width: '100%' }}
                variant="body1"
              >
                <MiddleEllipsis
                  text={task.name || ''}
                  width="100"
                ></MiddleEllipsis>
              </Typography>
            </Box>
          }
          secondary={
            <>
              {!hideProject && task?.project?.name && (
                <Typography variant="body2">{task?.project?.name}</Typography>
              )}
              {!hideUser && task.assignment && task.assignment.length > 0 ? (
                <Typography variant="body2">
                  {task.assignment
                    .map((user) => user?.displayName)
                    .filter(Boolean)
                    .join(', ') || t('No user')}
                </Typography>
              ) : (
                '-'
              )}
              <Box sx={{ display: 'flex' }}>
                {task.endTime ? (
                  <Typography>{dayjs(task.endTime).format('ll')}</Typography>
                ) : (
                  <Typography>{t('No date')}</Typography>
                )}
                {task.status ? (
                  <Typography
                    sx={{
                      color: getColorForStatus(task.status),
                      marginLeft: 1,
                    }}
                  >{`${task.status}`}</Typography>
                ) : null}
              </Box>
            </>
          }
        />
        <IconButton edge="end">
          {showTaskQuickActions() && <TaskQuickActions task={task} />}
        </IconButton>
      </ListItemButton>
    </ListItem>
  );
};

export default TaskListItemMobile;
