import { usePhasesBuild12Query } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { sortBy } from '@pro4all/shared/utils';

import { useShareBuild12Context } from './ShareBuild12Context';

export const useGetProjectPhaseOptions = () => {
  const { selectedConnection } = useShareBuild12Context();
  const { params } = useRouting();
  const { projectId } = params;

  const { data } = usePhasesBuild12Query({
    fetchPolicy: 'cache-and-network',
    skip: !selectedConnection,
    variables: {
      connectionId: selectedConnection || '',
      projectId,
    },
  });

  const projectPhaseOptions =
    data?.phasesBuild12?.map((phase) => ({
      id: phase.id,
      label: phase.name,
    })) ?? [];

  return {
    projectPhaseOptions: [
      ...projectPhaseOptions.sort(sortBy({ key: 'label' })),
    ],
  };
};
