import React, { useMemo } from 'react';

import { FieldDefinition, ValueTypeName } from '@pro4all/graphql';
import { showField, unnestInstance } from '@pro4all/metadata/ui/utils';
import { List } from '@pro4all/shared/ui/list';
import { PropertyList } from '@pro4all/shared/ui/property-list';

import { AnswerEdit } from './AnswerEdit';
import { AnswerView } from './AnswerView';
import { DisplayField } from './DisplayField';
import { DisplaySection } from './DisplaySection';
import { FieldsProps } from './SectionsAndFieldsList.types';
import { useGetValues } from './useGetValues';
import { useSetFields } from './useSetFields';

export const SectionsAndFieldsList: React.FC<FieldsProps> = ({
  Edit = AnswerEdit,
  View = AnswerView,
  editMode = false,
  fields,
  hideExcludedTypes = false,
  isDirty,
  isTBQ,
  linkedSnags,
  padding = false,
  root = true,
  level = 0,
  refetchQualityInstance,
  onSubmit,
  searchTerm,
  unnestedItems = unnestInstance(fields as FieldDefinition[]),
  sectionPercentageCounter,
}) => {
  const { conditionFieldsValues, values } = useGetValues({ fields });
  const { tbqInstance } = useSetFields({ fields, unnestedItems });

  const sectionsAndFields = useMemo(
    () => (
      <List item={PropertyList.Item}>
        {(fields ?? []).map((field) =>
          field.type === ValueTypeName.Section
            ? field?.valueType?.subFields?.length > 0 &&
              showField({ field, items: unnestedItems, values }) && (
                <DisplaySection
                  Edit={Edit}
                  View={View}
                  editMode={editMode}
                  field={field}
                  hideExcludedTypes={hideExcludedTypes}
                  isDirty={isDirty}
                  isTBQ={isTBQ}
                  key={`section-${field.id}`}
                  level={level}
                  linkedSnags={linkedSnags}
                  onSubmit={onSubmit}
                  refetchQualityInstance={refetchQualityInstance}
                  root={root}
                  searchTerm={searchTerm}
                  sectionPercentageCounter={sectionPercentageCounter}
                  unnestedItems={unnestedItems}
                />
              )
            : showField({
                field,
                hideExcludedTypes,
                items: unnestedItems,
                values,
              }) && (
                <DisplayField
                  Edit={Edit}
                  View={View}
                  editMode={editMode}
                  field={field}
                  key={`field-${field.id}`}
                  linkedSnags={linkedSnags}
                  padding={padding}
                  refetchQualityInstance={refetchQualityInstance}
                  tbqInstance={tbqInstance}
                />
              )
        )}
      </List>
    ),
    [linkedSnags, conditionFieldsValues, editMode]
  );
  return sectionsAndFields;
};
