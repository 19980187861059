import React from 'react';

import {
  Group,
  Member,
  useGroupsAndUsersIncludeQuery,
  User,
} from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { getMultiValue } from '@pro4all/shared/qc-meta';
import { useRouting } from '@pro4all/shared/routing-utils';
import { IdentityTagType } from '@pro4all/shared/types';
import { IdentityTag } from '@pro4all/shared/ui/identity-card';

import type { AnswerComponent } from '../AnswerView';

export const UserSelection: AnswerComponent = ({ children }) => {
  const { params } = useRouting();

  const { data } = useGroupsAndUsersIncludeQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      includeEmail: true,
      includeIsAdmin: true,
      includeOrganization: true,
      projectId: params.projectId,
    },
  });
  const members = (data?.groupsAndUsers as Member[]) || [];

  if (!children) return null;

  const items = getMultiValue(children);

  const filteredMembers = members.filter((member) => items.includes(member.id));

  return filteredMembers.length && children ? (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {filteredMembers.map((member) => {
        const isGroup = member.__typename === 'Group';
        if (isGroup) {
          const group = member as unknown as Group;
          const { displayName, id } = group || {};
          return (
            <IdentityTag
              hasBorder
              id={id}
              label={displayName}
              type={IdentityTagType.Group}
            />
          );
        } else {
          const user = member as unknown as User;
          const { displayName, id } = user || {};
          return (
            <IdentityTag
              hasBorder
              id={id}
              label={displayName}
              type={IdentityTagType.User}
            />
          );
        }
      })}
    </Box>
  ) : null;
};
