import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import {
  Group,
  Member,
  useAllowedGroupsIncludeQuery,
  useOrganizationUsersIncludeQuery,
  useProjectMembersIncludeQuery,
} from '@pro4all/graphql';
import { useMemberOptions } from '@pro4all/identity/ui';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Alert } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { FormFooter } from '@pro4all/shared/ui/form';
import { FormikForm, FormikInput } from '@pro4all/shared/ui/formik';
import {
  useSelectGroupsDropdownSources,
  useSelectUsersAndGroupsDropdownSources,
} from '@pro4all/shared/ui/identity-card';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { getInitialValues } from './getInitialValues';
import { GroupSelect } from './GroupSelect';
import { MemberSelect } from './MemberSelect';
import { useGroupFormConfig } from './useGroupFormConfig';
import { useSubmitGroupForm } from './useSubmitGroupForm';

interface Props {
  group?: Group;
  onClose: () => void;
}

export const GroupForm: React.FC<Props> = ({ group, onClose }) => {
  const { t } = useTranslation();

  const { projectId } = useRouting().params;
  const inputRef = useRef(null);

  const includeProps = {
    includeActive: true,
    includeEmail: true,
    includeInvited: true,
  };

  const { data: dataProject, loading: loadingProject } =
    useProjectMembersIncludeQuery({
      fetchPolicy: 'cache-and-network',
      skip: !projectId,
      variables: {
        ...includeProps,
        projectId,
      },
    });

  const { data: dataOrg, loading: loadingOrg } =
    useOrganizationUsersIncludeQuery({
      fetchPolicy: 'cache-and-network',
      skip: Boolean(projectId),
      variables: {
        ...includeProps,
      },
    });

  const loading = projectId ? loadingProject : loadingOrg;

  const members =
    (projectId ? dataProject?.projectMembers : dataOrg?.organizationUsers) ||
    [];

  const options = useMemberOptions(members, {
    includeNotInvited: false,
  });

  const {
    noOptionsText,
    optionsToPassIn,
    renderCustomOption,
    renderCustomInput,
  } = useSelectUsersAndGroupsDropdownSources({
    options,
  });

  const { data: dataGroups, loading: loadingGroups } =
    useAllowedGroupsIncludeQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        groupId: group?.id,
        projectId,
      },
    });

  const optionsGroups: Option[] = (dataGroups?.allowedGroups || []).map(
    (group) => ({
      iconName: 'group',
      id: group.id,
      label: group.displayName,
    })
  );

  const {
    noOptionsText: noOptionsTextGroups,
    optionsToPassIn: optionsToPassInGroups,
    renderCustomOption: renderCustomOptionsGroups,
  } = useSelectGroupsDropdownSources({
    members: (dataGroups?.allowedGroups as Member[]) || [],
  });

  const { renderCustomInput: renderCustomInputGroups } =
    useSelectUsersAndGroupsDropdownSources({
      options: optionsGroups,
    });

  const submitGroupForm = useSubmitGroupForm(group);
  const initialValues = getInitialValues(group);

  const { getField, validationSchema } = useGroupFormConfig();
  const nameField = getField('displayName');

  return (
    <Formik
      initialValues={initialValues}
      onReset={() => inputRef.current.focus()}
      onSubmit={async (values) => {
        submitGroupForm({
          initialValues,
          onClose,
          values,
        });
      }}
      validationSchema={validationSchema}
    >
      {({ errors, isSubmitting, dirty }) => (
        <FormikForm>
          <FormWrapper>
            <FormikInput
              autoFocus
              inputRef={inputRef}
              label={nameField.label}
              name={nameField.name}
              placeholder={t('Name')}
            />
            <MemberSelect
              loading={loading}
              noOptionsText={noOptionsText}
              options={optionsToPassIn}
              renderCustomInput={renderCustomInput}
              renderCustomOption={renderCustomOption}
            />
            <GroupSelect
              loading={loadingGroups}
              noOptionsText={noOptionsTextGroups}
              options={optionsToPassInGroups}
              renderCustomInput={renderCustomInputGroups}
              renderCustomOption={renderCustomOptionsGroups}
            />
            <Alert severity="info" variant="filled">
              {t(
                'When a user is added/deleted to/from a group with specific permissions, it may take up to 5 minutes for those permissions to be applied and visible.'
              )}
            </Alert>
          </FormWrapper>
          <FormFooter
            disableSubmit={isSubmitDisabled({
              dirty,
              errors,
              isSubmitting,
            })}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
