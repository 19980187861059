import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { FieldDefinition, TemplateType, ValueTypeName } from '@pro4all/graphql';
import { DndField, DndTypes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { ContextMenuInstance } from '@pro4all/shared/ui/context-menu-instance';
import { Icon } from '@pro4all/shared/ui/icons';
import { Switch } from '@pro4all/shared/ui/inputs';
import { Text } from '@pro4all/shared/ui/typography';

import { useMetaDataContext } from '../../views/MetaDataContext';

import { ConditionButton } from './ConditionButton';
import { FieldCustomCard } from './FieldCustomCard';
import { NewCards } from './NewCards';
import * as Styled from './Section.styles';
import {
  StyledActions,
  StyledDropZone,
  StyledMarginBottomZone,
  StyledToggleZone,
} from './Styles';
import { useFieldContext } from './TemplateMutationContext';
import { useSectionMenuItems } from './useSectionMenuItems';

export const Section = ({
  displayNames,
  parentSection,
  section,
  filterInvalidConditions,
  setToggleIndicativeField,
  isMetaDataDms,
}: {
  displayNames: boolean;
  filterInvalidConditions: (fieldDefinition: FieldDefinition) => void;
  isMetaDataDms: boolean;
  parentSection?: FieldDefinition;
  section: FieldDefinition;
  setToggleIndicativeField?: (value: string) => void;
}) => {
  const linkSnagToSection = useFeatureFlag('link-snag-to-section');
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const { insertField, editField } = useFieldContext();
  const { templateType } = useMetaDataContext();

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
    item: { fieldDefinition: section, move: true },
    type: DndTypes.INCLUDED_SECTION,
  });

  const [{ hoversOverCurrent }, drop] = useDrop({
    accept: [
      DndTypes.INCLUDED_FIELD,
      DndTypes.INCLUDED_SECTION,
      DndTypes.NOT_INCLUDED_FIELD,
    ],
    collect: (monitor) => ({
      hoversOverCurrent: Boolean(monitor.isOver({ shallow: true })),
    }),
    drop: (field: DndField, monitor) => {
      // If the source was dropped on one of the child fields, don't return a thing.
      if (!monitor.didDrop()) {
        // Drop of an field or section.
        insertField({
          fieldToInsert: field.fieldDefinition,
          move: field.move,
          nextField: section,
          parentSection,
        });
      }
    },
  });

  const { sectionMenuItems } = useSectionMenuItems({
    filterInvalidConditions,
    section,
  });

  if (isDragging) return null;

  const { displayName, id, name } = section;
  const isReusable = false; // TODO: for now we only have inline sections.

  return drag(
    drop(
      <div>
        {hoversOverCurrent && <StyledDropZone />}
        <Styled.StyledSection>
          <Styled.Header
            expanded={expanded}
            isReusable={false}
            onClick={() => setExpanded(!expanded)}
          >
            <Styled.Title>
              <Text variant="h5">
                <Styled.StyledIcon iconName="sectionTwoBars" />
                {isReusable ? (displayNames ? displayName : name) : displayName}
              </Text>
            </Styled.Title>
            <StyledActions>
              {linkSnagToSection &&
                (templateType === TemplateType.Form ||
                  templateType === TemplateType.Tbq) && (
                  <StyledToggleZone
                    onClick={(e) => {
                      e.stopPropagation();
                      editField({
                        ...section,
                        linksAllowed: !section.linksAllowed,
                      });
                    }}
                  >
                    <Switch checked={section.linksAllowed} />
                    {t('Place snags ')}
                  </StyledToggleZone>
                )}
              <div onClick={(event) => event.stopPropagation()}>
                <ConditionButton sectionId={id} />
              </div>
              <ContextMenuInstance
                disableBorder
                menuItems={sectionMenuItems}
                name={section.name}
              />
              <Styled.ExpandCollapse>
                <Icon iconName={expanded ? 'expandLess' : 'expandMore'} />
              </Styled.ExpandCollapse>
            </StyledActions>
          </Styled.Header>
          {expanded && (
            <Styled.Content>
              {section?.valueType?.subFields?.map((subField) =>
                subField.type === ValueTypeName.Section ? (
                  <Section
                    displayNames={displayNames}
                    filterInvalidConditions={filterInvalidConditions}
                    isMetaDataDms={isMetaDataDms}
                    key={subField.id}
                    parentSection={section}
                    section={subField}
                    setToggleIndicativeField={setToggleIndicativeField}
                  />
                ) : (
                  <FieldCustomCard
                    displayNames={displayNames}
                    fieldDefinition={subField}
                    filterInvalidConditions={filterInvalidConditions}
                    isMetaDataDms={isMetaDataDms}
                    key={subField.id}
                    parentSection={section}
                    setToggleIndicativeField={setToggleIndicativeField}
                  />
                )
              )}
              <NewCards parentSection={section} />
            </Styled.Content>
          )}
        </Styled.StyledSection>
        <StyledMarginBottomZone />
      </div>
    )
  );
};
