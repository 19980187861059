import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  ScopeType,
  Template,
  useDeleteTemplatesMutation,
} from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  useOptimisticResponseContext,
  useTableCheck,
} from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';

export const useDeleteTemplates = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteTemplates] = useDeleteTemplatesMutation();
  const { checkedRows, uncheckAllRows } = useTableCheck<Template>();
  const { templateType, templateService } = useMetaDataContext();
  const { entity } =
    featureConfig[templateService]?.templateTypes[templateType] ?? {};
  const { track } = useAnalytics();
  const { deleteItems } = useOptimisticResponseContext<Template>();

  const {
    params: { projectId },
  } = useRouting();
  const isOrganizationLevel = Boolean(!projectId);

  return async () => {
    // Determine the IDs to delete based on the scope
    const ids = checkedRows
      .filter(
        (item) => isOrganizationLevel || item.scope.type === ScopeType.Project
      )
      .map((item) => item.id);

    try {
      track(TrackingEvent.MetadataTemplateDeleted, {
        setsDeleted: ids.length,
      });
      await deleteTemplates({
        variables: { ids, templateService },
      });
      deleteItems(ids);
      enqueueSnackbar(t(`Selected ${entity}s deleted`));
      uncheckAllRows();
    } catch (e) {
      enqueueSnackbar(
        `${t('Something went wrong')}. ${t('Please try again')}.`
      );
    }
  };
};
