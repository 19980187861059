import React from 'react';
import styled from 'styled-components';

import { Avatar as MUIAvatar } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { getInitials } from '@pro4all/shared/utils';

import {
  AvatarProps,
  AvatarSize,
  AvatarSizeL,
  AvatarSizeM,
  AvatarSizeS,
  AvatarSizeXL,
  AvatarSizeXS,
  AvatarSizeXXL,
} from './Avatar.types';

export const Avatar: React.FC<AvatarProps> = ({
  name,
  noOfCharacters = 2,
  size = AvatarSize.AvatarSizeS,
  src,
}) => {
  let avatarSize = 32;
  let fontSize = 16;

  switch (size) {
    case AvatarSize.AvatarSizeXS:
      avatarSize = AvatarSizeXS.avatarSize;
      fontSize = AvatarSizeXS.fontSize;
      break;
    case AvatarSize.AvatarSizeS:
      avatarSize = AvatarSizeS.avatarSize;
      fontSize = AvatarSizeS.fontSize;
      break;
    case AvatarSize.AvatarSizeM:
      avatarSize = AvatarSizeM.avatarSize;
      fontSize = AvatarSizeM.fontSize;
      break;
    case AvatarSize.AvatarSizeL:
      avatarSize = AvatarSizeL.avatarSize;
      fontSize = AvatarSizeL.fontSize;
      break;
    case AvatarSize.AvatarSizeXL:
      avatarSize = AvatarSizeXL.avatarSize;
      fontSize = AvatarSizeXL.fontSize;
      break;
    case AvatarSize.AvatarSizeXXL:
      avatarSize = AvatarSizeXXL.avatarSize;
      fontSize = AvatarSizeXXL.fontSize;
      break;
    default:
  }

  return (
    <StyledAvatar
      $avatarSize={`${avatarSize}px`}
      $fontSize={`${fontSize}px`}
      src={src}
    >
      {getInitials({ fullName: name || '', noOfCharacters })}
    </StyledAvatar>
  );
};

const StyledAvatar = styled(MUIAvatar)<{
  $avatarSize: string;
  $fontSize: string;
}>`
  && {
    background-color: ${customColors.grey500};
    color: ${customColors.white};
    font-size: ${({ $fontSize }) => $fontSize};
    height: ${({ $avatarSize }) => $avatarSize};
    width: ${({ $avatarSize }) => $avatarSize};
  }
`;
