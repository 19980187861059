import React from 'react';
import styled from 'styled-components';

import { Document, DocumentVersion, Folder } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Icon } from '@pro4all/shared/ui/icons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';

const StyledSpan = styled.span<{ isExpected: boolean }>`
  display: flex;
  align-items: center;

  /* because this is a flex child and we want it to expand to
  * its parent */
  min-width: 0;

  ${({ isExpected }) => isExpected && `color: ${customColors.grey500};`}

  .MuiSvgIcon-root {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

interface Props {
  document: Document | DocumentVersion;
  folder?: Folder;
}

const DocumentNameComponent: React.FC<Props> = ({ document, folder }) => {
  const theme = useTheme();
  const { isExpected, name } = document;
  const showWarning =
    !isExpected &&
    folder?.metadataSetId &&
    document.metaData &&
    !document.metaData?.isValid;

  return (
    <StyledSpan isExpected={isExpected}>
      {showWarning && (
        <Icon htmlColor={theme.palette.warning.main} iconName="warning" />
      )}
      <MiddleEllipsis endLength={9} text={name} />
    </StyledSpan>
  );
};

const skipUpdate = (prevProps: Props, nextProps: Props) => {
  const prevMetadataTemplateId = prevProps.folder?.metadataSetId;
  const prevIsValid = prevProps.document?.metaData?.isValid;
  const prevIsExpected = prevProps.document?.isExpected;
  const prevName = prevProps.document?.name;

  const nextMetadataTemplateId = nextProps.folder?.metadataSetId;
  const nextIsValid = nextProps.document?.metaData?.isValid;
  const nextIsExpected = nextProps.document?.isExpected;
  const nextName = nextProps.document?.name;

  return (
    prevMetadataTemplateId === nextMetadataTemplateId &&
    prevIsValid === nextIsValid &&
    prevIsExpected === nextIsExpected &&
    prevName === nextName
  );
};

export const DocumentName = React.memo(DocumentNameComponent, skipUpdate);
