import { Folder } from '@pro4all/graphql';

export const getParentFolders = ({
  folderId,
  folders,
}: {
  folderId: string;
  folders: Folder[];
}) => {
  const folderMap = new Map(folders.map((folder) => [folder.id, folder])); // Quick access to folders via id.
  const parentFolders: Folder[] = [];

  let currentFolder = folderMap.get(folderId);

  while (currentFolder && currentFolder.parentFolderId) {
    const parentFolder = folderMap.get(currentFolder.parentFolderId);
    if (parentFolder) {
      parentFolders.push(parentFolder);
    }
    currentFolder = parentFolder; // Continu with the parent.
  }

  return parentFolders;
};
