import React from 'react';

import {
  Action as PdftronAction,
  State as PdftronState,
} from '../pdf-tron-utils/pdftronReducer';
import {
  Action as VersionsAction,
  State as VersionsState,
  Status as VersionsStatus,
} from '../version-utils/versionsReducer';

import { useVersionPanelUtils } from './useVersionPanelUtils';

/**
 * PdfTron loads in it's own iframe, with it's own version of React.
 * We cannot use our shared/ui component (it breaks) but we can at
 * least use the basics to render out the version panel with just this
 * basic setup for now. If we need more advanced things, we may have
 * a look at this package: https://github.com/PDFTron/webviewer-ui
 * and see if we can use it for more advanced things.
 *
 * Some tricks may be possible here, like pre-rendering this component
 * with our version of react inside a container using ReactDOM.render
 * but we run into themeing issues this way. I did not investigate
 * further.
 */

interface Props {
  pdftronDispatch: React.Dispatch<PdftronAction>;
  pdftronState: PdftronState;
  redrawPanel: () => void;
  versionsDispatch: React.Dispatch<VersionsAction>;
  versionsState: VersionsState;
}

export const VersionPanel: React.FC<Props> = ({
  versionsDispatch,
  versionsState,
  pdftronDispatch,
  pdftronState,
  redrawPanel,
}) => {
  const versionsArray = Object.values(versionsState);
  const { baseVersionId, overlayVersionId } = pdftronState;
  const {
    handleOverlayVersionChange,
    handleBaseVersionChange,
    handleToggleAnnotation,
  } = useVersionPanelUtils({
    pdftronDispatch,
    pdftronState,
    redrawPanel,
    versionsDispatch,
    versionsState,
  });

  const renderSectionList = (type: 'base' | 'overlay') => (
    <>
      <div>
        <label htmlFor={type}>
          <strong>{`${
            type.charAt(0).toUpperCase() + type.slice(1)
          } version`}</strong>
        </label>
      </div>
      <div style={{ paddingBottom: '8px' }}>
        <select
          id={type}
          name={type}
          onChange={(event) => {
            if (type === 'base') {
              handleBaseVersionChange(event.target.value);
            } else {
              handleOverlayVersionChange(event.target.value);
            }
          }}
          style={{ width: '100%' }}
          value={type === 'base' ? baseVersionId : overlayVersionId}
        >
          {type === 'overlay' && <option value="">Select a version</option>}
          {versionsArray.map((version) => {
            if (type === 'overlay' && version.id === baseVersionId) return null;
            return (
              <option key={version.id} value={version.id}>
                {version.documentVersion.versionNumber}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );

  return (
    <div style={{ padding: '0 20px' }}>
      {renderSectionList('base')}
      {renderSectionList('overlay')}
      <table>
        <tr>
          <th>Version</th>
          <th>Show annotations?</th>
        </tr>
        {versionsArray.map((version, i) => (
          <tr key={version.id}>
            <td>
              <label htmlFor={version.id}>
                {version.documentVersion.versionNumber}
              </label>
            </td>
            <td>
              <input
                checked={version.showAnnotations}
                id={version.id}
                name="annotations"
                onChange={() => {
                  handleToggleAnnotation(version.id);
                }}
                type="checkbox"
              />
              {version.status === VersionsStatus.SYNCING && ' (loading)'}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
