import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  Document,
  SnagstreamState,
  useConnectionQuery,
  useShareToSnagstreamMutation,
} from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { useShareSnagstreamContext } from './ShareSnagstreamContext';
import { FormFields } from './types';

export const useSubmit = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedDocuments,
    selectedConnection,
    existingVersionIds,
    setSelectedConnection,
    setSelectedDocuments,
    setExistingVersionIds,
    setValidationScreen,
  } = useShareSnagstreamContext();
  const { showBatchErrors, showSingleError } = useShowMessages();
  const { params } = useRouting();
  const { projectId } = params;
  const { data: integrationConnection } = useConnectionQuery({
    fetchPolicy: 'cache-first',
    pollInterval: 60000 * ApiConfig.pollEnabled,
    skip: !selectedConnection,
    variables: { id: selectedConnection || '', projectId: projectId || '' },
  });
  const [shareToSnagstream] = useShareToSnagstreamMutation();
  const {
    editItems,
    state: { items },
  } = useOptimisticResponseContext<Document>();

  return async ({
    markAsSent,
    overwrite,
    values,
  }: {
    markAsSent?: boolean;
    overwrite?: boolean;
    values: FormFields;
  }) => {
    const { connection } = values;
    const versionIds = selectedDocuments.map(
      (document) => document.versionId || ''
    );

    try {
      const versionIdsToUse =
        markAsSent || overwrite
          ? versionIds
          : versionIds.filter((id) => !existingVersionIds.includes(id));

      if (versionIdsToUse.length === 0) {
        setSelectedConnection && setSelectedConnection('');
        setSelectedDocuments && setSelectedDocuments([]);
        setExistingVersionIds([]);
        setValidationScreen(false);
        onClose();
        return;
      }

      const response = await shareToSnagstream({
        variables: {
          connectionId: connection.id,
          integrationId: integrationConnection?.connection.integrationId || '',
          markAsSentVersionIds: markAsSent ? existingVersionIds : [],
          overwriteVersionIds: overwrite ? existingVersionIds : [],
          projectId: integrationConnection?.connection.projectId || '',
          versionIds: versionIdsToUse,
        },
      });

      const successful = response.data?.shareToSnagstream.successful;
      const unsuccessful = response.data?.shareToSnagstream.unsuccessful;

      if (selectedDocuments.length === 1) {
        const fileName = selectedDocuments[0].name;
        const message = successful
          ? t('{{fileName}} has been succesfully shared to Snagstream.', {
              fileName,
            })
          : unsuccessful
          ? t(
              '{{fileName}} has failed sharing to Snagstream. Please try again.',
              {
                fileName,
              }
            )
          : t('Something went wrong');
        enqueueSnackbar(message);
      } else {
        if (successful && successful.length === selectedDocuments.length) {
          enqueueSnackbar(
            t('All documents are succesfully shared to Snagstream.')
          );
        } else {
          const selectedDocumentsFailed = selectedDocuments.filter((document) =>
            unsuccessful?.includes(document?.versionId || '')
          );
          const identifierData = selectedDocumentsFailed.map((document) => ({
            displayName: document.name,
            id: document.id,
          }));
          if (
            unsuccessful &&
            unsuccessful.length === selectedDocuments.length
          ) {
            showBatchErrors({
              customToastError: t(
                'All documents failed sharing to Snagstream. Please try again.'
              ),
              hideCodeError: true,
              identifierData,
            });
          } else {
            enqueueSnackbar(
              t('{{count}} documents are succesfully shared to Snagstream.', {
                count: successful?.length,
              })
            );
            if (unsuccessful && unsuccessful.length > 0) {
              showBatchErrors({
                customToastError: t(
                  '{{count}} documents failed sharing to Snagstream. Please try again.',
                  { count: unsuccessful?.length }
                ),
                hideCodeError: true,
                identifierData,
              });
            }
          }
        }
      }

      if (successful?.length) {
        const itemsToModify = items.filter((item) =>
          successful.includes(item.versionId || '')
        );

        const modifiedItems = itemsToModify.map((item) => {
          const { stateSnagstream } = item;
          const connections = stateSnagstream?.connections || [];
          return {
            ...item,
            stateSnagstream: {
              connections: [
                ...connections,
                {
                  connectionId: connection.id,
                  connectionName: connection.label,
                },
              ],
              state: SnagstreamState.Shared,
            },
          };
        });
        editItems(modifiedItems);
      }
    } catch (e) {
      showSingleError(e);
    }

    setSelectedConnection && setSelectedConnection('');
    setSelectedDocuments && setSelectedDocuments([]);
    setExistingVersionIds([]);
    setValidationScreen(false);
    onClose();
  };
};
