import React, {
  ForwardedRef,
  forwardRef,
  isValidElement,
  useImperativeHandle,
  useRef,
} from 'react';

import DefaultMore, { DisplayType } from './DefaultMore';
import * as Styled from './List.styles';
import { useListOverflow } from './useListOverflow';
// TODO: If anyone know how to make typescript behave properly here...
// For example: we don't have autocompletion for HTML attributes.
export type Props<
  Component extends React.ElementType = typeof Styled.Ul,
  Item extends React.ElementType = typeof Styled.Li
> = React.ComponentPropsWithRef<Component> & {
  children?: React.ReactNode[];
  color?: string;
  component?: Component;
  direction?: 'horizontal' | 'vertical';
  displayType?: DisplayType;
  dividers: string[];
  flexEnd: boolean;
  item?: Item;
  itemProps?: React.ComponentPropsWithRef<typeof Styled.Li>;
  limit?: number;
  margintTop?: number;
  more?: ListMore;
  overflow?: boolean;
  overflowMenuPosition?: 'bottom-end' | 'bottom-start';
};

export type ListMore = React.ElementType<{
  children: React.ReactChild[];
  container: HTMLLIElement;
  limit: number;
}>;
/* The list component has an optional feature where it can automatically render 
an icon with 3 dots when there are too many list items for the space available. 
If you hover the dots, the excess items appear in a seperate pop up menu(DefaultMore.tsx). 
You can turn this behaviour on by passing in the optional overflow prop.*/
function ListComponent<
  Component extends React.ElementType,
  Item extends React.ElementType
>(
  {
    children,
    component = Styled.Ul,
    direction = 'vertical',
    itemProps,
    limit = Infinity,
    item = Styled.Li,
    more = DefaultMore,
    flexEnd = false,
    overflow,
    color,
    displayType,
    dividers,
    ...rest
  }: Props<Component, Item>,
  ref: ForwardedRef<Element>
) {
  const containerRef = useRef<Element>(null);
  // useImperativeHandle passes our `containerRef` as `ref` to the parent. So
  // when you use <List ref={ref}> `ref.current` is the `ul` rendered by List.
  useImperativeHandle(ref, () => containerRef.current as Element);
  const Item = item;
  const items = React.Children.toArray(children);
  const overflowLimit = Math.min(
    limit,
    useListOverflow({ containerRef, flexEnd, items, overflow })
  );

  const hasMore = items.length > overflowLimit;
  const head = overflow ? items : items.slice(0, overflowLimit);
  const tail = items.slice(overflowLimit);

  const Component = component;
  const More = more;

  if (!items.length) return null;

  const noOfHiddenItems = items.length - overflowLimit;

  const moreCircle = (
    <Styled.Li
      {...itemProps}
      aria-hidden={overflow}
      className="more"
      data-list-more
      key="more"
    >
      <More
        children={tail}
        color={color}
        displayType={displayType}
        dividers={dividers}
        noOfHiddenItems={noOfHiddenItems}
      />
    </Styled.Li>
  );

  return (
    <Component
      $direction={direction}
      $flexEnd={flexEnd}
      $overflow={overflow}
      {...rest}
      ref={containerRef}
    >
      {Boolean(hasMore && flexEnd) && moreCircle}
      {head.map((child, index) => {
        if (!isValidElement(child)) return {};
        return (
          <Item
            $direction={direction}
            {...itemProps}
            index={index}
            // HACK: If `child` is a primitive (e.g. string) `child.key` should
            // return undefined. TypeScript doesn't like property access on
            // unsupported props, so we just tell it it's a ReactElement.
            key={(child as React.ReactElement)?.key ?? index}
            style={
              // When overflow is set, we want to hide items initially to prevent
              // visual flicker of items when the List's children change.
              overflow
                ? {
                    // If the menu is already overflowing prevent the `more`
                    // component from being pushed out of position
                    position: index > overflowLimit && 'absolute',
                    visibility: 'hidden',
                    ...itemProps?.style,
                  }
                : itemProps?.style
            }
          >
            {child}
          </Item>
        );
      })}
      {Boolean(hasMore && !flexEnd) && moreCircle}
    </Component>
  );
}

export const List = forwardRef(ListComponent);
