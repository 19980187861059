import styled from 'styled-components';

import { SwipeableDrawer } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

import { Color } from './types';

export const mobileDrawerContainer = styled('div')`
  position: fixed;
  z-index: 1500;
`;

export const GreyOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  background-color: rgba(88, 88, 88, 0.24);
`;

// Todo: invert this SC so we can style all sub elements separately
// Wait for update -- there currently is an issue with S-C which prevents us from doing this
export const StyledDrawer = styled(SwipeableDrawer)<{
  $colorVariant: Color;
  $open: boolean;
}>`
  && {
    @media print {
      display: none;
    }

    --drawer-width: ${({ $open }) => ($open ? '216px' : '64px')};
    --nav-group-justify-content: ${({ $open }) =>
      $open ? 'flex-start' : 'center'};
    --background-color: ${({ $colorVariant }) => {
      switch ($colorVariant) {
        case 'dark':
          return ({ theme }) => theme.palette.primary.dark;
        case 'light':
          return ({ theme }) => theme.palette.primary.light;
      }
    }};
    --item-color: ${({ $colorVariant }) => {
      switch ($colorVariant) {
        case 'dark':
          return customColors.white;
        case 'light':
          return customColors.grey900;
      }
    }};
    --item-active-color: ${({ $colorVariant }) => {
      switch ($colorVariant) {
        case 'dark':
          return customColors.grey900;
        case 'light':
          return customColors.white;
      }
    }};
    --item-active-background-color: ${({ $colorVariant }) => {
      switch ($colorVariant) {
        case 'dark':
          return ({ theme }) => theme.palette.primary.light;
        case 'light':
          return ({ theme }) => theme.palette.primary.dark;
      }
    }};
    --item-background-hover-color: ${({ $colorVariant }) => {
      switch ($colorVariant) {
        case 'dark':
          return 'rgba(255, 255, 255, 0.12)';
        case 'light':
          return 'rgba(88,88,88,0.12)';
      }
    }};

    width: var(--drawer-width);
    .MuiDrawer-paper {
      position: fixed;
      width: var(--drawer-width);
      height: 100%;
      padding: ${({ theme }) => theme.spacing(1.33)};
      overflow-x: hidden;
      background-color: var(--background-color);
      font-weight: 500;

      .MuiPaper-root,
      .organization-title {
        color: var(--item-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .MuiSvgIcon-root {
        color: var(--item-color);
      }

      .Warning {
        .MuiSvgIcon-root {
          color: ${({ theme }) => theme.palette.warning.main};
        }
      }

      .chevron {
        margin-right: ${({ theme }) => theme.spacing(2.33)};
        .MuiSvgIcon-root:hover {
          background-color: var(--item-background-hover-color);
          border-radius: 50%;
          cursor: pointer;
        }
      }

      /* stylelint-disable no-descending-specificity */
      .MuiList-root {
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => theme.spacing(0.5)};
        overflow-y: auto;
        a,
        .nav-group {
          display: flex;
          justify-content: var(--nav-group-justify-content);
          flex: 1;
          text-decoration: none;

          .MuiListItemText-root {
            display: inline-flex;
            flex-shrink: 0;
            margin: 0;
          }

          .MuiBox-root,
          .MuiListItemIcon-root,
          .MuiListItemText-root {
            min-width: 0;
            color: var(--item-color);
            cursor: pointer;
            user-select: none;
          }

          .MuiListItemIcon-root + .MuiListItemText-root {
            padding-left: ${({ theme }) => theme.spacing(1)};
          }
        }

        .nav-group-tooltip {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-radius: 4px;
          padding: ${({ theme }) => theme.spacing(0.7)} 0;
          &:hover {
            background-color: var(--item-background-hover-color);
            border-radius: 4px;
          }
          &:has(.nav-link.active) {
            background-color: var(--item-background-hover-color);
          }
        }
        .nav-link {
          padding: ${({ theme }) => theme.spacing(1)}
            ${({ theme }) => theme.spacing(1.3)};
          &.nav-item:hover {
            background-color: var(--item-background-hover-color);
            border-radius: 4px;
          }
          ${({ theme }) => theme.breakpoints.down('sm')} {
            padding-top: ${({ theme }) => theme.spacing(1.1)};
            padding-bottom: ${({ theme }) => theme.spacing(1.1)};
          }
        }
        .group-container .nav-link {
          padding: ${({ theme }) => theme.spacing(1)}
            ${({ theme }) => theme.spacing(4.66)};
        }
        .nav-group {
          width: 100%;
        }
        /* nav link with class active that is not inside a nav group */
        .nav-link.active:not(.nav-group .nav-link.active) {
          background-color: var(--item-active-background-color);
          border-radius: 4px;
          &.MuiBox-root,
          .MuiListItemIcon-root,
          .MuiListItemText-root,
          .MuiSvgIcon-root {
            color: var(--item-active-color);
          }
        }
        .group-container {
          width: 100%;
        }
        .toggle-drawer {
          display: flex;
          justify-content: ${({ $open }) => ($open ? 'flex-end' : 'center')};
          padding: ${({ theme }) => theme.spacing(2)};
          color: var(--item-color);
          cursor: pointer;
        }
        .MuiIconButton-colorPrimary:hover {
          background-color: var(--item-background-hover-color);
          cursor: pointer;
        }

        /* stylelint-enable no-descending-specificity */
      }
      @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        .MuiDrawer-paper {
          width: 240px;
        }
      }
    }
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  cursor: pointer;
`;
