import React from 'react';

import { QualityControlInstance } from '@pro4all/graphql';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
} from '@pro4all/quality-control/ui/results';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SnagFormSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import { Loader } from '@pro4all/shared/ui/loader';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { DrawingSearchResults } from './DrawingSearchResults';
import { useFetchDrawing } from './useFetchDrawing';

export const Drawing = () => {
  const {
    params: { visualContextId },
  } = useRouting();

  const { data } = useFetchDrawing(visualContextId);

  if (!data?.visualContext) return <Loader />;

  return (
    <OptimisticResponseProvider<QualityControlInstance>>
      <SnagFormSearchContextProvider
        initializeSearchWithPredefinedHiddenFilters
      >
        <DrawingSearchResults visualContext={data.visualContext} />
        <PhotoProvider>
          <AnswersPercentageContextProvider>
            <ResultSidebar />
          </AnswersPercentageContextProvider>
        </PhotoProvider>
      </SnagFormSearchContextProvider>
    </OptimisticResponseProvider>
  );
};
