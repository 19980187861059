import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Maybe, ScheduledTime } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { isDefined } from '@pro4all/shared/utils';

import { ErrorMessage } from '../selectDays/SelectDays.styles';

import { RemoveTime } from './RemoveTimeButton';
import {
  AddTimeButton,
  ContentWrap,
  ListOfTimes,
  TimeInputWrapper,
} from './SelectTime.styles';
import { Args, TimeInput } from './TimeInput';

export const defaultTime: ScheduledTime = { hour: '07', minute: '00' };

type Props<T extends {}> = {
  enabled?: boolean;
  errors?: UseFormMethods<T>['errors'];
  setValue: UseFormMethods<T>['setValue'];
  watch: UseFormMethods<T>['watch'];
};

type TSelectTimeFields = {
  notificationTimes: Maybe<ScheduledTime>[];
};

export const SelectTime = ({
  watch,
  setValue,
  enabled,
  errors,
}: Props<TSelectTimeFields>) => {
  const { t } = useTranslation();

  const selectedTimesWatched = watch('notificationTimes')?.filter(isDefined);
  const selectedTimes = selectedTimesWatched?.length
    ? selectedTimesWatched
    : [defaultTime];

  const timesCount = selectedTimes?.length || 0;
  const maxedOut = timesCount === 8;

  const addTime = (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    const nextValue = selectedTimes
      ? [...selectedTimes, defaultTime]
      : [defaultTime];
    setValue('notificationTimes', nextValue);
  };

  const removeTime = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    e.preventDefault();
    if (!selectedTimes || index < 0) return;
    const filteredTimes = selectedTimes.filter((time, i) => i !== index);
    setValue('notificationTimes', filteredTimes);
  };

  /* Shift focus to the latest time selector */
  useEffect(() => {
    if (timesCount > 1) {
      const index = timesCount - 1;
      const latestElement = document.getElementById(`time-${index}-hour`);
      latestElement && latestElement.focus();
    }
  }, [timesCount]);

  const validate = (index: number, value: string, type: 'hour' | 'minute') => {
    const indexInRange = selectedTimes && index < selectedTimes?.length;
    const isValidNumber = /\d|\./.test(value);
    const maxNumber = type === 'hour' ? 24 : 60;
    const number = parseInt(value);
    const isValidTime = number < maxNumber && number >= 0;
    const validLength = value.length <= 2;
    const validInput =
      indexInRange && isValidNumber && isValidTime && validLength;
    return !value || validInput;
  };

  const onChange = ({ index, type, value }: Args) => {
    const isValid = validate(index, value, type);

    if (selectedTimes && isValid) {
      const selectedTime = selectedTimes[index];
      const updatedTimes = [...selectedTimes];

      if (type === 'hour') {
        updatedTimes[index] = {
          hour: value,
          minute: selectedTime.minute,
        };

        /* Shift focus to minutes after 2 digits */
        if (value.length === 2) {
          const activeElement = document.activeElement;
          const pattern = new RegExp(/^time-\d+-hour+$/);
          if (activeElement && pattern.test(activeElement.id)) {
            const nextId = activeElement.id.replace('hour', 'minute');
            const nextElement = document.getElementById(nextId);
            nextElement?.focus();
          }
        }
      }
      if (type === 'minute') {
        updatedTimes[index] = {
          hour: selectedTime.hour,
          minute: value,
        };
      }
      setValue('notificationTimes', updatedTimes);
    }
  };

  const onBlur = ({ index, type, value }: Args) => {
    if (selectedTimes) {
      const number = parseInt(value);
      const formattedValue = value
        ? value !== '0' && value !== '00' && number < 10
          ? `0${number}`
          : value
        : '00';

      onChange({ index, type, value: formattedValue });
    }
  };

  // react-hook-form's errors are incorrectly typed
  const errorMessages = errors?.notificationTimes as unknown as {
    message: string;
  };

  return (
    <ContentWrap $enabled={enabled}>
      <ListOfTimes>
        {selectedTimes?.map(({ hour, minute }, index) => (
          <TimeInputWrapper id={`time-${index}`} key={index}>
            <TimeInput
              disabled={!enabled}
              hour={hour}
              index={index}
              minute={minute}
              onBlur={onBlur}
              onChange={onChange}
            />
            {selectedTimes?.length > 1 && (
              <RemoveTime
                disabled={!enabled}
                onClick={(e) => removeTime(e, index)}
              />
            )}
          </TimeInputWrapper>
        ))}
        {!maxedOut && (
          <Box display="flex" mt="8px">
            <AddTimeButton
              ariaLabel="addTime"
              disabled={!enabled}
              onClick={addTime}
              startIcon="add"
              type="button"
              variant="text"
            >
              {t('Add time')}
            </AddTimeButton>
          </Box>
        )}
      </ListOfTimes>
      {Boolean(errorMessages?.message) && (
        <ErrorMessage>{t(errorMessages?.message)}</ErrorMessage>
      )}
    </ContentWrap>
  );
};
