import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { FolderTreeContextProvider } from '@pro4all/documents/ui/folders';
import { GenerateLinkContextProvider } from '@pro4all/documents/ui/share';
import { ShareBuild12ContextProvider } from '@pro4all/documents/ui/share-build12';
import { ShareSnagstreamContextProvider } from '@pro4all/documents/ui/share-sns';
import { Document } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { DocumentSearchContextProvider } from '@pro4all/shared/documents-search-context';
import {
  getLastVisitedOrganizationFolder,
  getLastVisitedProjectFolder,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { DocumentsBrowserContents } from './DocumentsBrowserContents';

export const DocumentsBrowser: React.FC = () => {
  const history = useHistory();
  const {
    searchParams,
    params: { projectId, path },
    url,
  } = useRouting();

  const routeSmartFolder = useRouteMatch([
    Routes.projectSmartFolder,
    Routes.smartFolder,
  ]);
  const routeCollectionFolder = useRouteMatch([
    Routes.collectionFolder,
    Routes.projectCollectionFolder,
  ]);
  const routeDocs = useRouteMatch([Routes.projectDocs, Routes.docs]);
  const routeTasks = useRouteMatch(Routes.projectDocsTasks);
  const routeProjectDmsBase = useRouteMatch(Routes.projectDmsBase);
  const isDmsRouteOtherThanDocs = Boolean(routeProjectDmsBase && !routeDocs);
  const isSearch = searchParams.has('search');

  const shouldRedirectToFolder =
    !path &&
    !isDmsRouteOtherThanDocs &&
    !routeSmartFolder &&
    !routeCollectionFolder &&
    !routeTasks &&
    !isSearch;

  useEffect(() => {
    if (shouldRedirectToFolder) {
      // It loads the path for the folder tree structure
      // depending if the user is in project or organization level.

      const folderPath =
        (projectId
          ? getLastVisitedProjectFolder(projectId)?.params?.path
          : getLastVisitedOrganizationFolder()?.path) ?? '';

      const baseUrl =
        url[url.length - 1] === '/' ? url.slice(0, url.length - 1) : url;

      // When the user moves to 'documents' the stored path for the folder tree structure is loaded.
      history.replace(`${baseUrl}${folderPath !== '' ? '/' : ''}${folderPath}`);
    }
  }, [history, path, projectId, shouldRedirectToFolder, url]);

  return (
    <FolderTreeContextProvider projectId={projectId}>
      <OptimisticResponseProvider<Document>>
        <GenerateLinkContextProvider>
          <DocumentSearchContextProvider>
            <ShareBuild12ContextProvider>
              <ShareSnagstreamContextProvider>
                <DocumentsBrowserContents />
              </ShareSnagstreamContextProvider>
            </ShareBuild12ContextProvider>
          </DocumentSearchContextProvider>
        </GenerateLinkContextProvider>
      </OptimisticResponseProvider>
    </FolderTreeContextProvider>
  );
};
