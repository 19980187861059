import { useCallback } from 'react';

import { useFolderTreeContextInner } from './tree/FolderTreeContext';
import { useFolderTreeContextOuter } from './tree/FolderTreeProviderOuter';
import { TreeFolder } from './tree/types';

interface FolderLevelData {
  depth: number;
  hasSubFolders: boolean;
  id: string;
  isExpanded: boolean;
  path: string[];
}

export const useFolderLevels = () => {
  const { expandedRowKeys, toggleMultiple } = useFolderTreeContextInner();
  const {
    state: { folderTree },
  } = useFolderTreeContextOuter();

  const getFolderLevelData = useCallback(
    ({
      folderTree,
      parentPath = [],
      result = [],
    }: {
      folderTree: TreeFolder[];
      parentPath?: string[];
      result?: FolderLevelData[];
    }): FolderLevelData[] => {
      if (!folderTree) return result;

      folderTree.forEach((item) => {
        if (item.hasSubFolders) {
          const currentPath = [...parentPath, item.id];
          const depth = currentPath.length - 1;

          result.push({
            depth,
            hasSubFolders: item.hasSubFolders,
            id: item.id,
            isExpanded: expandedRowKeys.includes(item.id),
            path: currentPath,
          });

          if (item.children?.length && item.hasSubFolders) {
            getFolderLevelData({
              folderTree: item.children,
              parentPath: currentPath,
              result,
            });
          }
        }
      });

      return result;
    },
    [expandedRowKeys]
  );

  const expandNextLevel = useCallback(async () => {
    const folderInfo = getFolderLevelData({ folderTree });
    if (!folderInfo.length) return;

    // Get expanded folders and maximum depth
    const expandedFolders = folderInfo.filter((f) => f.isExpanded);
    const currentMaxDepth = expandedFolders.length
      ? Math.max(...expandedFolders.map((f) => f.depth))
      : -1;

    // Get folders that can be expanded at current level or below
    const foldersToCheck = folderInfo.filter(
      (folder) =>
        folder.hasSubFolders &&
        !folder.isExpanded &&
        folder.depth <= currentMaxDepth
    );

    if (foldersToCheck.length > 0) {
      // Get lowest depth folders that need expansion
      const lowestDepth = Math.min(...foldersToCheck.map((f) => f.depth));
      const foldersToExpand = foldersToCheck
        .filter((f) => f.depth === lowestDepth)
        .map((f) => f.id);

      await toggleMultiple(foldersToExpand, false);
      return;
    }

    // Expand next level if all previous levels are expanded
    const nextLevelFolders = folderInfo
      .filter(
        (folder) =>
          folder.depth === currentMaxDepth + 1 &&
          folder.hasSubFolders &&
          folder.path
            .slice(0, -1)
            .every(
              (parentId) =>
                expandedRowKeys.includes(parentId) || parentId === folder.id
            )
      )
      .map((f) => f.id);

    if (nextLevelFolders.length > 0) {
      await toggleMultiple(nextLevelFolders, false);
    }
  }, [folderTree, expandedRowKeys, getFolderLevelData, toggleMultiple]);

  const collapseDeepestLevel = useCallback(async () => {
    const folderInfo = getFolderLevelData({ folderTree });
    if (!folderInfo.length) return;

    const expandedFolders = folderInfo.filter((f) => f.isExpanded);
    if (!expandedFolders.length) return;

    const maxDepth = Math.max(...expandedFolders.map((f) => f.depth));
    const foldersToCollapse = expandedFolders
      .filter((folder) => folder.depth === maxDepth)
      .map((f) => f.id);

    if (foldersToCollapse.length > 0) {
      await toggleMultiple(foldersToCollapse, true);
    }
  }, [folderTree, getFolderLevelData, toggleMultiple]);

  return {
    collapseDeepestLevel,
    expandNextLevel,
  };
};
