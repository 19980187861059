import React from 'react';
import { useTranslation } from 'react-i18next';

import { Template, User } from '@pro4all/graphql';
import { RenderOptionType } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';
import { Tag } from '@pro4all/shared/ui/tag';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/timestamp';

export const useColumnsTemplates = () => {
  const { t } = useTranslation();

  const columnsSets: ColumnProps<Template>[] = [
    {
      defaultSort: true,
      filterable: true,
      headerComponent: (
        <FilterHeader<Template, {}>
          defaultWidth={300}
          filterType={FilterHeaderType.Text}
          label="Name" // i18n
          minWidth={100}
          propertyId="name"
          pullFromLocalStorage
        />
      ),
      key: 'name',
      width: 300,
    },
    {
      filterable: true,
      getValue: (template: Template) =>
        template.createdAt ? getFormattedDate(template.createdAt).label : '',
      headerComponent: (
        <FilterHeader<Template, {}>
          defaultWidth={200}
          filterType={FilterHeaderType.Date}
          label="Created on" // i18n
          minWidth={120}
          propertyId="createdAt"
        />
      ),
      key: 'createdAt',
      render: ({ createdAt }) => <Timestamp date={createdAt} />,
      width: 200,
    },
    {
      filterable: true,
      getValue: ({ createdBy }) => createdBy?.displayName,
      headerComponent: (
        <FilterHeader<Template, User>
          defaultWidth={300}
          filterType={FilterHeaderType.Select}
          label="Created by" // i18n
          minWidth={120}
          propertyId="createdBy"
          renderOption={RenderOptionType.UserCard}
          subPropertyId="displayName"
        />
      ),
      key: 'createdBy.displayName',
      render: ({ createdBy }) => <UserTag user={createdBy} />,
      width: 300,
    },
    {
      filterable: true,
      getValue: ({ state }) => t<string>(`TemplateState.${state}`),
      headerComponent: (
        <FilterHeader<Template, {}>
          defaultWidth={130}
          filterType={FilterHeaderType.Select}
          label="Status" // i18n
          minWidth={100}
          propertyId="state"
          translateOptions
        />
      ),
      key: 'state',
      width: 130,
    },
    {
      filterable: true,
      headerComponent: (
        <FilterHeader<Template, {}>
          defaultWidth={130}
          filterType={FilterHeaderType.Number}
          label="Usage" // i18n
          minWidth={100}
          propertyId="instanceCount"
        />
      ),
      key: 'instanceCount',
      render: ({ instanceCount }) => (
        <Tag
          name={instanceCount?.toString() || '0'}
          sx={{ margin: '0 0.2rem' }}
        />
      ),
      width: 130,
    },
  ];
  return columnsSets;
};
