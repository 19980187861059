import { Stamp, StampsQuery } from '@pro4all/graphql';

import { useRevoke } from '../data';
import {
  StamperDocument,
  Status,
  toRemoveStampInput,
  useStore,
} from '../store';

export function useRevokeAll(
  queue: StamperDocument[],
  data: StampsQuery | undefined,
  refetch: () => void
) {
  const [state, dispatch] = useStore({ queue });
  const revoke = useRevoke();

  const revokeAll = async () => {
    dispatch({ status: Status.Pending, type: 'status' });
    const allStamps = (data?.stamps?.data ?? []).filter(
      (stamp): stamp is Stamp => stamp !== null
    );
    revoke(
      state.queue.slice(state.index).flatMap(({ documentId, id }) =>
        toRemoveStampInput(
          documentId,
          allStamps.filter(({ versionId }) => id === versionId)
        )
      )
    );
    refetch();
    dispatch({ index: state.queue.length, type: 'next' });
  };

  return { revokeAll };
}
