import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Status,
  Task,
  useTaskStatusesQuery,
  useUpdateTaskMutation,
} from '@pro4all/graphql';
import { Box, useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { IconName } from '@pro4all/shared/ui/icons';
import { useShowMessages } from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

type Action = {
  ariaLabel: string;
  color: string;
  command: string;
  icon?: IconName;
  translationDefault: string;
  translationLabel: string;
};

export const TaskQuickActions: React.FC<{ task: Task }> = ({ task }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const [updateTask] = useUpdateTaskMutation();
  const { showSingleError } = useShowMessages();

  const {
    editItems,
    state: { items },
  } = useOptimisticResponseContext<Task>();

  const { refetch } = useTaskStatusesQuery({
    fetchPolicy: 'no-cache',
    skip: true,
  });

  const { procedureId, id, assignment } = task;

  const actionIconMap: Record<string, IconName> = {
    FailTask: 'taskFailed',
    FinishTask: 'check',
    PauseTask: 'pause',
    StartTask: 'play',
    StopTask: 'stop',
    WorkOnTask: 'play',
  };

  const actionColorMapRecord: Record<string, string> = {
    FailTask: theme.palette.error.main,
    FinishTask: theme.palette.success.main,
    PauseTask: theme.palette.warning.main,
    StartTask: theme.palette.primary.main,
    StopTask: theme.palette.error.main,
    WorkOnTask: theme.palette.primary.main,
  };

  const getActionProps = (command: string): Action => ({
    ariaLabel: `${command
      .split(/(?=[A-Z])/)
      .join('-')
      .toLowerCase()}-button`,
    color: actionColorMapRecord[command],
    command,
    icon: actionIconMap[command] || null,
    translationDefault: command.split(/(?=[A-Z])/).join(' '),
    translationLabel: t(`Api.workflow.actions.${command}`),
  });

  const performQuickAction = async (
    event: React.MouseEvent,
    command: string
  ) => {
    event.stopPropagation();

    try {
      await updateTask({
        variables: {
          command,
          endTime: task.endTime,
          id,
          procedureId,
          type: task.type,
          userId: assignment[0]?.id,
        },
      });

      // TODO: Weird construction that causes a mismapping between the enum 'Status' and the command values we get from BE.
      // So we do this remapping to the enum 'Status'.
      const status =
        command === 'StopTask'
          ? Status.ToDo
          : command === 'FinishTask'
          ? Status.Done
          : command === 'PauseTask'
          ? Status.Paused
          : (command as Status);

      const {
        data: { taskStatuses },
      } = await refetch({
        id: task.id,
        status,
      });

      editItems(
        items
          .filter((item) => item.id === task.id)
          .map((item) => ({
            ...item,
            availableStatuses: taskStatuses,
            status: Status[status],
          }))
      );
    } catch (e) {
      showSingleError(e);
    }
  };

  return (
    <Box
      color={theme.palette.success.contrastText}
      display="flex"
      style={{ marginLeft: theme.spacing(1.5) }}
    >
      {task.status !== Status.Done &&
        (!mobileBreakpoint ? (
          <Button
            aria-label={getActionProps('FinishTask').ariaLabel}
            color="secondary"
            onClick={(e) => performQuickAction(e, 'FinishTask')}
            startIcon={getActionProps('FinishTask').icon ?? 'prostream'}
          >
            {t(`Api.workflow.actions.FinishTask`)}
          </Button>
        ) : (
          <IconButton
            aria-label={getActionProps('FinishTask').ariaLabel}
            color="secondary"
            iconName={getActionProps('FinishTask').icon ?? 'prostream'}
            onClick={(e) => performQuickAction(e, 'FinishTask')}
          ></IconButton>
        ))}
    </Box>
  );
};
