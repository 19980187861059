import React from 'react';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { AvatarProfile } from '@pro4all/shared/ui/file-upload';
import { LayoutLeftMain } from '@pro4all/shared/ui/layout';

import { ProjectForm } from './ProjectForm';

export const ProjectFormEdit = () => {
  const { projectData } = useProjectContext();

  const leftContent = <ProjectForm project={projectData} showCancel={false} />;
  const mainContent = (
    <AvatarProfile
      avatarFallbacklabel={projectData?.name}
      id={projectData?.id}
      profilePictureEntity={ProfilePictureEntity.Project}
    />
  );

  return <LayoutLeftMain leftContent={leftContent} mainContent={mainContent} />;
};
