import { filterFoldersToHide } from '../helpers/filterFoldersToHide';
import { unflattenFolders } from '../helpers/unflattenFolders';
import { State } from '../types';

export const toggleEmptyFoldersAction = (state: State) => ({
  ...state,
  folderTree: state.hideEmptyFolders
    ? unflattenFolders(state.folders)
    : unflattenFolders(filterFoldersToHide(state.folders)),
  hideEmptyFolders: !state.hideEmptyFolders,
});
