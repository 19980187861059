import {
  Document,
  DocumentVersion,
  DocumentVisualContextInput,
  InstanceForDocumentInput,
  Organization,
  QualityControlInstanceType,
  UploadFileOption,
} from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import {
  ButtonProps as MUIButtonProps,
  MenuItemProps as MUIMenuItemProps,
} from '@pro4all/shared/mui-wrappers';
import { IconName } from '@pro4all/shared/ui/icons';

export interface BackwardsCompatibleProps {
  ariaLabel?: MUIButtonProps['aria-label'];
  dataTestId?: string;
  label?: string;
}

export interface ButtonProps
  extends BackwardsCompatibleProps,
    Omit<MUIButtonProps, 'disableElevation'> {
  endIcon?: IconName;
  sameTypeCondition?: `${QualityControlInstanceType}`;
  startIcon?: IconName;
}

export type ActionPropsType = ButtonProps & Pick<MUIMenuItemProps, 'onClick'>;

export type ActionProps = ActionPropsType & {
  actuallyDisabled?: boolean; // Use to set a button disabled state
  subItems?: ActionPropsType[];
};

export type Option = {
  disabled?: boolean;
  groupOption?: {
    totalUsers: number;
  };
  iconColor?: string;
  iconComponent?: JSX.Element | null;
  iconName?: IconName;
  id: string;
  /**
   * This is currently used in multi select for the creation of new values
   * TODO: see if we can omit this prop and handle it as an implementation
   * detail in the select components.
   * https://pro4all.visualstudio.com/Pro4all.Prostream.Frontend/_workitems/edit/19144/
   */
  inputValue?: string;
  label: string;
  labelColor?: string;
  type?: string;
  userOption?: {
    email: string;
    isAdmin: boolean;
    organization: Organization;
  };
};

export type PermissionUserOption = Option & {
  explicitProjectMember: boolean;
  organizationId?: string;
};

export enum TableColumnDataType {
  NUMERIC = 'numeric',
  TEXT = 'text',
}

export type InputValue = string | Option | Option[];
export type InstanceValues = Record<string, InputValue>;

export interface RouteOption {
  label: string;
  route: keyof typeof Routes;
}

export enum DocTablePropertyKeys {
  ApprovalState = 'versionState',
  Build12 = 'stateBuild12',
  CommentStatus = 'commentStatus',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Extension = 'extension',
  FileType = 'extension',
  FinalizeState = 'state',
  Name = 'name',
  Path = 'path',
  QrCodeState = 'qrCodeState',
  Snagstream = 'stateSnagstream',
  Tags = 'tags',
  ThreeDConversionState = 'threeDConversionState',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  VersionNumber = 'versionNumber',
}

export enum DocTableSubPropertyKeys {
  Build12State = 'state',
  CreatedByName = 'displayName',
  SnagstreamState = 'state',
  ThreeDConversionStatus = 'status',
  UpdatedByName = 'displayName',
}

export enum Position {
  Actionbar = 'actionbar',
  Contextmenu = 'contextmenu',
  Sidebar = 'sidebar',
}

export type PositionType =
  | Position.Actionbar
  | Position.Contextmenu
  | Position.Sidebar;

export type OpenCdeRequest = {
  callbackUrl: string;
  cdeApplication: string;
  cdeSelect: CdeActions | undefined;
  expiresIn: number;
  state?: UploadDocumentsResponse[];
  supportedFileExtensions?: string[];
};

export type OpenCdeSaveMetadata = {
  documentId: string;
  metadata: InstanceForDocumentInput[];
  name: string;
};

export type OpenCdeDocumentToUpload = {
  documentCurrent: Document | null;
  documentId: string;
  metadata: {
    answers: InstanceForDocumentInput[];
  };
  name: string;
  newMetadataInstanceId: string | undefined;
  publishDocument: boolean;
  session_file_id: string | undefined;
  version: OpenCdeDocumentVersion | undefined;
  version_name_instead_of_document_name_on_upload: UploadFileOption | undefined;
};

export type OpenCdeDocumentVersion = {
  newVersionFor: {
    id: string;
    metadataInstanceId: string;
    name: string;
  } | null;
  versionNumber: number;
};

export type UploadDocumentsResponse = {
  document_id?: string;
  expires_in?: number;
  file_name?: string;
  session_file_id: string;
};

export enum AppPermission {
  GroupRead = 'group.read',
  GroupUpdate = 'group.update',
  OrganizationRead = 'organization.read',
  OrganizationUpdate = 'organization.update',
  ProjectCreate = 'project.create',
  ProjectDelete = 'project.delete',
  ProjectRead = 'project.read',
  ProjectUpdate = 'project.update',
}

export enum CdeActions {
  CdeSelect = 'cde-select',
  CdeUpload = 'cde-upload',
  CdeView = 'cde-view',
}

export interface DocumentAndVersionType {
  document: Document | null;
  version: DocumentVersion | null;
}

export type SelectedIdName = {
  id: string;
  name: string;
};

export type SelectedObject = {
  [key: string]: string;
};

export enum CommandStatus {
  Pending = 0,
  Acknowledged = 1,
  Done = 2,
  Failed = 3,
}

export enum ColumnSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type DocumentIdsToConvertToDrawings = {
  selectedCount: number;
  supportedDocuments: DocumentVisualContextInput[];
};

export type MoveDocumentCountData = {
  count: number;
  total: number;
};

export enum UserGroupTabs {
  All = 'All',
  Groups = 'Groups',
  Users = 'Users',
}

export type RenderCustomOptionProps = {
  option: Option;
  props: React.HTMLAttributes<HTMLLIElement>;
};

export type RenderCustomInputProps = {
  hasBorder?: boolean;
  onDelete?: (event: any) => void;
  option: Option;
};

export enum RenderOptionType {
  UserCard = 'UserCard',
}

export type DMSProjectLink = {
  id: string;
  name: string;
  parentFolderId: string;
  path: string;
  projectId: string;
};
