import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { DocumentService } from '@pro4all/documents/data-access';
import { Routes, TrackingEvent } from '@pro4all/shared/config';
import { ActionProps } from '@pro4all/shared/types';
import { useAnalytics } from '@pro4all/shared/vendor';

import { usePubliclySharedDocsContext } from '../../publicly-shared-docs/PubliclySharedDocsContext';
import { useSharedDocsContext } from '../../shared-docs/SharedDocsContext';

export const useDownloadZipButtonProps = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useAnalytics();
  const publicDownloadsContext = usePubliclySharedDocsContext();
  const privateDownloadsContext = useSharedDocsContext();
  const isPublic = Boolean(
    useRouteMatch({
      path: Routes.publiclySharedDocs,
    })
  );

  const downloadZip = async () => {
    try {
      const zipDownloadUrl = isPublic
        ? publicDownloadsContext?.data?.zipDownloadUrl
        : privateDownloadsContext?.data?.zipDownloadUrl;
      track(TrackingEvent.DownloadZipSelected);
      enqueueSnackbar(t('Download started'));
      window.location.assign(zipDownloadUrl);
    } catch (exception) {
      enqueueSnackbar(t('Something went wrong'));
    }
  };

  const downloadZipButtonProps: ActionProps = {
    ariaLabel: t('Download .zip'),
    dataTestId: 'download-zip-button',
    key: 'download-zip-button',
    label: t('Download all as .zip'),
    onClick: downloadZip,
    startIcon: 'download',
  };

  return { downloadZipButtonProps };
};
