import * as React from 'react';

import { Paper, Table } from '@pro4all/shared/mui-wrappers';
import { TableBody } from '@pro4all/shared/mui-wrappers';
import { TableCell } from '@pro4all/shared/mui-wrappers';
import { TableHead } from '@pro4all/shared/mui-wrappers';
import { TableRow } from '@pro4all/shared/mui-wrappers';
import { TableContainer } from '@pro4all/shared/mui-wrappers';

type DataObject = {
  [key: string]: React.ReactNode;
};

interface PlainTableProps {
  columns: string[];
  data: DataObject[];
}

export const PlainTable = ({ columns, data }: PlainTableProps) => (
  <TableContainer
    component={Paper}
    sx={{
      '& .MuiPaper-root': {
        boxShadow: 'none',
      },
      boxShadow: 'none',
      maxHeight: 300,
      overflow: 'auto',
    }}
  >
    <Table aria-label="sticky table" stickyHeader>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column}>{column}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            {columns.map((column) => (
              <TableCell key={`${index}-${column}`}>{row[column]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
