import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FilterType,
  QualityControlInstance,
  SearchQcInstance,
} from '@pro4all/graphql';
import { Action } from '@pro4all/shared/config';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { Box, Divider } from '@pro4all/shared/mui-wrappers';
import { SelectSnagFilterModal } from '@pro4all/shared/qc-search-metadata-modal';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Loader } from '@pro4all/shared/ui/loader';
import { BigMessage } from '@pro4all/shared/ui/messages';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

import { AnswersPercentageContextProvider } from './form-answers-percentages/AnswersPercentageContext';
import { ResultSidebar } from './result-sidebar/ResultSidebar';
import { ResultsActionBar } from './ResultsActionBar';
import ResultsListMobile from './ResultsListMobile';
import { useColumns } from './useColumnsSearchResult';

export const SearchResultsTable = () => {
  const { t } = useTranslation();
  const {
    searchParams,
    params: { projectId },
  } = useRouting(true);

  const isMobileScreen = useIsMobileScreen();
  // TODO: Move this to a hook to be used with document search too?
  const currentParam = searchParams.get('taskId') || searchParams.get('id');
  const [selectedResult, setSelectedResult] = useState<string | undefined>(
    currentParam || undefined
  );

  useEffect(() => {
    setSelectedResult(currentParam || undefined);
  }, [currentParam, searchParams]);

  const { documents, loading, setSelectedDocument } =
    useSnagFormSearchContext();

  const columns = useColumns();

  const { track } = useAnalytics();

  const searchResults = useMemo(() => documents || [], [documents]);

  const getInstancetType = (resultId: string) =>
    searchResults?.find((qcInstance) => qcInstance.id === resultId)?.type;

  const getInstanceName = (resultId: string) =>
    searchResults?.find((qcInstance) => qcInstance.id === resultId)?.name;

  // const onRowClick = useViewResult(projectId, searchResults);

  useSetItemsInLocalState<SearchQcInstance>(searchResults || []);

  const handleSelect = async (rowDocument: SearchQcInstance) => {
    const { id } = rowDocument;

    track(Action.ItemOpened, {
      id,
      location: 'Quality control results',
      name: getInstanceName(rowDocument.id),
      projectId,
      type: getInstancetType(rowDocument.id),
    });

    searchParams.set({
      action: 'viewResult',
      id,
    });
    setSelectedDocument(rowDocument);
  };

  const {
    state: { items },
    deleteItems,
    restoreItems,
  } = useOptimisticResponseContext<SearchQcInstance>();

  const qcMetadataModalParam = searchParams.get('qcMetadataModal');

  return (
    <TableContextProvider
      columns={columns}
      id="table-qc-results"
      idFilteringSorting={`table-qc-results-${projectId}`}
      items={items}
    >
      <FilterContextProvider<QualityControlInstance>>
        {!isMobileScreen && (
          <ResultsActionBar
            deleteItems={deleteItems}
            restoreItems={restoreItems}
          />
        )}
        {isMobileScreen && <Divider />}
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%;',
            overflowY: isMobileScreen ? 'auto' : 'inherit',
          }}
        >
          {loading ? (
            <Loader />
          ) : isMobileScreen ? (
            <ResultsListMobile
              documents={searchResults}
              onClick={(document) => {
                track(Action.ItemOpened, {
                  id: document.id,
                  location: 'Quality control results',
                  name: getInstanceName(document.id),
                  projectId,
                  type: getInstancetType(document.id),
                });

                searchParams.set({
                  action: 'viewResult',
                  id: document.id,
                  taskId: undefined,
                });
              }}
            />
          ) : (
            <Table
              emptyRenderer={
                <BigMessage
                  shapeName="simplify"
                  text={t(
                    "We couldn't find any results matching your search. Try different or fewer keywords or adjust your filters"
                  )}
                  title={t('No results found')}
                />
              }
              enableKeyboardControl
              onRowClick={handleSelect}
              selectedId={selectedResult || undefined}
            />
          )}
          {Boolean(qcMetadataModalParam) && (
            <SelectSnagFilterModal
              filterType={qcMetadataModalParam as FilterType}
            />
          )}
          <PhotoProvider>
            <AnswersPercentageContextProvider>
              <ResultSidebar />
            </AnswersPercentageContextProvider>
          </PhotoProvider>
        </Box>
      </FilterContextProvider>
    </TableContextProvider>
  );
};
