import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';

import { Template, useDownloadFileNameConfigQuery } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { ActionBar } from '@pro4all/shared/ui/action-bar';
import { FullScreen } from '@pro4all/shared/ui/full-screen';

import { AvailableCharacters } from './AvailableCharacters';
import { AvailableMetaDataFields } from './AvailableMetaDataFields';
import * as Styled from './ConfigureDownloadNameForm.Styles';
import { useConfigureDownloadNameContext } from './ConfigureDownloadNameProvider';
import { Example } from './Example';
import { Title } from './Title';
import { useActions } from './useActions';

export const ConfigureDownloadNameForm = ({
  selectedTemplate,
}: {
  selectedTemplate?: Template;
}) => {
  const { actions, onClose } = useActions({ templateId: selectedTemplate.id });

  const {
    setEnabled,
    setSegments,
    state: { enabled },
  } = useConfigureDownloadNameContext();

  // Query for the current configuration data.
  const { data } = useDownloadFileNameConfigQuery({
    fetchPolicy: 'no-cache',
    variables: { templateId: selectedTemplate.id },
  });

  // Fill the context with the current configuration data.
  useEffect(() => {
    if (data && data.downloadFileNameConfig) {
      const { enabled, segments } = data.downloadFileNameConfig;

      const segmentsState = segments.map((segment) => {
        const deleteId = uuid();
        return {
          deleteId,
          id: segment.id,
          type: segment.type,
        };
      });
      setEnabled(enabled);
      setSegments(segmentsState);
    }
  }, [data, setEnabled, setSegments]);

  return (
    <FullScreen backButtonText="Back to meta data template" onClose={onClose}>
      <Box p={2}>
        <Styled.Container>
          <Title />
          {enabled && (
            <Styled.ConfigurationWrapper>
              <AvailableMetaDataFields />
              <AvailableCharacters />
              <Example />
            </Styled.ConfigurationWrapper>
          )}
          <Styled.BottomActionBarWrapper>
            <Styled.BottomActionBar>
              <ActionBar mainActions={actions} />
            </Styled.BottomActionBar>
          </Styled.BottomActionBarWrapper>
        </Styled.Container>
      </Box>
    </FullScreen>
  );
};
