import type { RemoveStampInput, Stamp, StampParams } from '@pro4all/graphql';
import { StampType } from '@pro4all/graphql';

import { Rect } from '../shape';
import * as Stamps from '../stamps';
import { Dimensions, Position } from '../types';

import type {
  Canvas as CanvasState,
  Shape as ShapeState,
  StamperDocument,
} from './state';

export const toShape =
  ({ height, width }: CanvasState) =>
  ({
    id,
    scaleY,
    scaleX,
    offsetX,
    offsetY,
    type,
    currentPosition,
    currentDimensions,
  }: Partial<Stamp> & {
    currentDimensions?: Dimensions;
    currentPosition?: Position;
  }): ShapeState => {
    const Stamp = Stamps[type ?? StampType.QrCode];

    // Use current dimensions if available, otherwise calculate from scale or use defaults
    const shapeHeight =
      currentDimensions?.height ?? (scaleY ? height * scaleY : 100);
    const shapeWidth =
      currentDimensions?.width ?? (scaleX ? width * scaleX : 100);

    // Use current position if available, otherwise calculate from offset or use defaults
    const shapeX = currentPosition?.x ?? (offsetX ? width * (1 - offsetX) : 10);
    const shapeY =
      currentPosition?.y ??
      (offsetY && scaleY ? height * (1 - (offsetY + scaleY)) : 10);

    return {
      children: <Stamp />,
      id,
      rect: new Rect({
        constrainProportions: true,
        height: shapeHeight,
        width: shapeWidth,
        x: shapeX,
        y: shapeY,
      }),
      type,
    };
  };

export const toStampInput =
  ({ height, width }: CanvasState) =>
  ({ id, rect }: ShapeState): StampParams => ({
    // 0,0 == bottom-right in the API and an object on the PDF is drawn towards
    // top-right, so we need to pass in the inverted x/y offsets of the
    // bottom-left anchor of our shape, relative to the canvas.
    offsetX: 1 - rect.left / width,
    offsetY: 1 - rect.bottom / height,

    scaleX: rect.width / width,
    scaleY: rect.height / height,
    stampId: id,
  });

export const toRemoveStampInput = (
  documentId: StamperDocument['documentId'],
  stamps: Stamp[] = []
): RemoveStampInput[] =>
  stamps
    .filter(({ id, type }) => id && type === StampType.QrCode)
    .map(({ id }) => ({ documentId, id }));
