import { useTranslation } from 'react-i18next';

import { Document } from '@pro4all/graphql';
import { TaskType } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

type SearchParams = { action: string; id?: string; taskType: TaskType };

export const useCreateDocumentTaskAction = ({
  documents,
}: {
  documents: Document[];
}) => {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const createDocumentTaskAction: ActionProps = {
    ariaLabel: t('Create document task'),
    dataTestId: 'create-document-task',
    disabled: false,
    key: 'create-document-task',
    label: t('Create document task'),
    onClick: () => {
      const searchParams: SearchParams = {
        action: 'createTask',
        taskType: TaskType.Document,
      };
      //scenario: right click a document to create doc task via context menu
      if (documents.length === 1) searchParams['id'] = documents[0].id;
      goTo({
        searchParams,
        state: { selectedDocuments: documents },
      });
    },
    startIcon: 'expectedDocument',
  };
  return createDocumentTaskAction;
};
