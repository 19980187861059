import { clearFolderTreeAction } from './actions/clearFolderTreeAction';
import { createDMSItemAction } from './actions/createFolderAction';
import { deleteDMSItemAction } from './actions/deleteFolderAction';
import { documentUploadInFolderAction } from './actions/documentUploadInFolderAction';
import { folderEmptyAfterDocumentDeleteAction } from './actions/folderEmptyAfterDocumentDeleteAction';
import { moveFolderAction } from './actions/moveFolderAction';
import { mutateFolderPropAction } from './actions/renameFolderAction';
import { restoreFolderAction } from './actions/restoreFolderAction';
import { setInitialFolderTreeAction } from './actions/setInitialFolderTreeAction';
import { toggleEmptyFoldersAction } from './actions/toggleEmptyFoldersAction';
import { Action, ActionType, State } from './types';

export function folderTreeReducerOuter(state: State, action: Action) {
  switch (action.type) {
    case ActionType.CLEAR_FOLDER_TREE: {
      return clearFolderTreeAction(state);
    }
    case ActionType.CREATE_DMS_ITEM: {
      return createDMSItemAction({ payload: action.payload, state });
    }
    case ActionType.DELETE_DMS_ITEM: {
      return deleteDMSItemAction({ payload: action.payload, state });
    }
    case ActionType.DOCUMENT_UPLOAD_IN_FOLDER: {
      return documentUploadInFolderAction({ payload: action.payload, state });
    }
    case ActionType.FOLDER_EMPTY_AFTER_DOCUMENT_DELETE: {
      return folderEmptyAfterDocumentDeleteAction({
        payload: action.payload,
        state,
      });
    }
    case ActionType.MOVE_FOLDER: {
      return moveFolderAction({ payload: action.payload, state });
    }
    case ActionType.MUTATE_FOLDER: {
      return mutateFolderPropAction({ payload: action.payload, state });
    }
    case ActionType.RESTORE_FOLDER: {
      return restoreFolderAction(state);
    }
    case ActionType.SET_INITIAL_FOLDER_TREE: {
      return setInitialFolderTreeAction({ payload: action.payload, state });
    }
    case ActionType.SET_LOADING_FOLDER_DATA: {
      return { ...state, loadingFolderData: action.payload };
    }
    case ActionType.TOGGLE_EMPTY_FOLDERS_DISPLAY: {
      return toggleEmptyFoldersAction(state);
    }

    default:
      return state;
  }
}
