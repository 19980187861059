import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  FieldDefinition,
  FormLinkedSnag,
  QualityControlInstance,
} from '@pro4all/graphql';
import { unnestInstance } from '@pro4all/metadata/ui/utils';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useUnlinkSnag } from '@pro4all/shared/qc-sources/snag-action-buttons';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card } from '@pro4all/shared/ui/general';
import { useSearchInput } from '@pro4all/shared/ui/search-input';

import { useTbqLocationData } from './useTbqLocationData';

type UnlinkTbqQuestionDialogProps = {
  fieldDefinitions: FieldDefinition[];
  formInstances?: QualityControlInstance[];
  linkedSnags: FormLinkedSnag[];
  onClose: () => void;
};

export const UnlinkTbqQuestionDialog = ({
  formInstances,
  fieldDefinitions,
  onClose,
  linkedSnags = [],
}: UnlinkTbqQuestionDialogProps) => {
  const { t } = useTranslation();
  const { query, searchInput } = useSearchInput();
  const locationData = useTbqLocationData();

  const UnlinkSnag = useUnlinkSnag({
    linkedSnags,
  });

  const instanceItems: FieldDefinition[] = unnestInstance(fieldDefinitions);

  const findParentSectionQuestion = (sectionId: string) => {
    const question: FieldDefinition | undefined = instanceItems.find(
      (section) => section.id === sectionId
    );
    return question ? question.name : '';
  };

  const getTaskName = (linkId: string | undefined | null) => {
    const form = formInstances?.find((instance) =>
      instance.linkedSnags?.some((snag) => snag?.linkId === linkId)
    );
    return form?.task?.name;
  };

  const getQuestionName = (fieldId: string | undefined | null) =>
    instanceItems.find((item) => fieldId === item.id)?.name;

  const getParentSectionName = (fieldId: string | undefined | null) => {
    const parentSectionId = instanceItems.find(
      (item) => fieldId === item.id
    )?.parentSectionId;
    if (!parentSectionId) return '';
    return findParentSectionQuestion(parentSectionId);
  };

  const getParentSectionNameOfParent = (fieldId: string | undefined | null) => {
    const parentSection = instanceItems.find((item) => fieldId === item.id);
    const parentSectionOfParentId = instanceItems.find(
      (item) => parentSection?.parentSectionId === item.id
    )?.parentSectionId;
    if (!parentSectionOfParentId) return '';
    return findParentSectionQuestion(parentSectionOfParentId);
  };

  const linkedSnagsFields = linkedSnags.map((linkedInstance) => ({
    fieldId: linkedInstance.appData?.fieldId,
    linkId: linkedInstance.linkId,
    name: getQuestionName(linkedInstance.appData?.fieldId),
    parentSectionName: getParentSectionName(linkedInstance.appData?.fieldId),
    parentSectionNameOfParent: getParentSectionNameOfParent(
      linkedInstance.appData?.fieldId
    ),
    taskName: getTaskName(linkedInstance.linkId),
  }));

  const filteredQuestions = linkedSnagsFields.filter(({ name }) =>
    name ? name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) : false
  );

  const cardQuestions = filteredQuestions?.map(
    ({
      fieldId,
      linkId,
      name,
      parentSectionName,
      parentSectionNameOfParent,
      taskName,
    }) => {
      const cardTitle = name || '';

      const mainCategory = parentSectionNameOfParent
        ? `${parentSectionNameOfParent}, `
        : '';
      const subCategory = parentSectionName ? `${parentSectionName}, ` : '';
      const tskName =
        locationData && locationData.taskName
          ? locationData.taskName
          : taskName
          ? taskName
          : '';

      const cardMeta = `${mainCategory} ${subCategory} ${tskName}`;

      return (
        <Card
          disabledButtonBorder
          iconName="questionMark"
          key={linkId}
          menuItems={[
            {
              label: `Select question`,
              onClick: () => UnlinkSnag({ fieldId: fieldId ?? '' }),
              startIcon: 'unlink',
            },
          ]}
          meta={[cardMeta]}
          title={cardTitle}
        />
      );
    }
  );

  return (
    <FloatingModal
      maxWidth="md"
      onClose={() => onClose()}
      open
      title={t('Select question to unlink from snag')}
    >
      <FloatingModal.Header iconName="unlink">
        {t('Select question to unlink')}
      </FloatingModal.Header>
      <ContentWrapper>
        {searchInput}
        {!instanceItems?.length && (
          <p>
            No questions found for <strong>{query}</strong>
          </p>
        )}

        {cardQuestions}
      </ContentWrapper>
      <FloatingModal.Footer>
        <Button color="inherit" onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
      </FloatingModal.Footer>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
