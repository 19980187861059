type ResizeImageProps = {
  file: File;
  maxHeight: number;
  maxWidth: number;
};

// Method to decrease size an image file.
export const resizeImage = async ({
  file,
  maxHeight,
  maxWidth,
}: ResizeImageProps): Promise<File> => {
  const img = document.createElement('img');
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) throw new Error('Canvas context cannot be created');

  // Convert the file to a data-URL
  const dataUrl = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });

  // Load the image in the img-element
  img.src = dataUrl;
  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  // Calculate the new sizes
  const aspectRatio = img.width / img.height;
  let newWidth = maxWidth;
  let newHeight = maxHeight;

  if (aspectRatio > 1) {
    // Width is bigger than height
    newHeight = Math.min(maxHeight, maxWidth / aspectRatio);
  } else {
    // Height is bigger than width
    newWidth = Math.min(maxWidth, maxHeight * aspectRatio);
  }

  // Define canvas size
  canvas.width = newWidth;
  canvas.height = newHeight;

  // Draw the scaled image in the canvas
  ctx.drawImage(img, 0, 0, newWidth, newHeight);

  // Convert the canvas to a Blob
  const resizedBlob = await new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) resolve(blob);
      else reject(new Error('Could not generate Blob from the canvas'));
    }, file.type || 'image/jpeg');
  });

  // Convertert the Blob back to a file
  return new File([resizedBlob], file.name, { type: file.type });
};
