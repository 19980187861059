export type IdentityTypes = {
  bypassCache?: boolean;
  id: string;
  profilePictureEntity: ProfilePictureEntity;
};

export enum ProfilePictureEntity {
  Organization = 'organization',
  Project = 'Project',
  User = 'User',
}

export enum IdentityTagType {
  Group = 'Group',
  Project = 'Project',
  User = 'User',
}

export type FetchProfilePictureProps = Pick<
  IdentityTypes,
  'id' | 'profilePictureEntity'
>;

export type GetProfileUrlProps = Pick<
  IdentityTypes,
  'bypassCache' | 'id' | 'profilePictureEntity'
>;
