import React from 'react';

import { useStamp } from '../data';
import { Action, Shape, StamperDocument, State, Status } from '../store';

import { useGetParams } from './useGetParams';

export function useStampOne(
  queue: StamperDocument[],
  applyToAllPages: boolean | undefined,
  shapes: Shape[],
  refetch: () => void,
  state: State,
  dispatch: React.Dispatch<Action>
) {
  const stamp = useStamp();

  const params = useGetParams(state, shapes, applyToAllPages);

  const stampOne = async () => {
    dispatch({ status: Status.Pending, type: 'status' });
    const current = state.queue[state.index];
    stamp(current, params);
    refetch();
    dispatch({ type: 'next' });
  };

  return { stampOne };
}
