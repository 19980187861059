import { Route, Switch } from 'react-router-dom';

import { TbqAnswer, TbqRieResult } from '@pro4all/graphql';
import { Drawing } from '@pro4all/quality-control/ui/floor-plan';
import { MapLinkingContextProvider } from '@pro4all/quality-control/ui/maps';
import { ReportingMain } from '@pro4all/quality-control/ui/report-options';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
} from '@pro4all/quality-control/ui/results';
import { Routes } from '@pro4all/shared/config';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Main } from '@pro4all/shared/ui/layout';
import { Loader } from '@pro4all/shared/ui/loader';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { TBQMultiBrandcheckResults } from './tbq/results/TBQMultiBrandcheckResults';
import { TBQMultiRieResults } from './tbq/results/TBQMultiRieResults';
import { TBQRieResults } from './tbq/results/TBQRieResults';
import { EditObjects } from './edit';
import * as Messages from './Messages';
import { ObjectsProvider } from './ObjectsContext';
import { TBQResults } from './tbq';
import { useObjects } from './useObjects';
import { ViewObjects } from './view';

const ObjectsUI = () => {
  const objectsFeature = useFeatureFlag('objects');
  const { searchParams } = useRouting();
  const { error, loading, objects, ...context } = useObjects();

  if (searchParams.get('action') === 'createReport') {
    return (
      <MapLinkingContextProvider>
        <ReportingMain />
      </MapLinkingContextProvider>
    );
  }

  if (!objectsFeature) return <Box p={3}>Nothing to see here.</Box>;
  if (error) return <Messages.Error error={error} />;
  if (!objects.length && loading) return <Loader />;

  return (
    <Main>
      <ObjectsProvider
        error={error}
        loading={loading}
        objects={objects}
        {...context}
      >
        {searchParams.is('action', 'editObjects') ? (
          <EditObjects />
        ) : (
          <ViewObjects />
        )}
      </ObjectsProvider>
    </Main>
  );
};

export const Objects = () => (
  <MapLinkingContextProvider>
    <Switch>
      <Route path={[Routes.objectTBQResults, Routes.projectObjectTBQResults]}>
        <OptimisticResponseProvider<TbqAnswer>>
          <TBQResults />
        </OptimisticResponseProvider>
      </Route>
      <Route
        path={[
          Routes.objectTBQMultiBrandcheckResults,
          Routes.projectObjectTBQMultiBrandcheckResults,
        ]}
      >
        <TBQMultiBrandcheckResults />
      </Route>
      <Route
        path={[Routes.projectObjectTBQRieResults, Routes.objectTBQRieResults]}
      >
        <OptimisticResponseProvider<TbqRieResult>>
          <TBQRieResults />
        </OptimisticResponseProvider>
      </Route>
      <Route
        path={[
          Routes.projectObjectTBQMultiRieResults,
          Routes.objectTBQMultiRieResults,
        ]}
      >
        <OptimisticResponseProvider<TbqRieResult>>
          <TBQMultiRieResults />
        </OptimisticResponseProvider>
      </Route>
      <Route exact path={[Routes.objectDrawing, Routes.projectObjectDrawing]}>
        <Drawing />
      </Route>
      <Route path={[Routes.objects, Routes.projectObjects]}>
        <ObjectsUI />
      </Route>
    </Switch>
    <PhotoProvider>
      <AnswersPercentageContextProvider>
        <ResultSidebar />
      </AnswersPercentageContextProvider>
    </PhotoProvider>
  </MapLinkingContextProvider>
);
