import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
  Message,
  ReferenceInput,
  useCommentThreadLazyQuery,
  useCommentThreadQuery,
  User,
} from '@pro4all/graphql';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isDefined, sortBy } from '@pro4all/shared/utils';

import { CommentForm } from './forms/';
import { useWebSocket } from './WebSocketHook';

interface ThreadContextValue {
  callbackOptions?: CallbackOptions;
  comments?: Message[] | null;
  loading: boolean;
  projectId?: string;
  references: ReferenceInput[];
  setProjectId: React.Dispatch<React.SetStateAction<string | undefined>>;
  target: ReferenceInput;
  threadId: string;
  threadResolved: boolean;
}

interface CallbackOptions {
  onAttachmentAdded: () => void;
  onAttachmentButtonClicked: () => void;
  onAttachmentDownload: () => void;
  onAttachmentRemoved: () => void;
  onInputClicked: () => void;
  onSubmit: () => void;
}

interface ProviderProps {
  callbackOptions?: CallbackOptions;
  filterFn?: (message: Message) => boolean;
  newComment?: boolean;
  pollInterval?: number;
  references: ReferenceInput[];
  target: ReferenceInput;
  targetId: string;
}

export interface CommentProps
  extends Omit<
    Message,
    'body' | 'id' | 'threadId' | 'fromId' | 'references' | 'createdBy'
  > {
  body?: string;
  createdBy?: User;
  fromId?: string;
  messageId?: string;
  referenceText?: string;
  references?: Message['references'];
  threadId: string;
}

export const ThreadContext = createContext<ThreadContextValue | undefined>(
  undefined
);

export const useThreadContext = () => useContext(ThreadContext);

export const ThreadContextProvider: React.FC<ProviderProps> = (props) => {
  const liveUpdates = useFeatureFlag('comments-live-updates');

  if (liveUpdates) {
    return <LiveThreadContextProvider {...props} />;
  } else {
    return <PollingThreadContextProvider {...props} />;
  }
};

export const LiveThreadContextProvider: React.FC<ProviderProps> = ({
  children,
  callbackOptions,
  filterFn = () => true,
  newComment,
  references,
  target,
  targetId,
}) => {
  const [fetchThread, { loading, data }] = useCommentThreadLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: { targetId },
  });
  const { lastUpdate } = useWebSocket(targetId);

  useEffect(() => {
    async function fetchData() {
      await fetchThread();
    }
    fetchData();
  }, [fetchThread, lastUpdate, targetId]);

  /* When changing docs in between pending poll requests, the doc will update before new thread data comes in.
  Users will see the previous thread until next poll. This is solved by matching targetIds between the thread(apollo) and props(params).
  We will interpret a mismatch as a loading state */
  const targetMatch =
    data?.commentThread?.targetId && data.commentThread.targetId === targetId;

  const [projectId, setProjectId] = useState<string | undefined>(undefined);

  const comments = targetMatch
    ? data?.commentThread?.messages
        ?.filter((message) => isDefined(message) && !message.deleted)
        .filter(filterFn)
        .sort(sortBy({ key: 'createdAt' }))
        .reverse()
    : []; // Return [] if targetId does not match. This will load a spinner until next poll;

  const threadResolved = !comments?.find((comment) => !comment.resolved);
  const threadId = comments?.length
    ? comments[0].threadId
    : `new-thread-${uuid()}`;

  const value = {
    callbackOptions,
    comments,
    loading,
    projectId,
    references,
    setProjectId,
    target,
    threadId,
    threadResolved,
  };

  return (
    <ThreadContext.Provider value={value}>
      <Container>
        {newComment && <CommentForm newComment />}
        {children}
      </Container>
    </ThreadContext.Provider>
  );
};

export const PollingThreadContextProvider: React.FC<ProviderProps> = ({
  children,
  callbackOptions,
  filterFn = () => true,
  newComment,
  pollInterval = 5000,
  references,
  target,
  targetId,
}) => {
  const { data, loading } = useCommentThreadQuery({
    pollInterval,
    skip: !targetId,
    variables: { targetId },
  });

  /* When new thread data is received while changing docs. It could happen that comments of previous targetId is shown
    Ensure these are filtered always.
  */
  const targetMatch =
    data?.commentThread?.targetId && data.commentThread.targetId === targetId;

  const [projectId, setProjectId] = useState<string | undefined>(undefined);

  const comments = targetMatch
    ? data?.commentThread?.messages
        ?.filter((message) => isDefined(message) && !message.deleted)
        .filter(filterFn)
        .sort(sortBy({ key: 'createdAt' }))
        .reverse()
    : [];

  const threadResolved = !comments?.find((comment) => !comment.resolved);
  const threadId = comments?.length
    ? comments[0].threadId
    : `new-thread-${uuid()}`;

  const value = {
    callbackOptions,
    comments,
    loading,
    projectId,
    references,
    setProjectId,
    target,
    threadId,
    threadResolved,
  };

  return (
    <ThreadContext.Provider value={value}>
      <Container>
        {newComment && <CommentForm newComment />}
        {children}
      </Container>
    </ThreadContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 1.5rem 1.5rem;
`;
