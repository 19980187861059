import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { stringToPlainText } from '@pro4all/communication/ui/general';
import { Message } from '@pro4all/graphql';
import { IdentityService } from '@pro4all/shared/api-services';
import {
  Box,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { Avatar, AvatarSize } from '@pro4all/shared/ui/avatar';
import { Icon } from '@pro4all/shared/ui/icons';

interface MessageListMobileProps {
  itemClick: (message: Message) => void;
  messages: Message[];
}

const MessageListMobile: React.FC<MessageListMobileProps> = ({
  itemClick,
  messages,
}) => {
  const { t } = useTranslation();

  const bodyLengthTreshold = 80;

  return (
    <List sx={{ width: '100%' }}>
      {messages &&
        messages.map((message) => {
          const createdById = message?.createdBy?.id;
          const url = IdentityService.getProfileUrl({
            id: createdById,
            profilePictureEntity: ProfilePictureEntity.User,
          });

          return (
            <ListItemButton
              dense
              divider
              key={message.id}
              onClick={() => itemClick(message)}
            >
              <ListItemAvatar>
                <Avatar
                  name={message?.createdBy?.displayName || ''}
                  size={AvatarSize.AvatarSizeS}
                  src={url}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      color="text.primary"
                      sx={{
                        fontWeight: message.read ? 'normal' : 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {message.subject ? message.subject : t('(no subject)')}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        minWidth: '110px',
                      }}
                    >
                      {message.createdAt &&
                        dayjs(message.createdAt).format('YYYY-MM-DD HH:mm')}
                    </Typography>
                  </Box>
                }
                secondary={
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        color="text.secondary"
                        sx={{ fontWeight: 'inherit' }}
                        variant="body2"
                      >
                        {message.createdBy.displayName}
                      </Typography>
                      {message.attachments && message.attachments.length > 0 && (
                        <IconButton edge="end">
                          <Icon iconName="attachment" />
                        </IconButton>
                      )}
                    </Box>
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontStyle: 'italic',
                        fontWeight: 'inherit',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      variant="body2"
                    >
                      {message.body
                        ? `${stringToPlainText(message.body).substring(
                            0,
                            bodyLengthTreshold
                          )}${
                            stringToPlainText(message.body).length >=
                            bodyLengthTreshold
                              ? '...'
                              : ''
                          }`
                        : ''}
                    </Typography>
                  </>
                }
                secondaryTypographyProps={{
                  fontWeight: message.read ? 'normal' : 'bold',
                }}
              />
            </ListItemButton>
          );
        })}
    </List>
  );
};

export default MessageListMobile;
