import React from 'react';
import { useDrag } from 'react-dnd';

import { FieldDefinition, ScopeType } from '@pro4all/graphql';
import { DndTypes } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Icon } from '@pro4all/shared/ui/icons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { FieldTypeIcon } from '../field-types/FieldTypeIcon';

import * as Styled from './FieldCard.styles';
import { useFieldContext } from './TemplateMutationContext';

interface FieldCardProps {
  fieldDefinition: FieldDefinition;
}

export const FieldCardCustom: React.FC<FieldCardProps> = ({
  fieldDefinition,
}) => {
  const { displayName, name, scope, type } = fieldDefinition;
  const { appendField } = useFieldContext();
  const { displayNames } = useMetaDataContext();
  const { params } = useRouting();

  const [, drag] = useDrag({
    item: { fieldDefinition },
    type: DndTypes.NOT_INCLUDED_FIELD,
  });

  return drag(
    <div>
      <Styled.Font variant="h6">
        <Styled.Wrapper mb={1} onClick={() => appendField(fieldDefinition)}>
          <Styled.FieldCard>
            <FieldTypeIcon type={type} />
            <MiddleEllipsis
              text={displayNames ? displayName : name}
            ></MiddleEllipsis>
          </Styled.FieldCard>
          {scope.type === ScopeType.Organization && params.projectId && (
            <Styled.OrganizationIcon>
              <Icon color="primary" iconName="link" />
            </Styled.OrganizationIcon>
          )}
        </Styled.Wrapper>
      </Styled.Font>
    </div>
  );
};
