import React from 'react';

import { Group } from '@pro4all/graphql';
import { IdentityTagType } from '@pro4all/shared/types';

import { IdentityTag } from './IdentityTag';

export const GroupTag = ({ group }: { group: Group }) => {
  const { id, displayName, totalUsers } = group || {};
  return (
    <IdentityTag
      id={id}
      label={displayName}
      totalUsers={totalUsers || 0}
      type={IdentityTagType.Group}
    />
  );
};
