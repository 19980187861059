import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ActionProps } from '@pro4all/shared/types';
import { InfoPage } from '@pro4all/shared/ui/infopages';

import { useActionMoveToNewOrganization } from './actions/useActionMoveToNewOrganization';
import { useActionStayInCurrentOrganization } from './actions/useActionStayInCurrentOrganization';
import { MoveExistingUserProps } from './types';

export const MoveToNewOrganization = ({
  currentOrganizationName,
  inviterEmail,
  inviterName,
  newOrganizationName,
  updateMoveState,
}: MoveExistingUserProps) => {
  const { t } = useTranslation();
  const stayInCurrentOrganization = useActionStayInCurrentOrganization();
  const moveToNewOrganization = useActionMoveToNewOrganization(updateMoveState);

  const description = (
    <>
      <Trans
        components={{ strong: <strong /> }}
        defaults="{{inviterName}} ({{inviterEmail}}) has invited you to join <strong>{{- newOrganizationName}}</strong>. Read below what this means for your account when you are moving to another organization."
        values={{
          inviterEmail,
          inviterName,
          newOrganizationName,
        }}
      />
      <br />
      <br />
      {t('When moving you will')}:
      <ul>
        <li>
          {t(
            'be removed from all organization groups of {{- currentOrganizationName}}',
            { currentOrganizationName }
          )}
          ;
        </li>
        <li>
          {t(
            'no longer have access to documents of {{- currentOrganizationName}}',
            {
              currentOrganizationName,
            }
          )}
          ;
        </li>
        <li>
          {t(
            'no longer have access to templates of {{- currentOrganizationName}}',
            {
              currentOrganizationName,
            }
          )}
          .
        </li>
      </ul>
    </>
  );

  const actions: ActionProps[] = [
    {
      ariaLabel: 'Stay in current organization',
      color: 'inherit',
      dataTestId: 'stay-in-current-organization',
      key: 'stay-in-current-organization',
      label: t('Stay in {{- currentOrganizationName}}', {
        currentOrganizationName,
      }),
      onClick: () => stayInCurrentOrganization(),
      startIcon: 'download',
    },
    {
      ariaLabel: 'Move to new organization',
      color: 'inherit',
      dataTestId: 'move-to-new-organization',
      key: 'move-to-new-organization',
      label: t('Move to {{- newOrganizationName}}', {
        newOrganizationName,
      }),
      onClick: () => moveToNewOrganization(),
      startIcon: 'arrowForward',
    },
  ];

  return (
    <InfoPage
      actions={actions}
      buttonOrientation="horizontal"
      description={description}
      title={t('Move to new organization')}
      type="warning"
    />
  );
};
