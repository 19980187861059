import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  Document,
  DocumentVersionState,
  FinalizationState,
  Folder,
  StampStatus,
  useCreateDocumentMutation,
} from '@pro4all/graphql';
import { TrackingEvent } from '@pro4all/shared/config';
import { getAuthUserDisplayName } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { useAnalytics } from '@pro4all/shared/vendor';

import { FormFields } from './types';

export const useSubmit = ({ folder }: { folder: Folder }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();
  const {
    params: { projectId },
  } = useRouting();
  const { track } = useAnalytics();
  const [createDocument] = useCreateDocumentMutation();
  const { addItems } = useOptimisticResponseContext<Document>();

  return async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    let { fileType, name } = values;

    fileType = fileType.substring(0, 1) === '.' ? fileType : `.${fileType}`;

    name = `${name}${fileType}`;
    try {
      const createDocumentResponse = await createDocument({
        variables: {
          document: {
            expectedExtension: fileType,
            folderId: folder.id,
            name,
            projectId,
          },
        },
      });
      if (
        createDocumentResponse &&
        createDocumentResponse.data?.createDocument?.id
      ) {
        const expectedDocument: Document = {
          __typename: 'Document',
          extension: fileType,
          folderId: folder.id,
          id: createDocumentResponse.data?.createDocument?.id,
          isExpected: true,
          name,
          updatedBy: { displayName: userName, id: userId },
          versionNumber: 0,
        };

        addItems([
          {
            ...expectedDocument,
            hasPreviousVersionQr: false,
            qrCodeState: StampStatus.None,
            state: FinalizationState.NotFinalized,
            tags: null,
            versionState: DocumentVersionState.None,
          },
        ]);
      }
      const message = (
        <ItemChangedMessage
          description={MessageAction.Create}
          entityName={name}
          entityTypeTranslation={EntityTypeTranslation.ExpectedDocument}
        />
      );
      enqueueSnackbar(message);

      track(TrackingEvent.ExpectedDocumentCreated);
    } catch (e) {
      enqueueSnackbar(t('Something went wrong. Please try again.'));
    }

    helpers.resetForm();
  };
};
