import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { IdentityService } from '@pro4all/shared/api-services';
import { getAuthUserDisplayName } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { useGenericContext } from '@pro4all/shared/providers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { ActionProps } from '@pro4all/shared/types';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { Avatar, AvatarSize } from '@pro4all/shared/ui/avatar';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { Text } from '@pro4all/shared/ui/typography';

interface Position {
  left: number;
  top: number;
}

export const UserNameTopBar = () => {
  const userName = getAuthUserDisplayName();
  const { userId } = AuthService.getProfile();

  const profileRef = useRef<HTMLInputElement>(null);

  const [position, setPosition] = useState<Position>();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const showDeviceUi = useMediaQuery(theme.breakpoints.down('md'));

  const [profilePictureUser, setProfilePictureUser] = useState<
    string | undefined
  >(undefined);

  const {
    state: { reloadProfilePictures },
  } = useGenericContext();

  useEffect(() => {
    const url = IdentityService.getProfileUrl({
      bypassCache: true,
      id: userId,
      profilePictureEntity: ProfilePictureEntity.User,
    });
    setProfilePictureUser(url);
  }, [userId, reloadProfilePictures]);

  const { t } = useTranslation();
  const { goTo } = useRouting();

  const handleOpen = (event: React.MouseEvent) => {
    event && event.preventDefault();
    if (profileRef.current) {
      setPosition({
        left: profileRef.current.offsetLeft,
        top: profileRef.current.offsetTop + 47,
      });
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setPosition(undefined);
    setIsOpen(false);
  };

  const goToProfileAction: ActionProps = {
    ariaLabel: 'My profile',
    dataTestId: 'my-profile',
    disabled: false,
    key: 'my-profile',
    label: t('My profile'),
    onClick: (_) => goTo('myProfile'),
    startIcon: 'person',
  };

  const logOutAction: ActionProps = {
    ariaLabel: 'Sign out',
    dataTestId: 'sign-out',
    disabled: false,
    key: 'sign-out',
    label: t('Sign out'),
    onClick: (_) => goTo('logout'),
    startIcon: 'logout',
  };

  const menuItems: ActionProps[] = [goToProfileAction, logOutAction];

  return (
    <Box
      data-testid="profile-button"
      onClick={handleOpen}
      ref={profileRef}
      sx={{
        '&:hover': {
          backgroundColor: customColors.grey300,
        },
        alignItems: 'center',
        borderRadius: '30px',
        cursor: 'pointer',
        display: 'flex',
        pr: 2,
      }}
    >
      <Box sx={{ mr: 1 }}>
        <Avatar
          name={userName}
          size={AvatarSize.AvatarSizeS}
          src={profilePictureUser}
        />
      </Box>

      {!showDeviceUi && (
        <Box>
          <Text variant="h5">{userName}</Text>
        </Box>
      )}

      <ContextMenu
        data-testid="context-menu"
        initialPosition={position}
        menuItems={menuItems}
        onClose={() => {
          handleClose();
        }}
        open={isOpen}
      />
    </Box>
  );
};
