import { useMemo } from 'react';

import { Shape, State, toStampInput } from '../store';

export function useGetParams(
  state: State,
  shapes: Shape[],
  applyToAllPages: boolean | undefined
) {
  return useMemo(() => {
    if (!state.canvas || !shapes.length) return;
    return {
      ...shapes.map(toStampInput(state.canvas))[0],
      stampAllPages: applyToAllPages,
    };
  }, [state.canvas, shapes, applyToAllPages]);
}
