import React from 'react';

import { SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Forward: React.FC<SvgIconProps> = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.175 11L15.3 7.125C15.1 6.925 15 6.6875 15 6.4125C15 6.1375 15.1 5.9 15.3 5.7C15.5 5.51667 15.7375 5.425 16.0125 5.425C16.2875 5.425 16.5167 5.51667 16.7 5.7L21.3 10.3C21.4 10.4 21.4708 10.5083 21.5125 10.625C21.5542 10.7417 21.575 10.8667 21.575 11C21.575 11.1333 21.5542 11.2583 21.5125 11.375C21.4708 11.4917 21.4 11.6 21.3 11.7L16.7 16.3C16.5 16.5 16.2667 16.5958 16 16.5875C15.7333 16.5792 15.5 16.4833 15.3 16.3C15.1 16.1 14.9958 15.8667 14.9875 15.6C14.9792 15.3333 15.075 15.1 15.275 14.9L19.175 11ZM13.175 12H7C6.16667 12 5.45833 12.2917 4.875 12.875C4.29167 13.4583 4 14.1667 4 15V18C4 18.2833 3.90417 18.5208 3.7125 18.7125C3.52083 18.9042 3.28333 19 3 19C2.71667 19 2.47917 18.9042 2.2875 18.7125C2.09583 18.5208 2 18.2833 2 18V15C2 13.6167 2.4875 12.4375 3.4625 11.4625C4.4375 10.4875 5.61667 10 7 10H13.175L10.3 7.125C10.1 6.925 10 6.6875 10 6.4125C10 6.1375 10.1 5.9 10.3 5.7C10.5 5.51667 10.7375 5.425 11.0125 5.425C11.2875 5.425 11.5167 5.51667 11.7 5.7L16.3 10.3C16.4 10.4 16.4708 10.5083 16.5125 10.625C16.5542 10.7417 16.575 10.8667 16.575 11C16.575 11.1333 16.5542 11.2583 16.5125 11.375C16.4708 11.4917 16.4 11.6 16.3 11.7L11.7 16.3C11.5 16.5 11.2667 16.5958 11 16.5875C10.7333 16.5792 10.5 16.4833 10.3 16.3C10.1 16.1 9.99583 15.8667 9.9875 15.6C9.97917 15.3333 10.075 15.1 10.275 14.9L13.175 12Z"
      fill="black"
      fillOpacity="0.56"
    />
  </svg>
);
