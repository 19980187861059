import { DropzoneOptions, FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export const useFileRejections = ({
  acceptedFileTypes,
  maxSize,
}: {
  acceptedFileTypes: string[];
  maxSize: number;
}): DropzoneOptions['onDropRejected'] => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const filterRejections = (fileRejections: FileRejection[], code: string) =>
    fileRejections
      .filter(
        (rejection) =>
          rejection.errors.filter((e) => e.code === code).length > 0
      )
      .map((rejection) => rejection.file.name);

  return (fileRejections) => {
    const sizeRejections = filterRejections(fileRejections, 'file-too-large');

    if (sizeRejections.length > 0) {
      enqueueSnackbar(
        `${t(
          'The maximum document size is {{size}}mb. The following documents will not be uploaded',
          { size: maxSize / 1000000 }
        )}: ${sizeRejections.join(', ')}`
      );
    }

    const typeRejections = filterRejections(
      fileRejections,
      'file-invalid-type'
    );

    if (typeRejections.length > 0) {
      enqueueSnackbar(
        `${t(
          'Only {{fileTypes}} file types are allowed. The following documents will not be uploaded',
          { fileTypes: acceptedFileTypes.join(', ') }
        )}: ${typeRejections.join(', ')}`
      );
    }
  };
};
