import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { Box } from '@pro4all/shared/mui-wrappers';
import { IdentityTagType, RenderOptionType } from '@pro4all/shared/types';
import { IdentityTag } from '@pro4all/shared/ui/identity-card';
import { TagProps } from '@pro4all/shared/ui/tag';

export type SelectedOptionCustomRenderingProps = {
  option: string;
  renderOption: RenderOptionType;
};

export const useOptionRendering = () => {
  const { projectData } = useProjectContext();
  const { organization } = useOrganizationContext();

  const selectableOptionRendering = ({
    option,
    renderOption,
  }: SelectedOptionCustomRenderingProps) => {
    const projectUsers = projectData?.users || [];
    const organizationUsers = organization?.users || [];
    const user = [...projectUsers, ...organizationUsers].find(
      (user) => user?.displayName === option
    );

    switch (renderOption) {
      case RenderOptionType.UserCard:
        return (
          <IdentityTag
            id={user?.id || ''}
            label={option}
            type={IdentityTagType.User}
          />
        );
      default:
        return option;
    }
  };

  const selectedOptionsRendering = ({
    renderOption,
    tags,
  }: {
    renderOption: RenderOptionType;
    tags: TagProps[];
  }) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {tags.map((tag) => {
        const projectUsers = projectData?.users || [];
        const organizationUsers = organization?.users || [];
        const user = [...projectUsers, ...organizationUsers].find(
          (user) => user?.displayName === tag.name
        );

        switch (renderOption) {
          case RenderOptionType.UserCard:
            return (
              <Box
                onClick={() => tag.onClickTag && tag.onClickTag(tag?.id || '')}
                sx={{ cursor: 'pointer' }}
              >
                <IdentityTag
                  hasBorder
                  id={user?.id || ''}
                  label={tag.name}
                  onDelete={tag.onDelete}
                  type={IdentityTagType.User}
                />
              </Box>
            );
          default:
            return tag.name;
        }
      })}
    </Box>
  );

  return { selectableOptionRendering, selectedOptionsRendering };
};
