import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, AvatarSize } from '@pro4all/shared/ui/avatar';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { IconName } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from './Card.styles';
import { GroupCardProps } from './Card.types';

export const GroupCard = ({ cardIcons, nameSuffix, group }: GroupCardProps) => {
  const { t } = useTranslation();

  const name = group && group.displayName ? group.displayName : t('Unknown');
  const totalUsers = group && group.totalUsers ? group.totalUsers : 0;

  return (
    <Styled.CardWrapper>
      <Avatar name={name} size={AvatarSize.AvatarSizeS} />
      <Styled.DetailsWrapper>
        <Styled.HeadlineWrapper>
          <Styled.NameWrapper>
            <Styled.Name>
              <Text variant="h5">{name}</Text>
            </Styled.Name>
            {nameSuffix && (
              <Styled.NameAppendix>{nameSuffix}</Styled.NameAppendix>
            )}
          </Styled.NameWrapper>
        </Styled.HeadlineWrapper>
        {/* TODO: The amount of direct and nested users that are member of this group is something BE is working on */}
        {/* <Styled.SublineWrapper>{`${totalUsers} ${t(
          'members'
        )}`}</Styled.SublineWrapper> */}
      </Styled.DetailsWrapper>

      {cardIcons && (
        <Styled.IconsWrapper>
          {cardIcons?.map(
            (icon) =>
              !icon.disabled && (
                <Tooltip placement="bottom" title={icon.label}>
                  <IconButton
                    className="CardActions" // Without this className the icon is not clickable when the card is used in the editor @mentions feature (Suggestions).
                    color="default"
                    dataTestId={icon.dataTestId}
                    disableBorder
                    disabled={icon.disabled}
                    iconName={icon.startIcon as IconName}
                    onClick={icon.onClick}
                  />
                </Tooltip>
              )
          )}
        </Styled.IconsWrapper>
      )}
    </Styled.CardWrapper>
  );
};
