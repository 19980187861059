interface FieldValidationProps {
  selectionOptions: SelectOption[];
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setNewOptionName: React.Dispatch<React.SetStateAction<string>>;
}

export type SelectOption = {
  name: string;
};

export const useFieldValidation = () => {
  const fieldValidation = ({
    setFieldTouched,
    selectionOptions,
    setNewOptionName,
  }: FieldValidationProps) => {
    setNewOptionName('');

    // setTimeout because of some weird async behaviour in Formik.
    setTimeout(() => {
      setFieldTouched(`selectionOptions[${selectionOptions.length}].name`);
    }, 100);
  };
  return { fieldValidation };
};
