import dayjs from 'dayjs';

import { NotificationsUser, Organization, User } from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { UserTag } from '@pro4all/shared/ui/identity-card';
import { ColumnProps } from '@pro4all/shared/ui/table';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

const formatDate = (value: string) => {
  if (dayjs(value).isValid()) return dayjs(value).format('lll');
  else return value;
};

export const useColumns = (): ColumnProps<NotificationsUser>[] => [
  {
    headerComponent: (
      <FilterHeader<NotificationsUser, {}>
        defaultWidth={200}
        filterType={FilterHeaderType.Text}
        label="Name" // i18n
        minWidth={100}
        propertyId="displayName"
      />
    ),
    key: 'displayName',
  },
  {
    headerComponent: (
      <FilterHeader<NotificationsUser, {}>
        defaultWidth={176}
        filterType={FilterHeaderType.Text}
        label="Email" // i18n
        minWidth={100}
        propertyId="email"
      />
    ),
    key: 'email',
  },
  {
    headerComponent: (
      <FilterHeader<NotificationsUser, Organization>
        defaultWidth={176}
        filterType={FilterHeaderType.Text}
        label="Organisation" // i18n
        minWidth={100}
        propertyId="organization"
        subPropertyId="name"
      />
    ),
    key: 'organization.name',
    render: ({ organization }) => organization?.name || '',
  },
  {
    headerComponent: (
      <FilterHeader<NotificationsUser, {}>
        defaultWidth={160}
        filterType={FilterHeaderType.Text}
        label="Last updated on" // i18n
        minWidth={100}
        propertyId="lastUpdatedOn"
      />
    ),
    key: 'lastUpdatedOn',
    render: ({ lastUpdatedOn }) =>
      lastUpdatedOn ? formatDate(lastUpdatedOn) : '',
  },
  {
    headerComponent: (
      <FilterHeader<NotificationsUser, User>
        defaultWidth={216}
        filterType={FilterHeaderType.Text}
        label="Last updated by" // i18n
        minWidth={100}
        propertyId="lastUpdatedBy"
        subPropertyId="displayName"
      />
    ),
    key: 'lastUpdatedBy',
    render: ({ lastUpdatedBy }) => {
      const { displayName, email, id, isAdmin, organization } =
        lastUpdatedBy || {};
      const user: User = {
        displayName: displayName || '',
        email,
        id: id || '',
        isAdmin,
        organization,
      };
      return <UserTag user={user} />;
    },
  },
];
