import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApiKey } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SubTabs, Tab } from '@pro4all/shared/ui/general';
import { Column, Main } from '@pro4all/shared/ui/layout';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { useGetSignatureHandler } from './SignatureForm/useGetSignatureHandler';
import { SupportForm } from './SupportForm/SupportForm';
import { ApiKeysTable } from './api-keys';
import * as Styled from './MyProfile.styles';
import { MyProfileTabContent } from './MyProfileTabContent';
import { SignatureForm } from './SignatureForm';

enum MyProfileTabs {
  MyApiKeyManagement = 'my-api-key-management',
  MyProfile = 'my-profile',
  MySignature = 'my-signature',
  MySupport = 'my-support',
}

export const MyProfile: React.FC = () => {
  const { t } = useTranslation();
  const { url } = useRouting();
  const [currentTab, setCurrentTab] = useState(url.replace('/', ''));
  const ffApiKeyManagement = useFeatureFlag('api-key-management');

  const base = Routes.myProfile;

  const { signatureBody, signatureId, signatureIsLoading } =
    useGetSignatureHandler();

  return (
    <Main>
      <Column>
        <SubTabs base={`${base}/`}>
          <Tab
            data-testid="my-profile-tab"
            label={t('My profile')}
            onClick={() => {
              setCurrentTab(MyProfileTabs.MyProfile);
            }}
            value={MyProfileTabs.MyProfile}
          />
          <Tab
            data-testid="my-signature-tab"
            label={t('Signature')}
            onClick={() => {
              setCurrentTab(MyProfileTabs.MySignature);
            }}
            value={MyProfileTabs.MySignature}
          />
          {ffApiKeyManagement && (
            <Tab
              data-testid="my-api-key-management-tab"
              label={t('API key management')}
              onClick={() => {
                setCurrentTab(MyProfileTabs.MyApiKeyManagement);
              }}
              value={MyProfileTabs.MyApiKeyManagement}
            />
          )}
          <Tab
            data-testid="my-support-tab"
            label={t('Support')}
            onClick={() => {
              setCurrentTab(MyProfileTabs.MySupport);
            }}
            value={MyProfileTabs.MySupport}
          />
        </SubTabs>
        {currentTab === MyProfileTabs.MyProfile && <MyProfileTabContent />}
        {currentTab === MyProfileTabs.MySignature && (
          <SignatureForm
            signatureBody={signatureBody}
            signatureId={signatureId as string}
            signatureIsLoading={signatureIsLoading}
          />
        )}
        {currentTab.includes(MyProfileTabs.MyApiKeyManagement) && (
          <OptimisticResponseProvider<ApiKey>>
            <ApiKeysTable />
          </OptimisticResponseProvider>
        )}
        {currentTab === MyProfileTabs.MySupport && (
          <Styled.StyledBox>
            <SupportForm />
          </Styled.StyledBox>
        )}
      </Column>
    </Main>
  );
};
