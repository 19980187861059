import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useUpdateCachedDocuments } from '@pro4all/documents/ui/utils';
import { StampParams, useStampDocumentsMutation } from '@pro4all/graphql';

import type { StamperDocument } from '../store';
import { Action, Shape, State, Status } from '../store';

import { useGetParams } from './useGetParams';

export function useStampMultiple(
  queue: StamperDocument[],
  applyToAllPages: boolean | undefined,
  shapes: Shape[],
  state: State,
  dispatch: React.Dispatch<Action>
) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [stampDocuments] = useStampDocumentsMutation();
  const updateCachedDocuments = useUpdateCachedDocuments();
  const params = useGetParams(state, shapes, applyToAllPages);

  const stampAll = async () => {
    dispatch({ status: Status.Pending, type: 'status' });
    try {
      const documentIds = queue.map((doc) => doc.documentId);

      const { data } = await stampDocuments({
        variables: {
          documentsStamp: queue.map((doc) => ({
            documentId: doc.documentId,
            documentVersionId: doc.id,
            params: params as StampParams,
          })),
        },
      });

      if (data?.stampDocuments?.success) {
        updateCachedDocuments({
          documentIds,
          fieldToUpdate: 'qrCodeState',
          keyField: 'id',
          value: 'progress',
        });
        enqueueSnackbar(t('Documents stamped successfully'));
      } else {
        enqueueSnackbar(
          t(data?.stampDocuments?.errors?.[0] ?? 'Something went wrong')
        );
      }
    } catch (error) {
      enqueueSnackbar(
        t('Something went wrong. Please try again or contact an administrator.')
      );
    }
    dispatch({ index: state.queue.length, type: 'next' });
  };

  return { stampAll };
}
