import { IntegrationType } from '@pro4all/graphql';

import { useIntegrationConfig } from '../config/useIntegrationConfig';

import { ApplicationProps } from './types';

export const useGetApplicationOptions = () => {
  const integrationConfig = useIntegrationConfig();

  const integrationTypes = [
    IntegrationType.Integration12Build,
    IntegrationType.IntegrationSnagstream,
  ];

  const applicationOptions: ApplicationProps[] = integrationTypes.map(
    (integrationType) => ({
      iconName: integrationConfig[integrationType].icon,
      id: integrationType,
      label: integrationConfig[integrationType].label,
    })
  );

  return { applicationOptions };
};
