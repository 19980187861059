import React, { useCallback, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import { getToken } from '@pro4all/authentication/src/utils';
import { QualityControlInstance } from '@pro4all/graphql';
import { SignatureService } from '@pro4all/quality-control/data-access';
import {
  PdfPreview,
  Report,
  ReportStyling,
} from '@pro4all/quality-control/ui/report';
import { groupInstancesByDrawing } from '@pro4all/quality-control/utils';
import { useGetReportImageUrls } from '@pro4all/shared/qc-report-assets';
import {
  InstanceWithSignature,
  ReportPreviewProps,
} from '@pro4all/shared/types';

import { DrawingReportMaps } from './utils/DrawingReportMaps';
import { getSignatureIds } from './utils/getSignaturesIds';
import { ReportMiniMaps } from './utils/ReportMiniMaps';
import { useReportRenderer } from './utils/useReportRenderer';
import { useSetReportConfigurationReportOptions } from './utils/useSetReportConfigurationReportOptions';
import { useUpdateMaps } from './utils/useUpdateMaps';
import { ReportPreviewContainer } from './ReportingMainStyles';
import { useReportOptionsContext } from './ReportOptionsProvider';

export const ReportPreview = ({
  currentUser,
  date,
  instances,
  instancesGroupedByDrawing,
  mapInstances,
  members,
  project,
  reportConfiguration,
  reportConfigurationReportOptions,
  reportConfigurationDummyTemplates,
  tasks,
  photoBlobsReportPreview,
  miniMapsFlag,
  setMiniMapsFlag,
  signaturesFlag,
  setSignaturesFlag,
  signatures,
  setSignatures,
}: ReportPreviewProps) => {
  const { t } = useTranslation();
  const id = 'pdf-report';
  const token = getToken();

  const groupedInstances =
    tasks && tasks.length > 0
      ? groupInstancesByDrawing(
          tasks
            .map((task) => task.linkedSnagInstances)
            .flat()
            .filter(
              (instance): instance is QualityControlInstance =>
                instance !== null && instance !== undefined
            )
        )
      : instancesGroupedByDrawing;

  const {
    state: { miniMaps, reportOptions, reportDrawings },
  } = useReportOptionsContext() || {};

  useSetReportConfigurationReportOptions({
    reportConfiguration,
    reportConfigurationReportOptions,
  });

  const instancesWithSignatures = getSignatureIds(instances);

  const getSignatures = useCallback(
    async (instancesWithSignatures: InstanceWithSignature[]) => {
      const signatureUrls = await Promise.all(
        instancesWithSignatures.map(async (instance: InstanceWithSignature) => {
          const signature = await SignatureService.getSignature({
            instanceId: instance.instanceId,
            signatureId: instance.signatureId,
          });
          return {
            instanceId: instance.instanceId,
            itemId: instance.itemId,
            signatureId: instance.signatureId,
            signatureUrl: signature,
          };
        })
      );

      return signatureUrls;
    },
    []
  );

  useEffect(() => {
    if (instances.length > 0 && !signaturesFlag) {
      const fetchSignatures = async () => {
        const urls =
          instancesWithSignatures.length > 0
            ? await getSignatures(instancesWithSignatures)
            : [];
        setSignatures(urls);
      };
      fetchSignatures();
      setSignaturesFlag(true);
    }
  }, [
    getSignatures,
    instances,
    instancesWithSignatures,
    setSignatures,
    setSignaturesFlag,
    signaturesFlag,
  ]);

  useUpdateMaps({
    instances,
    instancesGroupedByDrawing,
    mapInstances,
    tasks,
  });

  const { background, backgroundFrontPage, companyLogo } =
    useGetReportImageUrls({
      reportOptions,
      token,
    });

  const component = (
    <Report
      companyLogo={companyLogo}
      currentUser={currentUser}
      date={date}
      downloadPdfContext={false}
      instancesGroupedByDrawing={instancesGroupedByDrawing}
      members={members}
      miniMaps={miniMaps}
      photoBlobs={photoBlobsReportPreview}
      project={project}
      reportConfigurationDummyTemplates={reportConfigurationDummyTemplates}
      reportDrawings={reportDrawings}
      reportOptions={reportOptions}
      signatures={signatures}
      tasks={tasks}
    />
  );

  useEffect(() => {
    if (!miniMapsFlag && miniMaps !== undefined) {
      setMiniMapsFlag(true);
    }
  }, [miniMaps, miniMapsFlag, setMiniMapsFlag]);

  const photosLoaded = photoBlobsReportPreview !== undefined;
  const mediaLoaded = photosLoaded && signaturesFlag;

  const html = ReactDOMServer.renderToString(component);

  useReportRenderer({
    html,
    id,
    mediaLoaded,
  });

  return (
    <ReportPreviewContainer id={id}>
      <ReportStyling
        background={background}
        backgroundFrontPage={backgroundFrontPage}
        page={t('Page')}
        reportOptions={reportOptions}
      />

      <div
        id="temp-maps-container"
        style={{ height: '1px', visibility: 'hidden', width: '1px' }}
      >
        <ReportMiniMaps instances={mapInstances} />
      </div>

      <div
        id="temp-drawings-container"
        style={{ height: '1px', visibility: 'hidden', width: '1px' }}
      >
        <DrawingReportMaps instancesGroupedByDrawing={groupedInstances ?? []} />
      </div>

      <PdfPreview id={`${id}-preview`} />
    </ReportPreviewContainer>
  );
};
