// import { getParentFolders } from '../helpers/getParentFolders';
import { unflattenFolders } from '../helpers/unflattenFolders';
import { State } from '../types';

export const folderEmptyAfterDocumentDeleteAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => {
  const folderGettingEmpty = state.folders.find(
    (folder) => folder.id === payload
  );

  const folders = state.folders.filter((folder) => folder.id !== payload);

  const foldersUpdated = [
    { ...folderGettingEmpty, hasDocuments: false }, // Set the prop `hasDocuments` to false for the folder that gets empty.
    ...folders,
  ];

  return {
    ...state,
    folderTree: unflattenFolders(foldersUpdated),
    folders: foldersUpdated,
    foldersPrevious: state.folders,
  };
};
