import React, { createContext, useContext, useMemo, useState } from 'react';

import { Document } from '@pro4all/graphql';

import { ShareBuild12Modal } from './ShareBuild12Modal';

export interface ShareBuild12ContextValues {
  existingVersionIds: string[];
  selectedConnection?: string;
  selectedDocuments: Document[];
  setExistingVersionIds: (value: string[]) => void;
  setSelectedConnection?: (connectionId: string) => void;
  setSelectedDocuments?: (value: Document[]) => void;
  setValidationScreen: (value: boolean) => void;
  validationScreen: boolean;
}

const ShareBuild12Context = createContext<ShareBuild12ContextValues>({
  existingVersionIds: [],
  selectedConnection: '',
  selectedDocuments: [],
  setExistingVersionIds: function (): void {
    throw new Error('Function not implemented.');
  },
  setValidationScreen: function (): void {
    throw new Error('Function not implemented.');
  },
  validationScreen: false,
});

export const useShareBuild12Context = () =>
  useContext<ShareBuild12ContextValues>(ShareBuild12Context);

export const ShareBuild12ContextProvider: React.FC = ({ children }) => {
  const [selectedConnection, setSelectedConnection] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [existingVersionIds, setExistingVersionIds] = useState<string[]>([]);
  const [validationScreen, setValidationScreen] = useState(false);

  const value = useMemo(
    () => ({
      existingVersionIds,
      selectedConnection,
      selectedDocuments,
      setExistingVersionIds,
      setSelectedConnection,
      setSelectedDocuments,
      setValidationScreen,
      validationScreen,
    }),
    [
      existingVersionIds,
      selectedConnection,
      selectedDocuments,
      setExistingVersionIds,
      setSelectedConnection,
      setSelectedDocuments,
      setValidationScreen,
      validationScreen,
    ]
  );

  return (
    <ShareBuild12Context.Provider value={value}>
      {children}
      <ShareBuild12Modal />
    </ShareBuild12Context.Provider>
  );
};
