import {
  IntegrationType,
  useProjectsBuild12Query,
  useProjectsSnagstreamQuery,
} from '@pro4all/graphql';
import { sortBy } from '@pro4all/shared/utils';

import { useGetIntegrationTypeData } from './useGetIntegrationTypeData';

export const useGetProjectOptions = (integrationId: string) => {
  const { integrationType } = useGetIntegrationTypeData();

  const { data: dataSnagstream, loading: loadingSnagstream } =
    useProjectsSnagstreamQuery({
      fetchPolicy: 'cache-and-network',
      skip: integrationType !== IntegrationType.IntegrationSnagstream,
      variables: { integrationId },
    });

  const { data: dataBuild12, loading: loadingBuild12 } =
    useProjectsBuild12Query({
      fetchPolicy: 'cache-and-network',
      skip: integrationType !== IntegrationType.Integration12Build,
      variables: { integrationId },
    });

  const projectOptions =
    integrationType === IntegrationType.Integration12Build
      ? dataBuild12
        ? dataBuild12?.projectsBuild12.map((project) => ({
            id: project.id,
            label: project.name,
          }))
        : []
      : integrationType === IntegrationType.IntegrationSnagstream
      ? dataSnagstream
        ? dataSnagstream?.projectsSnagstream.map((project) => ({
            id: project.id,
            label: project.name,
          }))
        : []
      : [];

  const loadingProjects =
    integrationType === IntegrationType.Integration12Build
      ? loadingBuild12 && !dataBuild12
      : integrationType === IntegrationType.IntegrationSnagstream
      ? loadingSnagstream && !dataSnagstream
      : false;

  return {
    loadingProjects,
    projectOptions: [...projectOptions.sort(sortBy({ key: 'label' }))],
  };
};
