import { useTranslation } from 'react-i18next';

import { QcPermissionCategory } from '@pro4all/graphql';
import { useOpenCdeContext } from '@pro4all/opencde/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps, CdeActions, Position } from '@pro4all/shared/types';

import { useApproveActions } from './document-actions/approve-version/useApproveActions';
import { useCopyAction } from './document-actions/copy/useCopyAction';
import { useCreateDocumentTaskAction } from './document-actions/create-document-task/useCreateDocumentTaskAction';
import { useCreateMessageAction } from './document-actions/create-message/useCreateMessageAction';
import { useCutAction } from './document-actions/cut/useCutAction';
import { useDeleteAction } from './document-actions/delete/useDeleteAction';
import { useDeleteVersionAction } from './document-actions/delete-version/useDeleteVersionAction';
import { useDocumentToolsAction } from './document-actions/document-tools/useDocumentToolsAction';
import { useCollectionFolderActions } from './document-actions/documents-collection/useCollectionFolderActions';
import { useDownloadAction } from './document-actions/download/useDownloadAction';
import { useEditFileAction } from './document-actions/edit-file/useEditFileAction';
import { useEditFileClientAction } from './document-actions/edit-file/useEditFileClientAction';
import { useEditPopupAction } from './document-actions/edit-file/useEditPopupAction';
import { useEditMetaDataNewAction } from './document-actions/edit-meta-data/useEditMetaDataNewAction';
import { useFinalizeActions } from './document-actions/finalize/useFinalizeActions';
import { useGenerateKeywordsAction } from './document-actions/generate-keywords/useGenerateKeywordsAction';
import { useOpenCdeAction } from './document-actions/open-cde/useOpenCdeAction';
import { useOpenOfficeAction } from './document-actions/open-office/useOpenOfficeAction';
import { usePublishAction } from './document-actions/publish/usePublishAction';
import { usePublishClientAction } from './document-actions/publish/usePublishClientAction';
import { useRequestUnlockAction } from './document-actions/request-unlock/useRequestUnlockAction';
import { useShareGroupedAction } from './document-actions/share/useShareGroupedAction';
import { useStampAction } from './document-actions/stamp/useStampAction';
import { use3DAction } from './document-actions/threeD/use3DAction';
import { useToFolderAction } from './document-actions/to-folder/useToFolderAction';
import { useUnlockAction } from './document-actions/unlock/useUnlockAction';
import { useUploadVersionAction } from './document-actions/upload-version/useUploadVersionAction';
import { useVisualContextAction } from './document-actions/visual-context/useVisualContextAction';
import { useVisualContextsAction } from './document-actions/visual-context/useVisualContextsAction';
import { DocumentActionsProps } from './useDocumentActionsProps';
import { useDocumentActionsProps } from './useDocumentActionsProps';

export function useDocumentActions({
  contextMenuRow,
  contextMenuRowVersion,
  folderId,
  isSpecialFolderProp,
  openFileInput,
  position = Position.Actionbar,
  setLoading,
  sidebarRow,
  sidebarRowVersion,
}: DocumentActionsProps) {
  const { t } = useTranslation();
  const { goTo } = useRouting();

  const {
    allDocumentsExpected,
    allDocumentsFinalized,
    allDocumentsUnfinalized,
    editableDocuments,
    editableDocumentsWithFinalized,
    editableVersions,
    expectedFileCount,
    folderIdSelected,
    hasFeatureFlagClient,
    hasFolderPermission,
    isAdmin,
    isLocked,
    isLockedAll,
    isLockedUser,
    isSpecialFolder,
    isVersion,
    notExpectedDocuments,
    organizationId,
    ownedByUserDocuments,
    projectId,
    searchContext,
    selected,
    selection,
    selectionVersion,
    setActionLoading,
    trackingPosition,
    uncheckAllRows,
    userId,
    versionIds,
    versionIsNotCurrentVersion,
  } = useDocumentActionsProps({
    contextMenuRow,
    contextMenuRowVersion,
    folderId,
    isSpecialFolderProp,
    position,
    setLoading,
    sidebarRow,
    sidebarRowVersion,
  });

  const createMessageAction = useCreateMessageAction({
    allDocumentsExpected,
    selection,
    selectionVersion,
    setLoading: setActionLoading,
  });

  const copyAction = useCopyAction({
    selection,
  });

  const cutAction = useCutAction({
    hasFolderPermission,
    ownedByUserDocuments,
    selection,
  });

  const downloadAction = useDownloadAction({
    allDocumentsExpected,
    isSpecialFolder,
    selection,
    selectionVersion,
  });

  const editFileAction = useEditFileAction({
    editableDocuments,
    isLocked: isLockedAll,
    isSpecialFolder,
    isVersion,
    position,
    selection,
    uncheckAllRows,
  });

  const editFileClientAction = useEditFileClientAction({
    editableDocuments,
    isLocked: isLockedAll,
    isSpecialFolder,
    isVersion,
    position,
    selection,
    uncheckAllRows,
  });

  const unlockAction = useUnlockAction({
    editableDocuments,
    isAdmin,
    isLocked,
    isLockedUser,
    isSpecialFolder,
    isVersion,
    position,
    selection,
    uncheckAllRows,
  });

  const requestUnlockAction = useRequestUnlockAction({
    editableDocuments,
    isAdmin,
    isLocked,
    isLockedUser,
    isSpecialFolder,
    isVersion,
    position,
    selection,
    uncheckAllRows,
  });

  const publishAction = usePublishAction({
    editableDocuments,
    isLocked,
    isLockedUser,
    isSpecialFolder,
    isVersion,
    position,
    selection,
  });

  const publishClientAction = usePublishClientAction({
    editableDocuments,
    isLocked,
    isLockedUser,
    isSpecialFolder,
    isVersion,
    position,
    selection,
  });

  const currentPublish = hasFeatureFlagClient
    ? publishClientAction
    : publishAction;

  const editMetaDataNewAction = useEditMetaDataNewAction({
    editableDocuments,
    isSpecialFolder,
    isVersion,
  });

  const editPopupAction = useEditPopupAction({
    editableDocuments,
    isSpecialFolder,
    isVersion,
    subItems: [editFileClientAction, editMetaDataNewAction],
  });

  const currentEditFile = hasFeatureFlagClient
    ? editPopupAction
    : editFileAction;

  const { documentCreateAll, documentCreateOwn } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
  });

  const createDocumentTaskAction = useCreateDocumentTaskAction({
    documents: selection,
  });
  const canCreateDocumentTask =
    projectId && (documentCreateAll || documentCreateOwn);

  const { addDocumentToCollection, removeDocumentsFromCollection } =
    useCollectionFolderActions({
      allDocumentsExpected,
      isVersion,
      selection: notExpectedDocuments,
    });

  const stampAction = useStampAction({
    editableDocuments,
    editableVersions,
    isLocked,
  });

  const openOfficeAction = useOpenOfficeAction({
    selection,
    uncheckAllRows,
  });

  const deleteAction = useDeleteAction({
    allDocumentsFinalized,
    hasFolderPermission,
    isLocked,
    isSpecialFolder,
    isVersion,
    selection,
    userId,
  });

  const generateKeywordsAction = useGenerateKeywordsAction({
    allDocumentsUnfinalized,
    projectId,
    selection,
  });

  const deleteVersionAction = useDeleteVersionAction({
    allDocumentsFinalized,
    hasFolderPermission,
    isLocked,
    selectionVersion,
    userId,
  });

  const shareGroupedAction = useShareGroupedAction({
    allDocumentsExpected,
    editableDocumentsWithFinalized,
    expectedFileCount,
    isVersion,
    projectId,
    selection,
    userId,
  });

  const { threeDAction } = use3DAction({
    allDocumentsExpected,
    selected,
    versionIds,
  });

  const documentToolsAction = useDocumentToolsAction({
    allDocumentsExpected,
    sidebarRow,
  });

  const openCdeAction = useOpenCdeAction({
    allDocumentsUnfinalized,
    editableDocuments,
    isLocked,
    selection,
    uncheckAllRows,
  });

  const visualContextAction = useVisualContextAction({ selection });

  const visualContextsAction = useVisualContextsAction({ selection });

  const toFolderAction = useToFolderAction({ selection, sidebarRow });

  const { finalizeAction, unfinalizeAction } = useFinalizeActions({
    allDocumentsExpected,
    allDocumentsFinalized,
    allDocumentsUnfinalized,
    folderId: folderIdSelected,
    hasFolderPermission,
    isLocked,
    organizationId,
    position,
    projectId,
    selection,
    selectionVersion,
    trackingPosition,
    uncheckAllRows,
    userId,
    versionIsNotCurrentVersion,
  });

  const uploadVersionAction = useUploadVersionAction({
    allDocumentsFinalized,
    hasFolderPermission,
    isLocked,
    isVersion,
    openFileInput,
    position,
  });

  const addToSearch: ActionProps = {
    ariaLabel: t('Open in search'),
    dataTestId: 'add-to-search',
    disabled: false,
    key: 'add-documents-to-search',
    label: t('Open in search'),
    onClick: () => {
      searchContext.setContextQuery(
        selection.map((item) => item.versionId || '').filter(Boolean)
      );
    },
    startIcon: 'openSearch',
  };

  const {
    approveVersionAction,
    rejectVersionAction,
    unapproveVersionAction,
    unrejectVersionAction,
  } = useApproveActions({
    allDocumentsExpected,
    allDocumentsFinalized,
    folderId: folderIdSelected,
    hasFolderPermission,
    isLocked,
    organizationId,
    position,
    projectId,
    selection,
    selectionVersion,
    trackingPosition,
    uncheckAllRows,
    userId,
    versionIds,
  });

  const smartFolderActions = [
    shareGroupedAction,
    generateKeywordsAction,
    createMessageAction,
    cutAction,
    copyAction,
    downloadAction,
    canCreateDocumentTask && createDocumentTaskAction,
    addDocumentToCollection,
    removeDocumentsFromCollection,
    threeDAction,
    toFolderAction,
    addToSearch,
  ].filter(Boolean);
  const collectionActions = [
    shareGroupedAction,
    generateKeywordsAction,
    createMessageAction,
    cutAction,
    copyAction,
    downloadAction,
    canCreateDocumentTask && createDocumentTaskAction,
    removeDocumentsFromCollection,
    threeDAction,
    toFolderAction,
    addToSearch,
  ].filter(Boolean);

  const searchActions = [
    generateKeywordsAction,
    createMessageAction,
    cutAction,
    copyAction,
    downloadAction,
    addDocumentToCollection,
    toFolderAction,
  ].filter(Boolean);

  const altActions =
    selection.length || selectionVersion.length
      ? [
          shareGroupedAction,
          generateKeywordsAction,
          selection.length === 1 && uploadVersionAction,
          cutAction,
          copyAction,
          downloadAction,
          currentPublish,
          !hasFeatureFlagClient && editMetaDataNewAction,
          unlockAction,
          requestUnlockAction,
          currentEditFile,
          addDocumentToCollection,
          removeDocumentsFromCollection,
          canCreateDocumentTask && createDocumentTaskAction,
          threeDAction,
          visualContextsAction,
          stampAction,
          openOfficeAction,
          openCdeAction,
          approveVersionAction,
          unapproveVersionAction,
          rejectVersionAction,
          unrejectVersionAction,
          finalizeAction,
          unfinalizeAction,
          deleteAction,
        ].filter(Boolean)
      : [];

  const sidebarActions: ActionProps[] = [
    shareGroupedAction,
    uploadVersionAction,
    cutAction,
    copyAction,
    createMessageAction,
    downloadAction,
    currentPublish,
    !hasFeatureFlagClient && editMetaDataNewAction,
    unlockAction,
    requestUnlockAction,
    currentEditFile,
    addDocumentToCollection,
    removeDocumentsFromCollection,
    canCreateDocumentTask && createDocumentTaskAction,
    documentToolsAction,
    threeDAction,
    visualContextAction,
    stampAction,
    openOfficeAction,
    openCdeAction,
    approveVersionAction,
    unapproveVersionAction,
    rejectVersionAction,
    unrejectVersionAction,
    finalizeAction,
    unfinalizeAction,
    deleteAction,
  ].filter(Boolean);

  // Open CDE metadata save to send to the external client
  const { openCdeRequest } = useOpenCdeContext();

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t('Expected document'),
      dataTestId: 'new-expected-document',
      disabled: isSpecialFolder || !hasFolderPermission('CreateContent'),
      key: 'new-expected-document',
      label: t('Expected document'),
      onClick: () => goTo({ searchParams: { action: 'createExpectedDoc' } }),
      startIcon: 'document',
    },
    {
      ariaLabel: t('Upload document'),
      dataTestId: 'upload-document',
      disabled:
        isSpecialFolder ||
        !hasFolderPermission('CreateContent') ||
        openCdeRequest?.cdeSelect === CdeActions.CdeUpload,
      key: 'upload-document',
      label: t('Upload document'),
      onClick: openFileInput,
      startIcon: 'upload',
    },
    {
      ariaLabel: t(`Upload document from ${openCdeRequest?.cdeApplication}`),
      dataTestId: 'upload-document-cde',
      disabled:
        isSpecialFolder ||
        !hasFolderPermission('CreateContent') ||
        openCdeRequest?.cdeSelect !== CdeActions.CdeUpload,
      key: 'upload-document-cde',
      label: t(`Upload document from ${openCdeRequest?.cdeApplication}`),
      onClick: () => {
        if (openCdeRequest?.cdeSelect === CdeActions.CdeUpload) {
          // Generate a file using the doc names in the state
          const files = openCdeRequest?.state?.map(
            (documentName) => new File([], documentName.file_name ?? '')
          );
          goTo({
            searchParams: {
              action: 'uploadDocuments',
              fullscreen: true,
            },
            state: {
              filesToUpload: files,
            },
          });
        }
      },
      startIcon: 'upload',
    },
  ];

  const versionActions: ActionProps[] = [...altActions, deleteVersionAction];

  return {
    altActions: [...altActions, createMessageAction, addToSearch],
    collectionActions,
    mainActions,
    searchActions,
    sidebarActions,
    smartFolderActions,
    versionActions,
  };
}
