import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { SelectOption } from './CustomSelection';

export const useCustomSelectionValidationSchema = () => {
  const { t } = useTranslation();
  const characterLimit = 9999;

  return {
    selectionOptions: () =>
      Yup.array()
        .of(
          Yup.object()
            .shape({
              name: Yup.string()
                .required(t('This field is required'))
                .max(characterLimit),
            })
            .test('cannotBeEmpty', '', function (option) {
              const { options } = this;
              const currentIndex = (options as { index: number }).index;

              if (option?.name) {
                return true;
              }
              return Promise.resolve(
                this.createError({
                  message: t("'{{name}}' is required", { name: t('Name') }),
                  path: `selectionOptions[${currentIndex}].name`,
                })
              );
            })
            .test('characterLimit', '', function (option) {
              const { options } = this;
              const currentIndex = (options as { index: number }).index;

              const exceedsCharLimit = option.name?.length > characterLimit;

              if (!exceedsCharLimit) {
                return true;
              }
              return Promise.resolve(
                this.createError({
                  message: t('Cannot exceed {{number}} characters', {
                    number: `${characterLimit}`,
                  }),
                  path: `selectionOptions[${currentIndex}].name`,
                })
              );
            })
            .test('uniqueName', '', function (option) {
              const { parent, options } = this;
              const currentIndex = (options as { index: number }).index;

              const duplicateNames = parent.filter(
                (_option: SelectOption) =>
                  _option.name === option.name && Boolean(_option.name)
              );
              const isUnique = duplicateNames.length <= 1;

              if (isUnique) {
                return true;
              }
              return Promise.resolve(
                this.createError({
                  message: t('Name is already used', { name: t('Name') }),
                  path: `selectionOptions[${currentIndex}].name`,
                })
              );
            })
        )
        .when(['type'], {
          is: (type) => type?.id === 'Selection',
          path: `newOptionError`,
          then: Yup.array().required(),
        })

        // This test sets the error messages for the name input
        .test('uniqueNames', '', function (options) {
          const { options: opts } = this;
          const currentIndex = (opts as { index: number }).index;

          const mapper = (option: SelectOption) => option.name;
          const set = [...new Set(options?.map(mapper))];

          const hasUniqueNames = options?.length === set.length || !options;
          if (hasUniqueNames) {
            return true;
          }
          return Promise.resolve(
            this.createError({
              message: t("'{{name}}' is already used", { name: t('Name') }),
              path: `selectionOptions[${currentIndex}].name`,
            })
          );
        }),
  };
};
