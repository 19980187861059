import React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';

type LayoutLeftMainProps = {
  leftContent: React.ReactNode;
  mainContent: React.ReactNode;
};

export const LayoutLeftMain = ({
  leftContent,
  mainContent,
}: LayoutLeftMainProps) => (
  <Box sx={{ display: 'flex' }}>
    <Box sx={{ height: '100%', width: '400px' }}>{leftContent}</Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {mainContent}
    </Box>
  </Box>
);
