import React, { useCallback, useContext, useReducer } from 'react';

import { usePersistentState } from '@pro4all/shared/hooks';

import { genericReducer } from './genericReducer';
import { ActionType, State } from './types';

type ContextType = {
  state: State;
  togglePermissionsSaved: () => void;
  toggleReloadProfilePictures: () => void;
  toggleShowFinalPermissions: () => void;
  toggleShowHighestQCPermission: () => void;
  toggleShowQCGroupPermissions: () => void;
};

const GenericContext = React.createContext(null);

export function useGenericContext() {
  return useContext<ContextType>(GenericContext);
}

export function GenericProvider({ children }: { children: JSX.Element }) {
  const [showHighestQCPermissionPersist] = usePersistentState(
    'show-highest-QC-permission',
    false
  );
  const [showQCGroupPermissionPersist] = usePersistentState(
    'show-QC-group-permission',
    true
  );

  const [state, dispatch] = useReducer(genericReducer, {
    permissionsSaved: false,
    reloadProfilePictures: false,
    showFinalPermissions: false,
    showHighestQCPermission: showHighestQCPermissionPersist,
    showQCGroupPermissions: showQCGroupPermissionPersist,
  });

  // Define all actions
  const togglePermissionsSaved = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_PERMISSIONS_SAVED,
    });
  }, []);

  const toggleReloadProfilePictures = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_RELOAD_PROFILE_PICTURES,
    });
  }, []);

  const toggleShowFinalPermissions = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_SHOW_FINAL_PERMISSIONS,
    });
  }, []);

  const toggleShowQCGroupPermissions = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_SHOW_QC_GROUP_PERMISSIONS,
    });
  }, []);
  const toggleShowHighestQCPermission = useCallback(() => {
    dispatch({
      type: ActionType.TOGGLE_SHOW_HIGHEST_QC_PERMISSION,
    });
  }, []);
  return (
    <GenericContext.Provider
      value={{
        state,
        togglePermissionsSaved,
        toggleReloadProfilePictures,
        toggleShowFinalPermissions,
        toggleShowHighestQCPermission,
        toggleShowQCGroupPermissions,
      }}
    >
      {children}
    </GenericContext.Provider>
  );
}
