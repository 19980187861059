import { useCallback, useEffect } from 'react';

import { QualityControlInstance } from '@pro4all/graphql';
import { groupInstancesByDrawing } from '@pro4all/quality-control/utils';
import { GroupedInstances, UpdateMapsProps } from '@pro4all/shared/types';

import { useReportOptionsContext } from '../ReportOptionsProvider';

import { getReportDrawings } from './getReportDrawings';
import { getReportMiniMaps } from './getReportMiniMaps';

export const useUpdateMaps = ({
  instances,
  mapInstances,
  tasks,
  instancesGroupedByDrawing,
}: UpdateMapsProps) => {
  const {
    state: { miniMaps, reportDrawings },
    setMiniMaps,
    setReportDrawings,
  } = useReportOptionsContext() || {};

  const updateMaps = useCallback(
    async (
      mapInstances: QualityControlInstance[],
      groupedInstancesLocal: GroupedInstances[]
    ) => {
      const mapInstanceIds = mapInstances
        .filter((instance) => instance.x && instance.y)
        .map((instance) => instance.id);

      const linkedSnags: boolean = instances.some(
        (instance) =>
          instance.linkedSnagInstances &&
          instance.linkedSnagInstances.length > 0
      );

      if (!mapInstanceIds.length && !linkedSnags) {
        setMiniMaps({});
        return;
      }

      if (!miniMaps) {
        const updatedMiniMaps = await getReportMiniMaps(mapInstanceIds);
        if (!updatedMiniMaps || !Object.values(updatedMiniMaps).length) {
          setMiniMaps({});
          return;
        }

        const updatedValue = Object.values(updatedMiniMaps);

        const equality =
          updatedValue.join() === Object.values(miniMaps || {}).join();

        if (!equality || !miniMaps) {
          updatedMiniMaps && setMiniMaps(updatedMiniMaps);
        }
      }

      const validGroupedInstances = groupedInstancesLocal?.filter((group) =>
        Boolean(
          group.visualContextId !== '00000000-0000-0000-0000-000000000000'
        )
      );

      const drawingTempsIds = validGroupedInstances?.map(
        (group) => `${group.visualContextId}${group.page}`
      );

      if (!drawingTempsIds?.length && !linkedSnags) {
        setReportDrawings({});
        return;
      }

      if (!reportDrawings) {
        const updatedReportDrawings = await getReportDrawings(drawingTempsIds);

        if (
          !updatedReportDrawings ||
          !Object.values(updatedReportDrawings).length
        ) {
          setReportDrawings({});
          return;
        }

        const updatedValues = Object.values(updatedReportDrawings);

        const equality =
          updatedValues.join() === Object.values(reportDrawings || {}).join();

        if (!equality || !reportDrawings) {
          updatedReportDrawings && setReportDrawings(updatedReportDrawings);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (mapInstances.length > 0) {
      const miniMapsIntervalId = setInterval(async () => {
        await updateMaps(
          mapInstances,
          tasks && tasks.length > 0
            ? groupInstancesByDrawing(
                tasks
                  .map((task) => task.linkedSnagInstances)
                  .flat()
                  .filter(
                    (instance): instance is QualityControlInstance =>
                      instance !== null && instance !== undefined
                  )
              )
            : instancesGroupedByDrawing
        );
      }, 5000);

      return () => clearInterval(miniMapsIntervalId);
    }
  }, [instancesGroupedByDrawing, mapInstances, tasks, updateMaps]);
};
