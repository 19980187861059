import { ScopeType } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useAi } from '@pro4all/shared/hooks';
import { useContextScopedOrganizationId } from '@pro4all/shared/identity';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { AvatarProfile } from '@pro4all/shared/ui/file-upload';
import { LayoutLeftMain } from '@pro4all/shared/ui/layout';

import { OrganizationForm } from './OrganizationForm';
import { useInitialValues } from './useInitialValues';

export const OrganizationTabContent = () => {
  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const { aiEnabledOrganization, aiEnabledOverall } = useAi(
    ScopeType.Organization
  );

  const { userOrganizationName } = useOrganizationContext();
  const name = userOrganizationName;

  const initialValues = useInitialValues({
    aiEnabledOrganization,
    aiEnabledOverall,
    id: organizationId,
    name,
  });

  const leftContent = <OrganizationForm initialValues={initialValues} />;
  const mainContent = (
    <AvatarProfile
      avatarFallbacklabel={initialValues.name}
      id={organizationId}
      profilePictureEntity={ProfilePictureEntity.Organization}
    />
  );

  return <LayoutLeftMain leftContent={leftContent} mainContent={mainContent} />;
};
