import * as React from 'react';

import { Box } from '@pro4all/shared/mui-wrappers';
import { CircularProgress } from '@pro4all/shared/mui-wrappers';
import { CircularProgressProps } from '@pro4all/shared/mui-wrappers';
import { Typography } from '@pro4all/shared/mui-wrappers';

function CircularWithValueLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'inline-flex', position: 'relative' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          alignItems: 'center',
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <Typography
          component="div"
          sx={{ color: 'text.secondary' }}
          variant="caption"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export const CircularProgressWithLabel = ({
  everyThingLoaded,
  onLoadingComplete,
}: {
  everyThingLoaded: boolean;
  onLoadingComplete: () => void;
}) => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!everyThingLoaded) {
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          const increment = Math.random() * 20;
          return Math.min(prevProgress + increment, 99);
        });
      }, 800);
    } else {
      // When everything is loaded, smoothly increase to 100%
      timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer);
            // Call the callback after reaching 100%
            setTimeout(onLoadingComplete, 1000);
            return 100;
          }
          return Math.min(prevProgress + 5, 100);
        });
      }, 100);
    }

    return () => {
      clearInterval(timer);
    };
  }, [everyThingLoaded, onLoadingComplete]);

  return <CircularWithValueLabel value={progress} />;
};
