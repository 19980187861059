import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScopeType, Template, TemplateType } from '@pro4all/graphql';
import { Box, DialogContent } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { Dialog } from '@pro4all/shared/ui/dialog';
import { useTableCheck } from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { useDeleteTemplates } from '../hooks/useDeleteTemplates';

export const useDeleteTemplatesModal = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const deleteTemplates = useDeleteTemplates();

  const { templateType } = useMetaDataContext();
  const { checkedRows } = useTableCheck<Template>();
  const {
    params: { projectId },
    goTo,
  } = useRouting();

  const affectedAmount = useMemo(
    () =>
      checkedRows.reduce((acc, template) => acc + template.instanceCount, 0),
    [checkedRows]
  );

  const typeMapping: Record<TemplateType, string> = {
    [TemplateType.Document]: 'folders',
    [TemplateType.Form]: 'forms',
    [TemplateType.Snag]: 'snags',
    [TemplateType.Field]: '', // Not needed for now
    [TemplateType.Section]: '', // Not needed for now
    [TemplateType.Tbq]: '', // Not needed for now
  };

  const entityType = typeMapping[templateType];
  const hasInstances = affectedAmount > 0;

  const linkedElementsToOrganization = checkedRows?.filter(
    (item) => item.scope.type === ScopeType.Organization
  );
  const isOrganizationLevel = Boolean(!projectId);
  const asProjectLevelSelected = checkedRows?.filter(
    (item) => item.scope.type === ScopeType.Project
  )?.length;

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = async () => {
    await deleteTemplates();
    setShowModal(false);
  };

  const goToOrganization = () => goTo('metaDataDocumentsTemplates');
  const additionalActions = !isOrganizationLevel &&
    asProjectLevelSelected > 0 &&
    linkedElementsToOrganization.length > 0 && (
      <Box>
        <Button color="inherit" onClick={goToOrganization} variant="outlined">
          {t('Go to organization templates')}
        </Button>
      </Box>
    );

  const deleteTemplatesModal = (
    <Dialog
      additionalActions={additionalActions}
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      buttonColor={customColors.orangeAccent}
      onClose={handleClose}
      onSubmit={
        asProjectLevelSelected || isOrganizationLevel
          ? handleConfirmDelete
          : goToOrganization
      }
      open={showModal}
      submitLabel={
        asProjectLevelSelected || isOrganizationLevel
          ? t('Delete template')
          : t('Go to organization templates')
      }
      title={t('Delete template')}
    >
      <DialogContent sx={{ padding: 0 }}>
        <p>
          {checkedRows.length > 1
            ? t('Are you sure you want to delete these {{count}} templates?', {
                count: checkedRows.length,
              })
            : t("Are you sure you want to delete the template '{{name}}'?", {
                name: checkedRows[0]?.name,
              })}
        </p>
        {hasInstances && (
          <p>
            {t(
              'If you continue, it will reset the metadata to default of {{affectedAmount}} {{entityType}}.',
              {
                affectedAmount,
                entityType,
              }
            )}
          </p>
        )}
        {linkedElementsToOrganization.length > 0 && !isOrganizationLevel && (
          <p>
            {linkedElementsToOrganization.length === 1
              ? t(
                  "The template '{{name}}' is linked from an organization and can only be deleted from within the organization.",
                  {
                    name: linkedElementsToOrganization[0]?.name,
                  }
                )
              : t(
                  "The templates '{{name}}' and {{count}} more are linked from an organization and can only be deleted from within the organization.",
                  {
                    count: linkedElementsToOrganization.length - 1,
                    name: linkedElementsToOrganization[0]?.name,
                  }
                )}
          </p>
        )}
        {asProjectLevelSelected > 0 &&
          linkedElementsToOrganization.length > 0 && (
            <p>{t('Project-linked templates will also be deleted.')}</p>
          )}
      </DialogContent>
    </Dialog>
  );

  return { deleteTemplatesModal, setShowModal };
};
