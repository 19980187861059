import { omit } from 'lodash';

import {
  AttachmentInput,
  MessageStatus,
  MessageType,
  Reference,
  User,
} from '@pro4all/graphql';
import { 
  AddMessageMutation, 
  UpdateDraftMessageMutation,
} from '@pro4all/graphql';

interface MessageConfigArgs {
  _Bcc: string[];
  _Cc: string[];
  _To: string[];
  attachments: AttachmentInput[];
  body: string;
  draft: boolean;
  messageId: string;
  parentId?: string;
  projectId: string;
  references: Reference[];
  replyId: string | null;
  subject: string;
  threadId: string;
  user: User;
}

export const getUpdateMessageConfig = ({
  attachments,
  body,
  draft,
  messageId,
  projectId,
  references,
  replyId,
  subject,
  threadId = '',
  parentId: _parentId,
}: MessageConfigArgs) => {
  const variables = {
    attachments: attachments.map(
      (option) => omit(option, ['__typename']) as AttachmentInput
    ),
    body,
    id: messageId,
    messageId: messageId,
    parentId: replyId || _parentId || '',
    projectId,
    references,
    status: draft ? MessageStatus.Draft : MessageStatus.Sent,
    subject,
    threadId,
  };

  const optimisticResponse: UpdateDraftMessageMutation = {
    updateDraftMessage: {
      __typename: 'Message',
      attachments: attachments.map((option) => ({
        fileId: option.fileId,
        fileName: option.fileName,
        fileSize: option.fileSize,
      })),
      bcc: [],
      body,
      cc: [],
      createdAt: null,
      createdBy: {
        __typename: 'User',
        displayName: '',
        email: '',
        id: '',
        isAdmin: false,
        organization: null,
      },
      deleted: false,
      fromId: '',
      id: messageId,
      messageType: MessageType.Draft,
      parentId: replyId || _parentId || '',
      read: true,
      references,
      resolved: false,
      status: MessageStatus.Draft,
      subject,
      threadId,
      to: [],
      updatedAt: null,
    },
  };

  return { optimisticResponse, variables };
};

export const getAddMessageConfig = ({
  attachments,
  body,
  draft,
  messageId,
  projectId,
  references,
  replyId,
  subject,
  threadId = '',
  user,
  _To,
  _Cc,
  _Bcc,
}: MessageConfigArgs) => {
  const optimisticResponse: AddMessageMutation = {
    addMessage: {
      __typename: 'Message',
      attachments: attachments.map((option) => ({
        fileId: option.fileId,
        fileName: option.fileName,
        fileSize: option.fileSize,
      })),
      bcc: _Bcc,
      body,
      cc: _Cc,
      createdAt: null,
      createdBy: {
        __typename: 'User',
        displayName: user.displayName || 'unknown',
        email: user.email || 'unknown',
        id: user.id || 'unknown',
        isAdmin: user.isAdmin || false,
        organization: user.organization || null,
      },
      deleted: false,
      fromId: user.id || 'unknown',
      id: 'new',
      messageType: draft ? MessageType.Draft : MessageType.Sent,
      parentId: replyId,
      read: true,
      references,
      resolved: false,
      status: draft ? MessageStatus.Draft : MessageStatus.Sent,
      subject,
      threadId,
      to: _To,
      updatedAt: null,
    },
  };

  const variables = {
    attachments: attachments.map(
      (option) => omit(option, ['__typename']) as AttachmentInput
    ),
    body,
    id: messageId,
    parentId: replyId,
    projectId,
    references,
    status: draft ? MessageStatus.Draft : MessageStatus.Sent,
    subject,
    threadId,
  };

  return { optimisticResponse, variables };
};
