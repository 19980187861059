import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

import { Box, Slider, Typography } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { AvatarSizeXXL } from '@pro4all/shared/ui/avatar';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { FileUpload } from './FileUpload';
import { FileUploadOverlay } from './FileUploadOverlay';
import { useFileUploadContext } from './FileUploadProvider';
import { useFileRejections } from './useFileRejections';

type FileUploadInputProps = {
  acceptedFileTypes: string[];
  currentProfileImage: File | undefined;
  editorRef: React.RefObject<AvatarEditor>;
  maxSize: number;
  onDrop: (files: File[]) => void;
  removeCallback: () => void;
};

export const AvatarInput = ({
  acceptedFileTypes,
  currentProfileImage,
  editorRef,
  maxSize,
  onDrop,
  removeCallback,
}: FileUploadInputProps) => {
  const { t } = useTranslation();
  const [scale, setScale] = useState(1);
  const { isUploading } = useFileUploadContext() || {};

  const onDropRejected = useFileRejections({ acceptedFileTypes, maxSize });

  const imageSize = AvatarSizeXXL.avatarSize;
  const imageBorder = 0; // In case we want to have a transparant round image border in the future.
  const sliderWidth = imageSize + imageBorder * 2 - 20;

  return (
    <Box sx={{ width: '100%' }}>
      <FileUpload
        accept={acceptedFileTypes}
        maxSize={maxSize}
        multiple={false}
        onDrop={onDrop}
        onDropRejected={onDropRejected}
      >
        {({ isDragActive, openFileInput }) => (
          <FileUploadOverlay isActive={isDragActive} withStickyHeader={false}>
            <Box sx={{ width: '100%' }}>
              {/* If there's an image, we show the Avatar editor */}
              {currentProfileImage && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      background: customColors.grey300,
                      display: 'flex',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <AvatarEditor
                      border={imageBorder}
                      borderRadius={imageSize / 2}
                      height={imageSize}
                      image={currentProfileImage}
                      ref={editorRef}
                      rotate={0}
                      scale={scale}
                      width={imageSize}
                    />
                    <Tooltip
                      placement="right-start"
                      title={t('Remove profile picture')}
                    >
                      <IconButton
                        color="default"
                        disableBorder
                        disabled={isUploading}
                        iconName="delete"
                        onClick={removeCallback}
                        sx={{
                          position: 'absolute',
                          right: 2,
                          top: 2,
                        }}
                      />
                    </Tooltip>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {/* Slider to resize image */}
                    <Slider
                      aria-label="Zoom"
                      max={2}
                      min={1}
                      onChange={(e, newValue) => setScale(newValue as number)}
                      step={0.01}
                      sx={{ ml: 2, width: sliderWidth }}
                      value={scale}
                    />
                  </Box>
                </Box>
              )}

              {/* If there's no image, we show the requirements and a central upload button */}
              {!currentProfileImage && (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 4,
                    }}
                  >
                    <Typography variant="body1">
                      {`${t('Maximum file size')}: ${maxSize / 1024 / 1024} MB`}
                    </Typography>
                    <Typography variant="body1">
                      {`${t('Allowed file types')}: ${acceptedFileTypes.join(
                        ', '
                      )}`}
                    </Typography>
                  </Box>
                  <Button
                    data-testid="select-file"
                    onClick={openFileInput}
                    type="button"
                    variant="contained"
                  >
                    {t('Upload')}
                  </Button>
                </Box>
              )}
            </Box>
          </FileUploadOverlay>
        )}
      </FileUpload>
    </Box>
  );
};
