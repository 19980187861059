import { FieldArrayRenderProps } from 'formik';
import { debounce } from 'lodash';
import { v4 as uuid } from 'uuid';

import { useRouting } from '@pro4all/shared/routing-utils';

import { useFieldValidation } from './useFieldValidation';
interface Props {
  arrayHelpers: FieldArrayRenderProps;
  event: React.ClipboardEvent<HTMLInputElement>;
  index?: number | undefined;
  newOptionRef?: React.MutableRefObject<HTMLInputElement>;
  selectionOptions?: SelectOption[];
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
  setNewOptionName?: React.Dispatch<React.SetStateAction<string>>;
}

type SelectOption = {
  name: string;
};

export const usePaste = () => {
  const { fieldValidation } = useFieldValidation();
  const {
    params: { projectId },
  } = useRouting();

  const onPasteOverWriteHandler = ({
    event,
    arrayHelpers,
    index,
    newOptionRef,
    selectionOptions,
    setFieldTouched,
    setNewOptionName,
  }: Props) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const detectTextBreakLines = pastedText.match(/\n/gm);

    if (detectTextBreakLines) {
      const pastedOptions = pastedText.replace(/,/g, '').split(/\n/gm);
      pastedOptions
        .slice()
        .reverse()
        .forEach((option) => {
          if (index !== undefined) {
            arrayHelpers.insert(index === 0 ? 1 : index + 1, {
              name: option,
            });
            if (
              newOptionRef &&
              selectionOptions &&
              setFieldTouched &&
              setNewOptionName
            ) {
              fieldValidation({
                selectionOptions,
                setFieldTouched,
                setNewOptionName,
              });
            }
            return;
          }
        });
    }
  };

  const onPasteHandler = ({
    event,
    arrayHelpers,
    newOptionRef,
    selectionOptions,
    setFieldTouched,
    setNewOptionName,
  }: Props) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const pastedOptions = pastedText.replace(/,/g, '').split(/\n/gm);
    pastedOptions.forEach((option) => {
      arrayHelpers.push({
        id: uuid(),
        name: option.trim(),
        projectId: projectId ?? null,
      });
    });
    if (
      newOptionRef &&
      selectionOptions &&
      setFieldTouched &&
      setNewOptionName
    ) {
      fieldValidation({
        selectionOptions,
        setFieldTouched,
        setNewOptionName,
      });
    }
    const totalNumOptions =
      (selectionOptions?.length ?? 0) + pastedOptions.length;

    const throttledSetFieldTouched = debounce((index) => {
      setFieldTouched(`selectionOptions[${index}].name`);
      setFieldTouched(`statusOptions[${index}].name`);
      setFieldTouched(`newOptionError${index}`);
      setFieldTouched(`statusOptions[${index}].color`);
    }, 100);

    if (selectionOptions && setFieldTouched) {
      for (let i = 0; i <= totalNumOptions; i++) {
        throttledSetFieldTouched(i);
      }
    }
  };

  return { onPasteHandler, onPasteOverWriteHandler };
};
