import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ValueTypeName } from '@pro4all/graphql';
import {
  FormFieldConfig,
  SchemaExtender,
  useFormConfig,
} from '@pro4all/shared/forms';

import { useValidateUniqueEmail } from '../../hooks/useValidateUniqueEmail';
import { useSharedFormFieldsConfig } from '../useSharedFormFieldsConfig';

export const useStep2Config = () => {
  const { t } = useTranslation();

  const {
    confirmPasswordConfig,
    passwordConfig,
    confirmPasswordCustomValidation,
  } = useSharedFormFieldsConfig();

  const formFields: FormFieldConfig[] = [
    {
      displayName: 'Email address', // i18n
      email: true,
      name: 'email',
      required: true,
      type: ValueTypeName.Text,
    },
    passwordConfig,
    confirmPasswordConfig,
    {
      name: 'recaptcha',
      required: true,
      type: ValueTypeName.Text,
    },
  ];

  const validateUniqueEmail = useValidateUniqueEmail();

  const customEmailValidation: SchemaExtender<Yup.StringSchema> = (schema) =>
    schema.test(
      'unique',
      t('An account with this email already exists'),
      async function (value) {
        if (
          !value ||
          !this.parent.email ||
          !Yup.string().email().isValidSync(value)
        ) {
          return true; // Skip duplicate check if email is empty or invalid
        }
        return validateUniqueEmail(value);
      }
    );

  return useFormConfig({
    customValidation: {
      confirmPassword: confirmPasswordCustomValidation,
      email: customEmailValidation,
    },
    formFields,
  });
};
