import { useState } from 'react';

import { Position } from '../types';

export function useHandlePositionChange() {
  const [position, setPosition] = useState<Position | null>(null);

  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/^0+/, ''); // Remove leading zeros
    const numericValue =
      sanitizedValue === '' ? sanitizedValue : Number(sanitizedValue);
    if (numericValue > 0 || sanitizedValue === '') {
      setPosition((prev) => ({
        ...(prev || { x: 10, y: 10 }),
        [name]: numericValue,
      }));
    }
  };

  return { handlePositionChange, position };
}
