import styled from 'styled-components';

import { Checkbox } from '@pro4all/shared/mui-wrappers';
import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { Icon } from '@pro4all/shared/ui/icons';
import { Main as UIMain, Row as UIRow } from '@pro4all/shared/ui/layout';
import { Text } from '@pro4all/shared/ui/typography';

export const StyledBox = styled(Box)`
  && {
    margin-left: 1rem;
  }
`;

export const StyledIcon = styled(Icon)`
  flex: auto;
  color: ${customColors.grey700};
`;

export const BigTitleBouwbesluit = styled(Text)``;

export const BigTitlePlus = styled(Text)`
  && {
    width: 17rem;
  }
`;

export const Main = styled(UIMain)`
  && {
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 1.5rem;
  }
`;

export const Row = styled(UIRow).withConfig<{ gap?: number }>({
  shouldForwardProp: (prop) => prop !== 'gap',
})`
  && {
    flex: none;
    flex-wrap: wrap;
    max-width: 67rem;
    margin-top: 0.75rem;
    gap: ${({ theme, gap }) => theme.spacing(gap)};
  }
`;

export const BouwbesluitState = styled.div`
  display: flex;
  padding: 0.3rem;
  height: 28px;
  background-color: ${({ theme }) => theme.palette.success.light};
  border-radius: 5px;
  color: #006b5e;
  text-align: center;
`;

export const PlusBouwbesluitState = styled.div`
  padding: 0.2rem;
  height: 28px;
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 5px;
  color: ${customColors.grey200};
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    font-size: 16px;
    height: 28px;
    border: 1px solid #afafaf;
    border-radius: 20px;
    background-color: none;
    margin-left: auto;

    &:hover {
      background-color: ${customColors.grey200};
    }

    &.Mui-checked:hover {
      background-color: ${customColors.grey200};
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: 7.25rem;
    height: 2.25rem;
    margin: 0.75rem 0rem;
    padding: 0.37rem, 1rem, 0.37rem, 1rem;
  }
`;
