import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { TMuiIcon } from '@pro4all/shared/composed-snag-form-pin';
import { Box, Button } from '@pro4all/shared/mui-wrappers';
import {
  FileUpload,
  FileUploadOverlay,
  useFileRejections,
} from '@pro4all/shared/ui/file-upload';
import { Text } from '@pro4all/shared/ui/typography';

import { TFileParams } from '../shared-select-snag-icon';

const MAX_SIZE = 5000000;

type TCustomIconUploadProps = {
  onUpload: (iconName?: TMuiIcon, fileParams?: TFileParams) => void;
};

export const CustomIconUpload = (props: TCustomIconUploadProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const acceptedFileTypes = ['.jpeg', '.jpg', '.png'];

  const handleOnDrop = async (files: File[]) => {
    const currentFile = files?.[0] || null;
    if (currentFile) {
      const img = new Image();
      img.onload = function () {
        const width = img.width;
        const height = img.height;
        const aspectRatio = width / height;

        if (aspectRatio > 2) {
          enqueueSnackbar(t('The aspect ratio of the image is too wide.'));
          return;
        }
      };
      img.src = URL.createObjectURL(currentFile);

      props.onUpload(undefined, { file: currentFile });
    }
  };

  const onDropRejected = useFileRejections({
    acceptedFileTypes,
    maxSize: MAX_SIZE,
  });

  return (
    <Box>
      <FileUpload
        accept={acceptedFileTypes}
        maxSize={MAX_SIZE}
        multiple={false}
        onDrop={handleOnDrop}
        onDropRejected={onDropRejected}
      >
        {({ isDragActive, openFileInput }) => (
          <Box sx={{ padding: 3 }}>
            <FileUploadOverlay isActive={isDragActive} withStickyHeader={false}>
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Box mb={4}>
                  <Text variant="body1">
                    {t('Recommended size is 128 x 128 pixels.')}
                  </Text>
                  <Text variant="body1">
                    {t('Maximum size per file is 5 MB.')}
                  </Text>
                </Box>
                <Box>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      openFileInput();
                    }}
                    variant="contained"
                  >
                    {t('Upload')}
                  </Button>
                </Box>
              </Box>
            </FileUploadOverlay>
          </Box>
        )}
      </FileUpload>
    </Box>
  );
};
