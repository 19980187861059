import React from 'react';
import { useTranslation } from 'react-i18next';

import { NativeCheckbox } from '@pro4all/shared/ui/inputs';

import { useTableCheck } from '../hooks/useTableCheck';
import { BaseRow } from '../types';

export function CheckRow<Row extends BaseRow>({
  row,
  rowIndex,
}: {
  row: Row;
  rowIndex: number;
}) {
  const { t } = useTranslation();
  const { isChecked, checkRow } = useTableCheck();
  const rowIsChecked = isChecked(row);

  return (
    <NativeCheckbox
      aria-label={rowIsChecked ? t('Deselect row') : t('Select row')}
      checked={rowIsChecked}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange={() => {}}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        checkRow(row, rowIndex, event.shiftKey);
      }}
    />
  );
}
