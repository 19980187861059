import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useOrganizationContext } from '@pro4all/organization/context';
import { IdentityService } from '@pro4all/shared/api-services';
import { useGenericContext } from '@pro4all/shared/providers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ProfilePictureEntity } from '@pro4all/shared/types';

import { useFileUploadContext } from './FileUploadProvider';

type ProfilePictureUploadProps = {
  profilePictureEntity: ProfilePictureEntity;
};

export const useProfilePictureUpload = ({
  profilePictureEntity,
}: ProfilePictureUploadProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { upload } = useFileUploadContext() || {};

  const userId = AuthService.getProfile()?.userId;
  const { organizationId } = useOrganizationContext();
  const { params } = useRouting();
  const { projectId } = params;

  const { toggleReloadProfilePictures } = useGenericContext();

  const id =
    profilePictureEntity === ProfilePictureEntity.Organization
      ? organizationId
      : profilePictureEntity === ProfilePictureEntity.Project
      ? projectId || ''
      : profilePictureEntity === ProfilePictureEntity.User
      ? userId
      : '';

  const url =
    profilePictureEntity === ProfilePictureEntity.Organization
      ? `/v2/organization/${id}/picture`
      : profilePictureEntity === ProfilePictureEntity.Project
      ? `/v2/project/${id}/picture`
      : profilePictureEntity === ProfilePictureEntity.User
      ? `/v2/user/${id}/picture`
      : '';

  const handleUploadProfilePicture = async (files: File[]) => {
    try {
      upload &&
        id &&
        url &&
        upload(files, async (file, onProgress) => {
          const res = await IdentityService.uploadProfilePicture({
            file,
            onProgress,
            url,
          });
          toggleReloadProfilePictures();
          enqueueSnackbar(t('Profile picture stored successfully'));
          return res;
        });
    } catch (error) {
      enqueueSnackbar(t('Failed to store profile picture'), {
        variant: 'error',
      });
    }
  };

  const handleRemoveProfilePicture = async () => {
    if (id && url) {
      try {
        await IdentityService.removeProfilePicture({
          url,
        });
        toggleReloadProfilePictures();
        enqueueSnackbar(t('Profile picture removed successfully'));
      } catch (error) {
        enqueueSnackbar(t('Failed to remove profile picture'), {
          variant: 'error',
        });
      }
    }
  };

  return { handleRemoveProfilePicture, handleUploadProfilePicture };
};
