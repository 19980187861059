import React from 'react';
import { useField } from 'formik';

import { DatePickerProps } from '@pro4all/shared/mui-wrappers';
import { DateField } from '@pro4all/shared/ui/inputs';

export const FormikDate: React.FC<
  Pick<DatePickerProps, 'label' | 'minDate' | 'maxDate'> & {
    name: string;
  }
> = ({
  label,
  name,
  maxDate = new Date('2099-12-31'),
  minDate = new Date('1900-01-01'),
}) => {
  const [field, meta, helpers] = useField({ name });
  const error = Boolean(meta.touched) && Boolean(meta.error);

  const setValue = (value: string) => {
    helpers.setTouched(true);
    helpers.setValue(value);
  };

  const handleChange: DatePickerProps['onChange'] = (date: Date) => {
    const selectedDateObject = new Date(date);
    const selectedDate = date ? selectedDateObject.toString() : '';
    setValue && setValue(selectedDate);
  };

  return (
    <DateField
      error={error}
      helperText={meta.error}
      label={label}
      maxDate={maxDate}
      minDate={minDate}
      name={name}
      onChange={handleChange}
      value={field.value}
    />
  );
};
