import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { LockType, useDocumentQuery } from '@pro4all/graphql';
import { useSessionStorage } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { IconName } from '@pro4all/shared/ui/icons';
import {
  OFFICE_ICON_BY_EXTENSION,
  VALID_OFFICE_EXTENSIONS,
} from '@pro4all/shared/utils';

import { DocumentActionProps } from '../../types';

import { useGetOfficeOnlineInfo } from './useGetOfficeOnlineInfo';
import { useOnClickOnlineOffice } from './useOnClickOnlineOffice';

export const useOpenOfficeAction = ({
  selection,
  uncheckAllRows,
}: Pick<DocumentActionProps, 'selection' | 'uncheckAllRows'>) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const extension = selection[0]?.extension?.replace('.', '') || '';
  const isOfficeExtension = VALID_OFFICE_EXTENSIONS.includes(extension);
  const { versionId } = selection[0] || {};

  const { data } = useDocumentQuery({
    fetchPolicy: 'network-only',
    skip: !isOfficeExtension,
    variables: { id: selection[0]?.id ?? '' },
  });

  const document = data?.document;

  const lockType = document ? document.lockType : selection[0]?.lockType;
  const selectedDoc = document ? [document] : selection;

  const { hideOficeAction, officeActionUrl, readOnly } = useGetOfficeOnlineInfo(
    {
      isOfficeExtension,
      selection: selectedDoc,
    }
  );

  const { setSessionStorageItem } = useSessionStorage({
    key: `online-office-url-${data?.document?.versionId ?? versionId}`,
  });

  useEffect(() => {
    setSessionStorageItem(officeActionUrl); // Used in the office host page.
  }, [officeActionUrl, setSessionStorageItem]);

  const openOfficeOnline = useOnClickOnlineOffice({
    extension,
    readOnly,
    selection: selectedDoc,
  });

  const openWarningModal = () => {
    searchParams.set({
      action: 'officeonline',
      documentId: selection[0].id,
    });
  };

  const getLabel = () => {
    const label =
      extension.length > 0 && OFFICE_ICON_BY_EXTENSION[extension]
        ? OFFICE_ICON_BY_EXTENSION[extension]
        : 'Unknown';
    return t('Open in {{name}} for the web', {
      name: label.charAt(0).toUpperCase() + label.slice(1),
    });
  };

  const openOfficeAction: ActionProps = {
    ariaLabel: getLabel(),
    dataTestId: 'open-office',
    disabled: !isOfficeExtension || selection.length > 1 || hideOficeAction,
    key: 'open-office',
    label: getLabel(),
    onClick: () => {
      if (lockType === LockType.OfficeOnline) {
        openWarningModal();
        uncheckAllRows();
      } else {
        openOfficeOnline();
        uncheckAllRows();
      }
    },
    startIcon: OFFICE_ICON_BY_EXTENSION[extension] as IconName,
  };

  return openOfficeAction;
};
