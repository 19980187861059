import React from 'react';

import { User } from '@pro4all/graphql';
import { IdentityTagType } from '@pro4all/shared/types';

import { IdentityTag } from './IdentityTag';

export const UserTag = ({ user }: { user: User }) => {
  const { displayName, id } = user || {};
  return (
    <IdentityTag id={id} label={displayName} type={IdentityTagType.User} />
  );
};
