import styled, { css } from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

const sidebarWidth = '360px';
export const navBarHeight = '65px';

const row = css`
  align-items: center;
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 1rem;
  }
`;

export const Footer = styled.footer`
  ${row}
  padding: 1rem 1.5rem 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.header`
  ${row}
  padding: 1.5rem 1.5rem 1rem;
  border-bottom: 1px solid ${customColors.grey400};
  background-color: white;
  height: ${navBarHeight};
`;

export const Progress = styled.aside`
  color: #533be2;
  font-size: 1rem;
  margin-right: auto;
  text-align: right;
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  margin: 0 auto 0 1.5rem;
  text-align: center;
`;

export const CheckboxSection = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1.5rem 0.5rem;
  border-bottom: 1px solid ${customColors.grey400};
`;

export const SidebarSection = styled.div`
  padding: 1rem;
  width: ${sidebarWidth};
`;

export const SectionTitle = styled.h2`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  padding: 1rem;
`;

export const SectionSubtitle = styled.h2`
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

export const SectionContent = styled.h2`
  display: flex;
  align-content: space-between;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 1rem 1.5rem 1rem;
  border-bottom: 1px solid ${customColors.grey400};
  background-color: white;
`;

export const StamperContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export const CanvasContainer = styled.div`
  margin: 3rem;
`;

export const BoxContainer = styled(Box)`
  height: 100%;
  display: flex;
`;
