import { FieldDefinition, QualityControlInstance } from '@pro4all/graphql';
import { PhotoService } from '@pro4all/quality-control/data-access';
import { FetchArgs } from '@pro4all/shared/types';

export function getPhotoBlobs(instances: QualityControlInstance[]) {
  const fetchUrl = ({ id, instanceId }: FetchArgs) =>
    PhotoService.getThumbnailUrl({
      id,
      instanceId,
      timeout: 0,
    });

  const keyValue: Record<string, Promise<string>> = {};

  const addUrlToRecord = (value: string, instanceId: string) => {
    const ids = value.split(',') || [value];
    for (const id of ids) {
      keyValue[id] = fetchUrl({ id, instanceId });
    }
  };

  const processAllInstances = (instances: QualityControlInstance[]) => {
    const processItem = (item: FieldDefinition, instanceId: string): void => {
      if (item.type === 'Section') {
        item.valueType?.subFields?.forEach((item) =>
          processItem(item, instanceId)
        );
      }
      if (item.type === 'Media' && item.value) {
        addUrlToRecord(item.value, instanceId);
      }
    };
    instances.forEach((instance) => {
      if (instance.items)
        instance.items.forEach((item) => processItem(item, instance.id));
    });
  };

  return async () => {
    processAllInstances(instances);
    const promises = Object.values(keyValue);
    const keys = Object.keys(keyValue);
    const result: { [x: string]: string } = {};

    try {
      const resolvedBlobs = await Promise.all(promises);
      keys.forEach((key, index) => {
        result[key] = resolvedBlobs[index];
      });
    } catch (error) {
      console.error(`Failed to fetch image blob: ${error}`);
    }

    return result;
  };
}
