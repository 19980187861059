import React, { useMemo, useState } from 'react';

import { downloadDocument } from '@pro4all/documents/ui/share';
import { DocumentSidebar } from '@pro4all/documents/ui/sidebar';
import {
  SearchDocument,
  useFolderWithPermissionsByPathLazyQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { QueryTooGenericFeedback } from '@pro4all/search/ui';
import { useDocumentSearchContext } from '@pro4all/shared/documents-search-context';
import {
  useDocumentActions,
  useDocumentSelection,
} from '@pro4all/shared/hooks/src/documents';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useColumns } from '@pro4all/shared/search';
import {
  SearchEntities,
  useSearchTracking,
} from '@pro4all/shared/search-utils';
import { Position } from '@pro4all/shared/types';
import { DataViewType, useDataViewContext } from '@pro4all/shared/ui/data-view';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Main } from '@pro4all/shared/ui/layout';
import { Loader } from '@pro4all/shared/ui/loader';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { searchToDocument } from '@pro4all/shared/utils';

import DocumentSearchCharts from './analytics/DocumentSearchCharts';
import { NoResultsMessage } from './NoResultsMessage';
import { SearchResultsActionBar } from './SearchResultsActionBar';

export const SearchResults: React.FC = () => {
  const { searchParams, params } = useRouting();
  const {
    documents: searchDocuments,
    limitReached,
    loading,
  } = useDocumentSearchContext();
  const documents = searchDocuments?.map(searchToDocument);
  const { deselectDocument, selectDocument } = useDocumentSelection(
    documents || []
  );
  const { trackSearchResultClicked } = useSearchTracking(
    SearchEntities.Document
  );

  const { currentView } = useDataViewContext();

  const {
    state: { items, item },
  } = useOptimisticResponseContext<SearchDocument>();

  const searchResults = useMemo(() => searchDocuments || [], [searchDocuments]);

  useSetItemsInLocalState<SearchDocument>(searchResults);

  const documentId = searchParams.get('id');

  const { userId } = useOrganizationContext();

  const [getFolder] = useFolderWithPermissionsByPathLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleSelect = async (rowDocument: SearchDocument) => {
    if (!documents) return;
    const listIndex = documents.findIndex(
      (document) => document.id === rowDocument.id
    );
    trackSearchResultClicked(rowDocument.id, listIndex);
    const path = documents[listIndex].path;
    const folder = await getFolder({
      variables: {
        entityIds: userId ? [userId] : [],
        inherit: true,
        path: path || '/',
        projectId: params.projectId,
      },
    });
    const folderPermissions =
      folder?.data?.folder?.entityFolderPermissions?.find(
        (efp) => efp?.id === userId
      )?.folderPermissions;

    const resolvedDocument = { ...rowDocument, folderPermissions, path };
    selectDocument(searchToDocument(resolvedDocument));
  };

  const columns = useColumns();

  const [actionLoading, setActionLoading] = useState(false);

  const { searchActions } = useDocumentActions({
    position: Position.Sidebar,
    setLoading: setActionLoading,
    sidebarRow: item ? searchToDocument(item) : undefined,
  });

  const handleDownload = async (searchDocument: SearchDocument) => {
    await downloadDocument(searchToDocument(searchDocument));
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {limitReached && !loading && (
        <Box px={2}>
          <QueryTooGenericFeedback />
        </Box>
      )}

      {currentView === DataViewType.Charts && (
        <DocumentSearchCharts
          loading={loading}
          searchDocuments={searchResults}
        ></DocumentSearchCharts>
      )}

      {currentView === DataViewType.Table && (
        <TableContextProvider
          checkable
          columns={columns}
          id="table-search-results"
          items={items}
        >
          <FilterContextProvider<SearchDocument>>
            <Main>
              <Box display="flex" flex={1} flexDirection="column">
                <SearchResultsActionBar setActionLoading={setActionLoading} />
                {loading ? (
                  <Loader />
                ) : (
                  <Table<SearchDocument>
                    emptyRenderer={<NoResultsMessage />}
                    enableKeyboardControl
                    onRowClick={handleSelect}
                    onRowDoubleClick={handleDownload}
                    overlayRenderer={actionLoading ? <Loader /> : null}
                  />
                )}
              </Box>
            </Main>
            <DocumentSidebar
              documentId={documentId || ''}
              onClose={deselectDocument}
              sidebarActions={searchActions}
            />
          </FilterContextProvider>
        </TableContextProvider>
      )}
    </Box>
  );
};
