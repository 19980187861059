/* A minimal implementation of Autocomplete with MUI color, size and variant compatibility  */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Autocomplete,
  AutocompleteGetTagProps,
  Box,
  ChipTypeMap,
  TextFieldProps,
  UseAutocompleteProps,
} from '@pro4all/shared/mui-wrappers';
import {
  Option,
  RenderCustomInputProps,
  RenderCustomOptionProps,
} from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';
import { Tag } from '@pro4all/shared/ui/tag';

import { DynamicPopper } from '../searchable/DynamicPopper';

import { _TextField as TextField } from './_TextField';

export interface Props {
  autoCompleteProps: UseAutocompleteProps<Option | string, true, false, false>;
  color?: ChipTypeMap['props']['color'];
  disableCloseOnSelect?: boolean;
  fullWidth?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  label?: TextFieldProps['label'];
  loading?: boolean;
  noOptionsText?: string;
  onDelete?: (event: any) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  renderCustomInput?: (props: RenderCustomInputProps) => React.ReactNode;
  renderCustomOption?: (props: RenderCustomOptionProps) => React.ReactNode;
  tagVariant?: 'filled' | 'outlined';
}

export function isOption(input: string | Option): input is Option {
  return typeof input !== 'string' && input !== null;
}

export const _MultiSelect: React.FC<Props> = ({
  autoCompleteProps,
  color = 'primary',
  disableCloseOnSelect = true,
  fullWidth = true,
  inputRef,
  loading,
  noOptionsText = 'No options',
  onPaste,
  renderCustomInput,
  renderCustomOption,
  tagVariant,
}) => {
  const { t } = useTranslation();
  const [_maxHeight, setMaxHeight] = useState(0);

  const listboxRef = useRef<HTMLUListElement | null>(null);
  const previousScrollPosition = useRef(0);

  const resolvedInputColor: TextFieldProps['color'] =
    color === 'primary' || color === 'secondary' ? color : undefined;

  const renderTags = (
    value: Option[],
    getTagProps: AutocompleteGetTagProps
  ) => (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        maxHeight: '300px',
        overflowY: 'auto',
      }}
    >
      {value.map((option, index) => {
        const tagProps = getTagProps({ index });
        if (renderCustomInput) {
          return (
            <Box
              key={option.id}
              sx={{
                mr: 1,
                my: 0.75,
              }}
            >
              {renderCustomInput({
                hasBorder: true,
                onDelete: tagProps.onDelete,
                option,
              })}
            </Box>
          );
        } else {
          return (
            <Tag
              {...tagProps}
              color={color}
              icon={option.iconName && <Icon iconName={option.iconName} />}
              key={option.id}
              name={option.label}
              tagId={option.id}
              variant={tagVariant}
            />
          );
        }
      })}
    </Box>
  );

  return (
    <Autocomplete
      {...autoCompleteProps}
      ListboxProps={{
        onScroll: () => {
          // Store the current scrollposition
          if (listboxRef.current) {
            previousScrollPosition.current = listboxRef.current.scrollTop;
          }
        },
        ref: (node) => {
          // Check if the node-element ia a ul and cast it explicitly
          if (node && node instanceof HTMLUListElement) {
            listboxRef.current = node;
            // Restore scroll position when list is re-rendered
            node.scrollTop = previousScrollPosition.current;
          }
        },
        style: {
          maxHeight: `${_maxHeight}px`, // Use the dynamical height
          overflow: 'auto',
        },
      }}
      PopperComponent={(popperProps) => (
        <DynamicPopper {...popperProps} setMaxHeight={setMaxHeight} />
      )}
      color={color}
      disableClearable
      disableCloseOnSelect={disableCloseOnSelect}
      freeSolo
      fullWidth={fullWidth}
      loading={loading}
      loadingText={t('Loading')}
      multiple
      noOptionsText={t(noOptionsText)}
      onPaste={onPaste}
      renderInput={(params) => (
        <TextField
          {...params}
          color={resolvedInputColor}
          inputProps={{
            ...params.inputProps,
          }}
          inputRef={inputRef}
          name="name"
        />
      )}
      renderOption={(props, option: Option) => {
        const { iconColor, iconName, id, label } = option;
        if (renderCustomOption) {
          return renderCustomOption({ option, props }); // Render User or Group Card f.i., but can be any JSX.
        }
        return (
          <li {...props} key={id}>
            {iconName && (
              <StyledIcon htmlColor={iconColor} iconName={iconName} />
            )}
            {label}
          </li>
        );
      }}
      renderTags={renderTags}
      size="small"
    />
  );
};

const StyledIcon = styled(Icon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
