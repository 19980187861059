import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import {
  QualityControlInstance,
  ResourceType,
  Status,
  Task,
  TaskType,
  TbqAnswer,
  TbqRieResult,
  useAddQuestionsToResolveTaskBatchMutation,
  useCreateTaskMutation,
  useObjectIncludeQuery,
  useSetTaskTemplateLinksMutation,
  useSetTaskVisualContextLinksMutation,
  useTaskStatusesQuery,
  useUpdateTaskMutation,
} from '@pro4all/graphql';
import { Button } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
  useShowMessages,
} from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';
import { toApiDate } from '@pro4all/shared/utils';
import {
  isQualityControlInstance,
  isTbqBrandcheck,
  isTbqRie,
} from '@pro4all/workflow/ui/utils';

import { FormFields } from './types';

type Props = {
  linkInstances?: (QualityControlInstance | TbqRieResult | TbqAnswer)[];
  procedureId: string;
  task?: Task;
  tbqBrandcheckQcInstance?: QualityControlInstance;
  tbqQuestion?: TbqRieResult | TbqRieResult[] | TbqAnswer | TbqAnswer[];
};

export const useTBQSubmit = ({
  task,
  procedureId,
  tbqQuestion,
  linkInstances = [],
  tbqBrandcheckQcInstance,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createTask] = useCreateTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [setTaskTemplateLinks] = useSetTaskTemplateLinksMutation();
  const [setTaskVisualContextLinks] = useSetTaskVisualContextLinksMutation();
  const { showSingleError } = useShowMessages();
  const { t } = useTranslation();

  const [addQuestionsToResolveTaskBatch] =
    useAddQuestionsToResolveTaskBatchMutation();

  const { goTo, params, searchParams, goBack } = useRouting();
  const { projectId, objectId } = params;
  const objId = objectId ?? '';

  const { data: objectData } = useObjectIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !objectId,
    variables: { id: objId, includeProjectId: true },
  });
  const objectProjectId = objectData?.object?.projectId ?? '';

  const tbqQuestions = Array.isArray(tbqQuestion) ? tbqQuestion : [tbqQuestion];

  const { refetch } = useTaskStatusesQuery({
    fetchPolicy: 'no-cache',
    skip: true,
  });

  const {
    addItems,
    editItems,
    setItem,
    state: { items },
  } = useOptimisticResponseContext<Task>();

  const commands = task?.availableStatuses.map((ts) => ({
    command: ts.command,
    id: ts.taskStatus,
  }));

  const isEditMode = Boolean(task);

  const handleCloseTBQBrandcheckQuestionSidebar = () => {
    searchParams.delete('action');
    searchParams.delete('taskType');
  };

  return async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    const {
      description,
      endTime,
      name,
      participant,
      nextStatus,
      type,
      formTemplates = [],
      snagTemplates = [],
      visualContexts = [],
      documentAction,
    } = values;

    let message;

    // We only want to show the name and not the email.
    const participantNameDisplay = participant?.label?.split('(')[0];

    try {
      const status = nextStatus.id as Status;
      let taskId = task?.id;
      const deadline = endTime ? toApiDate(endTime) : null;

      if (isEditMode) {
        const payload = {
          command: commands.find((c) => c.id === status)?.command,
          description,
          documentAction: documentAction?.id,
          endTime: deadline,
          id: task.id,
          name,
          procedureId,
          status,
          type,
          userId: participant?.id,
        };
        await updateTask({
          variables: payload,
        });

        const {
          data: { taskStatuses },
        } = await refetch({
          id: taskId,
          status,
        });

        const qcInstances = linkInstances.filter((instance) =>
          isQualityControlInstance(instance)
        ) as QualityControlInstance[];

        setItem({
          ...task,
          ...payload,
          assignment: [
            { displayName: participantNameDisplay, id: participant?.id },
          ],
          linkedSnagInstances: qcInstances,
        });

        editItems(
          items
            .filter((item) => item.id === task?.id)
            .map((item) => ({
              ...item,
              assignment: [
                { displayName: participantNameDisplay, id: participant?.id },
              ],
              availableStatuses: taskStatuses,
              endTime: deadline,
              name,
              status: Status[status],
              type,
            }))
        );

        message = (
          <ItemChangedMessage
            description={MessageAction.Update}
            entityName={name}
            entityTypeTranslation={EntityTypeTranslation.Task}
          />
        );
      } else {
        let result;
        if (type === TaskType.TbqResolve || type === TaskType.QualityControl) {
          result = await createTask({
            variables: {
              description,
              endTime: deadline,
              name,
              procedureId,
              type,
              userId: participant?.id,
            },
          });
          taskId = result.data?.createTask?.id;
        }

        const {
          data: { taskStatuses },
        } = await refetch({
          id: taskId,
          status: Status.ToDo,
        });
        if (type === TaskType.QualityControl) {
          addItems([
            {
              assignment: [
                { displayName: participantNameDisplay, id: participant?.id },
              ],
              availableStatuses: taskStatuses,
              endTime: deadline,
              id: taskId,
              name,
              procedureId,
              status: Status.ToDo,
              type,
            },
          ]);
        }

        message = (
          <ItemChangedMessage
            action={
              <Button
                color="inherit"
                onClick={() =>
                  goTo(
                    type === TaskType.TbqResolve
                      ? 'objectTasks'
                      : 'projectQualityControlTasks',
                    {
                      params: {
                        objectId: objId,
                        projectId: projectId
                          ? projectId
                          : objectProjectId || undefined,
                      },
                      searchParams: { action: 'viewTask', id: taskId },
                    }
                  )
                }
                size="small"
              >
                {t('View task')}
              </Button>
            }
            description={MessageAction.Create}
            entityName={name}
            entityTypeTranslation={EntityTypeTranslation.Task}
          />
        );

        helpers.resetForm();
      }

      if (type === TaskType.TbqResolve) {
        if (tbqQuestions) {
          const batch = tbqQuestions.flatMap((question) => {
            let sectionId = '';
            let instanceId = '';
            let scanLinkedTaskId = '';

            if (
              question &&
              tbqBrandcheckQcInstance &&
              tbqBrandcheckQcInstance.task &&
              isTbqBrandcheck(question)
            ) {
              sectionId = question.sectionId;
              instanceId = tbqBrandcheckQcInstance?.id;
              scanLinkedTaskId = tbqBrandcheckQcInstance.task?.id;
            } else if (question && isTbqRie(question)) {
              sectionId = question.section.id;
              instanceId = question?.instanceId || '';
              scanLinkedTaskId = question.taskId || '';
            }

            if (
              !sectionId ||
              !scanLinkedTaskId ||
              !taskId ||
              !instanceId ||
              !objectId
            ) {
              return [];
            }
            return [
              {
                fieldId: sectionId,
                resourceAId: taskId,
                resourceAType: ResourceType.Task,
                resourceBId: instanceId,
                resourceBType: ResourceType.TbqInstance,
              },
              {
                fieldId: sectionId,
                resourceAId: taskId,
                resourceAType: ResourceType.Task,
                resourceBId: scanLinkedTaskId,
                resourceBType: ResourceType.Task,
              },
              {
                fieldId: sectionId,
                resourceAId: taskId,
                resourceAType: ResourceType.Task,
                resourceBId: objectId,
                resourceBType: ResourceType.Object,
              },
            ];
          });

          if (batch.length > 0) {
            await addQuestionsToResolveTaskBatch({
              variables: {
                batch: batch,
              },
            });
          }

          if (isTbqBrandcheck(tbqQuestions[0])) {
            handleCloseTBQBrandcheckQuestionSidebar();
          } else {
            searchParams.clear();
          }
        }
      }

      if (
        type === TaskType.QualityControl ||
        type === TaskType.Tbq ||
        type === TaskType.TbqScan
      ) {
        const templateIds = ([...formTemplates, ...snagTemplates] || []).map(
          ({ id }) => id
        );

        await setTaskTemplateLinks({
          variables: {
            taskId,
            templateIds,
          },
        });

        const visualContextIds = visualContexts.map(({ id }) => id);

        await setTaskVisualContextLinks({
          variables: {
            taskId,
            visualContextIds,
          },
        });
      }

      enqueueSnackbar(message);
      goBack();
    } catch (e) {
      showSingleError(e);
    }
  };
};
