import React from 'react';

import { Stamp, StampsQuery } from '@pro4all/graphql';

import { useRevoke } from '../data';
import {
  Action,
  StamperDocument,
  State,
  Status,
  toRemoveStampInput,
} from '../store';

export function useRevokeOne(
  queue: StamperDocument[],
  data: StampsQuery | undefined,
  refetch: () => void,
  state: State,
  dispatch: React.Dispatch<Action>
) {
  const revoke = useRevoke();

  const revokeOne = async () => {
    dispatch({ status: Status.Pending, type: 'status' });
    const current = state.queue[state.index];
    const currentStamps = (data?.stamps?.data ?? []).filter(
      (stamp): stamp is Stamp =>
        stamp !== null && current?.id === stamp.versionId
    );
    revoke(toRemoveStampInput(current?.documentId, currentStamps));
    refetch();
    dispatch({ type: 'next' });
  };

  return { revokeOne };
}
