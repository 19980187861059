import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import {
  Template,
  TemplateType,
  useGetTbqGlobalScopeLazyQuery,
} from '@pro4all/graphql';
import { useTBQTemplates } from '@pro4all/metadata/data-access';
import { useAppPermissions } from '@pro4all/shared/identity';
import { Box } from '@pro4all/shared/mui-wrappers';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import { FilterContextProvider } from '@pro4all/shared/ui/filtering';
import { Select } from '@pro4all/shared/ui/inputs';
import { Loader } from '@pro4all/shared/ui/loader';
import {
  Table,
  TableContextProvider,
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';

import { SetsActionBar } from '../action-bars/SetsActionBar';
import { BigMessage } from '../big-message/BigMessage';
import { useColumnsTemplates } from '../columns/useColumnsTemplates';

import {
  CustomActionBarWrapper,
  CustomDefaultActionBarWrapper,
} from './CustomActionBarWrapper';

export const ScreenTBQTemplates = () => {
  const history = useHistory();
  const {
    searchParams,
    params: { tbqScopeId },
  } = useRouting();
  const [getScopes] = useGetTbqGlobalScopeLazyQuery();
  const columnsSets = useColumnsTemplates();
  const {
    state: { items },
  } = useOptimisticResponseContext<Template>();
  useEffect(() => {
    const asyncScopes = async () => {
      const response = await getScopes();
      const scopeOptions: Option[] = response?.data?.getTBQGlobalScope?.map(
        (gs) => ({
          id: gs.scopeId,
          label: gs.name,
        })
      );
      const selectOption = tbqScopeId
        ? scopeOptions.find((so) => so.id === tbqScopeId)
        : scopeOptions[0];
      setSelectedOption(selectOption);
      setScopeOptions(scopeOptions);

      const route = generateRoute('metaDataQualityControlTBQTemplatesScope', {
        params: {
          tbqScopeId: selectOption.id,
        },
      });
      const actionParam = searchParams.get('action');
      history.push(route, { previousRouteParam: actionParam });
    };
    asyncScopes();
  }, [getScopes]);

  const [scopeOptions, setScopeOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>();

  function handleChange(e: Option): void {
    setSelectedOption(e);
    const route = generateRoute('metaDataQualityControlTBQTemplatesScope', {
      params: {
        tbqScopeId: e.id,
      },
    });
    const actionParam = searchParams.get('action');
    history.push(route, { previousRouteParam: actionParam });
  }

  return (
    <FilterContextProvider<Template>>
      <TableContextProvider
        columns={[...columnsSets]}
        id={`table-templates-${TemplateType.Form}-global`}
        items={items}
      >
        <>
          <CustomActionBarWrapper>
            <Box>
              {selectedOption && (
                <StyledSelect
                  defaultValue={scopeOptions[0]}
                  name="tbqScope"
                  onChange={(e) => handleChange(e)}
                  options={scopeOptions}
                  value={selectedOption}
                />
              )}
            </Box>
            <CustomDefaultActionBarWrapper>
              <SetsActionBar />
            </CustomDefaultActionBarWrapper>
          </CustomActionBarWrapper>
          {selectedOption?.id ? (
            <TableComponent scopeId={selectedOption?.id} />
          ) : (
            <BigMessage />
          )}
        </>
      </TableContextProvider>
    </FilterContextProvider>
  );
};

const TableComponent = ({ scopeId }: { scopeId: string }) => {
  const { hasAppPermission } = useAppPermissions();
  const {
    loading: loadingTemplates,
    templates,
    selectTemplate,
  } = useTBQTemplates({
    scopeId,
  });
  useSetItemsInLocalState<Template>(templates);

  return (
    <Table<Template>
      emptyRenderer={loadingTemplates ? <Loader /> : <BigMessage />}
      onRowClick={(row) =>
        hasAppPermission('OrganizationUpdate') && selectTemplate(row)
      }
      rowHeight={26}
    />
  );
};

const StyledSelect = styled(Select)`
  && {
    width: 170px;
  }
`;
