import type { Key } from 'react';
import { useCallback } from 'react';

import { Folder } from '@pro4all/graphql';
import { createUseState } from '@pro4all/shared/hooks';
import { DMSProjectLink } from '@pro4all/shared/types';
import type { UseToggleProviderOptions } from '@pro4all/shared/ui/toggle';
import { useToggleProvider } from '@pro4all/shared/ui/toggle';

interface UseTableRowToggleArgs
  extends Pick<
    UseToggleProviderOptions<string>,
    'filter' | 'initial' | 'onToggle'
  > {
  folders?: (Folder & DMSProjectLink)[];
  key: Key;
  onMultipleToggle?: (ids: string[], toggled: boolean) => void | Promise<void>;
}

export function useTableRowToggle({
  key,
  onMultipleToggle,
  folders = [],
  ...rest
}: UseTableRowToggleArgs) {
  const useState = createUseState<string[]>(key.toString(16));
  const baseToggle = useToggleProvider<string>({ ...rest, useState });

  const toggleMultiple = useCallback(
    async (ids: string[], toggled?: boolean) => {
      // Determine if we should toggle based on the first ID
      const shouldToggle = toggled ?? !baseToggle.toggles.includes(ids[0]);

      // Filter out folders that have children already loaded
      const elementsToLoad = ids.filter(
        (id) => !folders.some((folder) => folder.parentFolderId === id)
      );

      // Call the onMultipleToggle callback if provided
      if (onMultipleToggle && elementsToLoad.length) {
        await onMultipleToggle(elementsToLoad, shouldToggle);
      }

      // Update internal state
      baseToggle.setToggles((prev) =>
        shouldToggle
          ? prev.filter((id) => !ids.includes(id))
          : [...new Set([...prev, ...ids])]
      );
    },
    [baseToggle, onMultipleToggle, folders]
  );

  return {
    ...baseToggle,
    toggleMultiple,
  };
}
