import { useTranslation } from 'react-i18next';

import { Icon } from '@pro4all/shared/ui/icons';
import { Timestamp } from '@pro4all/shared/ui/timestamp';

import * as Styled from '../styles/InvoiceData.styles';
import { useSubscriptionContext } from '../SubscriptionContext';

import {
  SubscriptionStatus,
  useSubscriptionHelpers,
} from './useSubscriptionHelpers';

export const useInvoiceDataProperties = () => {
  const { t } = useTranslation();
  const { dataOrg } = useSubscriptionContext();
  const { billingEmail, nextBillingCycle, paymentInfo } =
    dataOrg?.subscriptionOrganization || {};
  const { last3Digits, type } = paymentInfo || {};
  const { getCurrentSubscriptionStatus } = useSubscriptionHelpers();

  const isTrial = getCurrentSubscriptionStatus() === SubscriptionStatus.Trial;

  return [
    {
      label: t('Payment'),
      value: isTrial ? (
        t('No payments')
      ) : (
        <Timestamp date={nextBillingCycle} format="ll" />
      ),
    },
    {
      label: t('Cardnumber'),
      value: isTrial ? (
        <Styled.NoDataWarning>
          <Icon iconName="warning" label={t('No data available')} />
        </Styled.NoDataWarning>
      ) : (
        `xxxx xxxx xxxx x${last3Digits || 'xxx'}`
      ),
    },
    {
      label: t('Payment type'),
      value: isTrial ? (
        <Styled.NoDataWarning>
          <Icon iconName="warning" label={t('No data available')} />
        </Styled.NoDataWarning>
      ) : type === 0 ? (
        t('Creditcard')
      ) : type === 1 ? (
        t('Bank account')
      ) : (
        t('Unknown')
      ),
    },
    {
      label: t('Invoice email'),
      value: billingEmail || '-',
    },
  ];
};
