import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Connection } from '@pro4all/graphql';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { ConnectionsTable } from '@pro4all/projects/ui/settings/src/connections';
import { ProjectSettingsFormEdit } from '@pro4all/projects/ui/settings/src/settings';
import { ProjectFormEdit } from '@pro4all/projects/ui/sidebar';
import { ProjectSidebar } from '@pro4all/projects/ui/sidebar';
import { Routes } from '@pro4all/shared/config';
import { useIsAdminInContext } from '@pro4all/shared/identity';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { Main } from '@pro4all/shared/ui/layout';
import { BigMessageNoAccess } from '@pro4all/shared/ui/messages';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/table';

import { ProjectsActionBar } from './ProjectsActionBar';
import * as Styled from './ProjectSettings.styled';
import { ProjectSettingsTabs } from './ProjectSettingsTabs';

export const ProjectSettings = () => {
  const {
    params: { projectId },
  } = useRouting();
  const base = generateRoute('project', { params: { projectId } });
  const { isExternalUserInProject } = useProjectContext();
  const isAdminInContext = useIsAdminInContext();

  return (
    <>
      <Route exact path={`${base}${Routes.settings}`}>
        <Redirect to={`${base}${Routes.baseSettingsProject}`} />
      </Route>
      <Route path={Routes.projectSettingsProject}>
        {!isAdminInContext ? (
          <BigMessageNoAccess />
        ) : (
          <>
            <ProjectSettingsTabs />
            <Main>
              <ProjectSidebar />
              <Styled.Wrapper>
                <ProjectsActionBar />
                <ProjectFormEdit />
              </Styled.Wrapper>
            </Main>
          </>
        )}
      </Route>
      <Route path={Routes.projectSettingsConnections}>
        {isExternalUserInProject || !isAdminInContext ? (
          <BigMessageNoAccess />
        ) : (
          <OptimisticResponseProvider<Connection>>
            <>
              <ProjectSettingsTabs />
              <Main>
                <ConnectionsTable />
              </Main>
            </>
          </OptimisticResponseProvider>
        )}
      </Route>
      <Route path={Routes.projectSettingsDocuments}>
        {isExternalUserInProject || !isAdminInContext ? (
          <BigMessageNoAccess />
        ) : (
          <>
            <ProjectSettingsTabs />
            <Main p={2}>
              <Styled.Wrapper>
                <ProjectSettingsFormEdit />
              </Styled.Wrapper>
            </Main>
          </>
        )}
      </Route>
    </>
  );
};
