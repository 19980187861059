import { Document, SearchDocument, User } from '@pro4all/graphql';

export const searchToDocument = (searchDocument: SearchDocument): Document => {
  const { createdBy, deletedBy, updatedBy, ...rest } = searchDocument;

  // Transform SearchUser into User by adding Ids
  const documentCreatedBy: User = {
    id: `placeholder-id-${createdBy?.displayName}`,
    ...createdBy,
    __typename: 'User',
    displayName: createdBy?.displayName || '',
  };
  const documentDeletedBy: User = {
    id: `placeholder-id-${deletedBy?.displayName}`,
    ...deletedBy,
    __typename: 'User',
    displayName: deletedBy?.displayName || '',
  };
  const documentUpdatedBy: User = {
    id: `placeholder-id-${updatedBy?.displayName}`,
    ...updatedBy,
    __typename: 'User',
    displayName: updatedBy?.displayName || '',
  };

  const { metaData, ...restWithoutMetaData } = rest;

  return {
    ...restWithoutMetaData,
    __typename: 'Document',
    createdBy: documentCreatedBy,
    deletedBy: documentDeletedBy,
    metaData: {
      ...metaData,
      __typename: 'DocumentMetaData',
      answers: metaData?.answers?.map((answer) => ({
        ...answer,
        __typename: 'Instance',
        fieldDefinitionId: answer?.fieldDefinitionId || '',
      })),
    },
    updatedBy: documentUpdatedBy,
  };
};
