import React from 'react';

import { IconButton } from '@pro4all/shared/ui/buttons';

import { useFolderLevels } from './useFolderLevels';

const ExpandCollapseButtons = () => {
  const { expandNextLevel, collapseDeepestLevel } = useFolderLevels();

  return (
    <>
      <IconButton
        color="default"
        disableBorder
        iconName="addBox"
        onClick={expandNextLevel}
      />
      <IconButton
        color="default"
        disableBorder
        iconName="indeterminateCheckBox"
        onClick={collapseDeepestLevel}
      />
    </>
  );
};

export default ExpandCollapseButtons;
