import React from 'react';

import { IdentityService } from '@pro4all/shared/api-services';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { Avatar, AvatarSize } from '@pro4all/shared/ui/avatar';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { IconName } from '@pro4all/shared/ui/icons';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

import * as Styled from './Card.styles';
import { ProjectCardProps } from './Card.types';

export const ProjectCard = ({
  cardIcons,
  label,
  nameSuffix,
  projectId,
}: ProjectCardProps) => {
  const url = IdentityService.getProfileUrl({
    id: projectId,
    profilePictureEntity: ProfilePictureEntity.Project,
  });

  return (
    <Styled.CardWrapper>
      <Avatar name={label} size={AvatarSize.AvatarSizeS} src={url} />
      <Styled.DetailsWrapper>
        <Styled.HeadlineWrapper>
          <Styled.NameWrapper>
            <Styled.Name>
              <Text variant="h5">{label}</Text>
            </Styled.Name>
            {nameSuffix && (
              <Styled.NameAppendix>{nameSuffix}</Styled.NameAppendix>
            )}
          </Styled.NameWrapper>
        </Styled.HeadlineWrapper>
      </Styled.DetailsWrapper>

      {cardIcons && (
        <Styled.IconsWrapper>
          {cardIcons?.map(
            (icon) =>
              !icon.disabled && (
                <Tooltip placement="bottom" title={icon.label}>
                  <IconButton
                    color="default"
                    dataTestId={icon.dataTestId}
                    disableBorder
                    disabled={icon.disabled}
                    iconName={icon.startIcon as IconName}
                    onClick={icon.onClick}
                  />
                </Tooltip>
              )
          )}
        </Styled.IconsWrapper>
      )}
    </Styled.CardWrapper>
  );
};
