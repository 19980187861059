import React from 'react';
import { useField } from 'formik';

import { Option } from '@pro4all/shared/types';
import { Select, SelectProps } from '@pro4all/shared/ui/inputs';

export const FormikSelect: React.FC<
  Omit<SelectProps, 'onChange' | 'value'> & {
    onChange?: (option: Option) => void;
  }
> = ({ name, onChange, onBlur, ...props }) => {
  const [field, meta, helpers] = useField({ name });

  const handleChange = (option: Option) => {
    helpers.setTouched(true);
    helpers.setValue(option);
    onChange && onChange(option);
  };

  const handleBlur = (option: Option) => {
    helpers.setTouched(true);
    onBlur && onBlur(option);
  };

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <Select
      {...props}
      {...field}
      error={hasError && Boolean(meta.error)}
      helperText={hasError && meta.error}
      id={name}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};
