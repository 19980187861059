import styled from 'styled-components';

import { Dialog as MuiDialog } from '@pro4all/shared/mui-wrappers';

export const DialogContainer = styled(MuiDialog)<{
  $fitWidth?: boolean;
  $noHorizontalPadding?: boolean; // Nieuw toegevoegde prop
}>`
  && {
    .MuiPaper-root {
      max-width: ${({ $fitWidth }) => ($fitWidth ? 'unset' : '600px')};
      width: ${({ $fitWidth }) => ($fitWidth ? 'fit-content' : '100%')};
      padding: ${({ $noHorizontalPadding }) =>
        $noHorizontalPadding
          ? ({ theme }) => theme.spacing(4) + ' 0'
          : ({ theme }) => theme.spacing(4)};
    }
  }
`;

export const DialogHeader = styled.header`
  padding: 0 ${({ theme }) => theme.spacing(1)};
`;
