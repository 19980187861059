import { useTranslation } from 'react-i18next';

import { useGotoStamper } from '@pro4all/documents/ui/stamper';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useStampAction = ({
  editableDocuments,
  editableVersions,
  isLocked,
}: Pick<
  DocumentActionProps,
  'editableDocuments' | 'editableVersions' | 'isLocked'
>) => {
  const { t } = useTranslation();
  const isPdf = (extension?: string) => extension?.replace('.', '') === 'pdf';

  const stampAction: ActionProps = {
    ariaLabel: t('QR codes'),
    dataTestId: 'stamp-document',
    disabled:
      isLocked ||
      (editableDocuments.length === 0 && editableVersions.length === 0) ||
      editableDocuments.some(({ isExpected }) => isExpected) ||
      editableVersions.some(({ isExpected }) => isExpected) ||
      !(
        editableDocuments.some(({ extension }) => isPdf(extension ?? '')) ||
        editableVersions.some(({ extension }) => isPdf(extension ?? ''))
      ),
    key: 'stamp-document',
    label: t('QR codes'),
    onClick: useGotoStamper(
      editableDocuments
        .filter(({ extension }) => isPdf(extension))
        .map(({ id: documentId, name, versionId: id }) => ({
          documentId,
          id,
          name,
        }))
        .concat(
          editableVersions
            .filter(({ extension }) => isPdf(extension))
            .map(({ documentId, name, id }) => ({
              documentId,
              id,
              name,
            }))
        )
    ),
    startIcon: 'stamp',
  };

  return stampAction;
};
