import React, { useEffect, useState } from 'react';

import { IdentityService } from '@pro4all/shared/api-services';
import { AvatarGroup, Box } from '@pro4all/shared/mui-wrappers';
import { useGenericContext } from '@pro4all/shared/providers';
import { ProfilePictureEntity } from '@pro4all/shared/types';
import { Avatar, AvatarSize } from '@pro4all/shared/ui/avatar';
import { Tooltip } from '@pro4all/shared/ui/tooltip';
import { Text } from '@pro4all/shared/ui/typography';

export const OrganizationTitle = ({
  organizationId,
  organizationName,
  projectId,
  projectName,
}: {
  organizationId: string;
  organizationName: string;
  projectId?: string;
  projectName?: string;
}) => {
  const [profilePictureOrg, setProfilePictureOrg] = useState<
    string | undefined
  >(undefined);

  const [profilePictureProject, setProfilePictureProject] = useState<
    string | undefined
  >(undefined);

  const {
    state: { reloadProfilePictures },
  } = useGenericContext();

  useEffect(() => {
    const urlOrganizationProfilePicture = IdentityService.getProfileUrl({
      bypassCache: true,
      id: organizationId,
      profilePictureEntity: ProfilePictureEntity.Organization,
    });
    setProfilePictureOrg(urlOrganizationProfilePicture);

    const urlProjectProfilePicture = projectId
      ? IdentityService.getProfileUrl({
          bypassCache: true,
          id: projectId,
          profilePictureEntity: ProfilePictureEntity.Project,
        })
      : '';
    setProfilePictureProject(urlProjectProfilePicture);
  }, [organizationId, projectId, reloadProfilePictures]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mb: 1 }}>
        <AvatarGroup sx={{ alignItems: 'center', display: 'flex' }}>
          <Avatar
            name={organizationName}
            size={AvatarSize.AvatarSizeXL}
            src={profilePictureOrg}
          />
          {projectId && (
            <Avatar
              name={projectName}
              size={AvatarSize.AvatarSizeL}
              src={profilePictureProject}
            />
          )}
        </AvatarGroup>
      </Box>
      <Tooltip placement="bottom" title={organizationName}>
        <Text className="organization-title" variant="h4">
          {organizationName}
        </Text>
      </Tooltip>
    </Box>
  );
};
