import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import {
  SmartFolder as SmartFolderType,
  useDeleteSmartFolderMutation,
  useSmartFoldersQuery,
} from '@pro4all/graphql';
import { Box, Collapse, useTheme } from '@pro4all/shared/mui-wrappers';
import {
  updateLsLastVisitedProjectFolder,
  useRouting,
} from '@pro4all/shared/routing-utils';
import { useIsMobileScreen } from '@pro4all/shared/themes';
import { ActionProps } from '@pro4all/shared/types';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';
import { Link } from '@pro4all/shared/ui/link';
import { Loader } from '@pro4all/shared/ui/loader';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';
import { ResponseWrapper } from '@pro4all/shared/ui/response-wrapper';
import {
  useOptimisticResponseContext,
  useSetItemsInLocalState,
} from '@pro4all/shared/ui/table';
import { Text } from '@pro4all/shared/ui/typography';

import SmartFolder from '../smart-folder/SmartFolder';
import { SmartFolderCreate } from '../smart-folder-create/SmartFolderCreate';
import { SmartFolderEdit } from '../smart-folder-edit/SmartFolderEdit';

const StyledHeader = styled(Box)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => `0 ${theme.spacing(2)} 0`};
  }
`;

export const SmartFolders = ({
  resetInitialItems,
}: {
  resetInitialItems: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { goTo, params, searchParams } = useRouting();
  const { name, projectId } = params;
  const [showMoreOpen, setShowMoreOpen] = useState<boolean>(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  /**
   * We lose the currently selected smart folder on close of the context menu,
   * and we use the name in the Delete Snackbar. We should either get the name back
   * from the API (now we only get the ID back), or refactor how we handle
   * selectedSmartFolder in combination with the context menu.
   * TODO: Figure out the best approach to deal with these situations
   * */
  const [smartFolderToBeDeletedName, setSmartFolderToBeDeletedName] =
    useState<string>(null);
  const [selectedSmartFolder, setSelectedSmartFolder] =
    useState<SmartFolderType | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const isMobileScreen = useIsMobileScreen();

  const { data, loading, error } = useSmartFoldersQuery({
    /** TODO: Remove this once we have a proper merge startegy for subscriptions */
    fetchPolicy: 'no-cache',
    variables: {
      projectId,
    },
  });

  const {
    deleteItems,
    state: { items },
  } = useOptimisticResponseContext<SmartFolderType>();

  const [deleteSmartFolder] = useDeleteSmartFolderMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <ItemChangedMessage
          description={MessageAction.Delete}
          entityName={smartFolderToBeDeletedName}
          entityTypeTranslation={EntityTypeTranslation.SmartFolder}
        />
      );
      setSmartFolderToBeDeletedName(null);
    },
  });

  const openContextMenu = (
    event: React.MouseEvent,
    smartFolder: SmartFolderType
  ) => {
    if (event) {
      event.preventDefault();
    }
    setSelectedSmartFolder(smartFolder);
    setIsContextMenuOpen(true);
  };

  const closeContextMenu = () => {
    setSelectedSmartFolder(null);
    setIsContextMenuOpen(false);
  };

  const onDeleteSmartFolder = async () => {
    const { id, name } = selectedSmartFolder;
    setSmartFolderToBeDeletedName(name);
    closeContextMenu();
    await deleteSmartFolder({
      variables: { id },
    });
    deleteItems([id]);
    if (items.length === 1) {
      updateLsLastVisitedProjectFolder({
        params: {},
        projectId,
        route: 'projectSmartFolder',
      });
    }
    goTo(projectId ? 'projectDocs' : 'docs', { params: { projectId } });
  };

  const onEditSmartFolder = async () => {
    closeContextMenu();

    goTo(projectId ? 'projectSmartFolder' : 'smartFolder', {
      params: { name: selectedSmartFolder.name, projectId },
      searchParams: { action: 'editSmartFolder' },
    });
  };

  const onNewSmartFolder = async () => {
    closeContextMenu();
    searchParams.set('action', 'createSmartFolder');
  };

  const handleClick = () => {
    setShowMoreOpen(!showMoreOpen);
  };

  const listMax = 3;

  const defaultMenu: ActionProps[] = [
    {
      ariaLabel: `Edit smart folder`,
      dataTestId: 'edit-smart-folder',
      key: 'edit-smart-folder',
      label: t('Edit smart folder'),
      onClick: onEditSmartFolder,
      startIcon: 'edit',
    },
    {
      ariaLabel: `Remove smart folder`,
      dataTestId: 'remove',
      key: 'remove-smart-folder',
      label: t('Remove smart folder'),
      onClick: onDeleteSmartFolder,
      startIcon: 'delete',
    },
  ];

  const smartFolders = useMemo(
    () => data?.smartFolders || [],
    [data?.smartFolders]
  );

  useSetItemsInLocalState<SmartFolderType>(smartFolders);

  const renderSmartFolder = (
    smartFolder: SmartFolderType,
    projectId?: string
  ) => (
    <SmartFolder
      key={smartFolder.id}
      {...smartFolder}
      onContextMenu={(event: React.MouseEvent) =>
        openContextMenu(event, smartFolder)
      }
      projectId={projectId}
      resetInitialItems={resetInitialItems}
      selected={name === smartFolder.name}
    />
  );

  return (
    !isMobileScreen && (
      <ResponseWrapper
        CustomLoadingComponent={<Loader fullHeight={false} />}
        error={error}
        isLoading={loading}
      >
        <Box marginY={2}>
          <StyledHeader>
            <Text variant="h5">{t('Smart folders')}</Text>
            <Link
              color="inherit"
              data-testid="new-smart-folder"
              onClick={onNewSmartFolder}
              startIcon="folderNew"
            >
              {t('New')}
            </Link>
          </StyledHeader>
          <ContextMenu
            data-testid="context-menu"
            menuItems={defaultMenu}
            onClose={closeContextMenu}
            open={isContextMenuOpen}
          />
          {!items.length && (
            <Box
              display="flex"
              justifyContent="center"
              padding={theme.spacing(1)}
            >
              <Text variant="body2">{t('No smart folders found')}</Text>
            </Box>
          )}
          {items
            .slice(0, listMax)
            .map((smartFolder) => renderSmartFolder(smartFolder, projectId))}
          <Collapse in={showMoreOpen} timeout="auto" unmountOnExit>
            {items
              .slice(listMax)
              .map((smartFolder) => renderSmartFolder(smartFolder, projectId))}
          </Collapse>
          {items.length > listMax && (
            <Box paddingLeft={2}>
              <Link onClick={handleClick}>
                {!showMoreOpen ? t('Show more') : t('Show less')}
              </Link>
            </Box>
          )}
          <SmartFolderCreate />
          <SmartFolderEdit smartFolders={items} />
        </Box>
      </ResponseWrapper>
    )
  );
};

export default SmartFolders;
