import React from 'react';
import styled from 'styled-components';

import { useSideNavContext } from '@pro4all/shared/contexts';
import {
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import { Breadcrumbs } from './Breadcrumbs';
import { HelpMenu } from './HelpMenu';
import { UserNameTopBar } from './UserNameTopBar';

const Nav = styled.nav`
  @media print {
    display: none;
  }

  align-items: center;
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)};

  > * + * {
    margin-left: 24px;
  }

  > :first-child {
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.up('md')}) {
    > * + * {
      margin-left: 5px;
    }
  }
`;

interface Props {
  projectName?: string;
}

export const TopBar: React.FC<Props> = ({ projectName }) => {
  const theme = useTheme();
  const showDeviceUi = useMediaQuery(theme.breakpoints.down('md'));
  const { setSideNavVisibleOnMobile } = useSideNavContext();
  return (
    <>
      <Nav>
        {showDeviceUi && (
          <IconButton onClick={() => setSideNavVisibleOnMobile(true)}>
            <Icon iconName="menu" />
          </IconButton>
        )}
        {!showDeviceUi && <Breadcrumbs projectName={projectName} />}
        <HelpMenu />
        <UserNameTopBar />
      </Nav>
      <Divider />
    </>
  );
};
