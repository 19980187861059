import { TreeFolder } from '../types';

export const filterFolderTree = (folderTree: TreeFolder[]): TreeFolder[] => {
  // Recursive function to process a single folder
  const filterFolder = (folder: TreeFolder): TreeFolder | null => {
    // If there are subfolders, filter them recursively
    const filteredChildren: TreeFolder[] = folder.children
      ? folder.children
          .map(filterFolder)
          .filter((child): child is TreeFolder => child !== null)
      : [];

    // Update childrenwith the filtered list
    folder.children = filteredChildren;

    // Check if the current folder should be included
    const shouldInclude: boolean =
      folder.hasDocuments ||
      folder.hasDocumentsInSubfolders ||
      filteredChildren.length > 0;

    return shouldInclude ? folder : null;
  };

  // Apply filter function to the root folderTree
  return folderTree
    .map(filterFolder)
    .filter((folder): folder is TreeFolder => folder !== null);
};
