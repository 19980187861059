import { useEffect, useState } from 'react';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { IdentityService } from '@pro4all/shared/api-services';
import { useGenericContext } from '@pro4all/shared/providers';
import { FetchProfilePictureProps } from '@pro4all/shared/types';

export const useFetchProfilePicture = ({
  id,
  profilePictureEntity,
}: FetchProfilePictureProps) => {
  const accessTokenValue = AuthService.getToken();
  const [currentProfileImage, setCurrentProfileImage] = useState<
    File | undefined
  >(undefined);
  const [currentProfileImageUrl, setCurrentProfileImageUrl] = useState<
    string | undefined
  >(undefined);

  const {
    state: { reloadProfilePictures },
  } = useGenericContext();

  useEffect(() => {
    if (id) {
      const fetchProfilePicture = async () => {
        const url = IdentityService.getProfileUrl({
          bypassCache: true,
          id,
          profilePictureEntity,
        });
        if (url) {
          setCurrentProfileImageUrl(url);
          try {
            const file = await IdentityService.getProfilePicture({
              url,
            });
            if (file) {
              setCurrentProfileImage(file); // Store the File in state
            } else {
              setCurrentProfileImage(undefined); // Reset state in case user removed profile picture
            }
          } catch (error) {
            setCurrentProfileImage(undefined); // Reset state in case of an error
          }
        }
      };

      fetchProfilePicture();
    }
  }, [accessTokenValue, id, profilePictureEntity, reloadProfilePictures]);

  return { currentProfileImage, currentProfileImageUrl };
};
