import React from 'react';
import styled from 'styled-components';

import { CommunicationService } from '@pro4all/communication/data-access';
import { SharedAttachments } from '@pro4all/graphql';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { MiddleEllipsis } from '@pro4all/shared/ui/middle-ellipsis';
import { LabelHeader } from '@pro4all/shared/ui/table';

export const usePublicAttachmentColumn = () => {

  const attachmentColumn = {
    filterable: true,
    headerRenderer: () => (
      <LabelHeader label="Name" /> // i18n
    ),
    key: 'name',
    minWidth: 100,
    render: (attachment: SharedAttachments) => (
      <>
        <StyledIconButton
          disableBorder
          iconName="download"
          onClick={() =>
            // TODO: Discuss with BE to see what download service to use (presigned, direct etc...)
            CommunicationService.downloadPublicAttachmentDirect({
              fileName: attachment.name,
              url: attachment.downloadUrl,
            })
          }
        />
        <MiddleEllipsis endLength={9} text={attachment.name} />
      </>
    ),
  };
  return {
    attachmentColumn,
  };
};

const StyledIconButton = styled(IconButton)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)};
    color: ${customColors.grey600};
  }
`;
