import { FormikHelpers } from 'formik/dist/types';
import { v4 as uuid } from 'uuid';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import {
  FieldDefinition,
  FieldDefinitionTypesQuery,
  ScopeType,
  useCreateFieldDefinitionMutation,
  useUpdateFieldDefinitionMutation,
} from '@pro4all/graphql';
import { StorageKeys } from '@pro4all/shared/config';
import { useLocalStorage } from '@pro4all/shared/hooks';
import {
  getAuthUserDisplayName,
  useContextScopedOrganizationId,
} from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { MessageAction, useShowMessages } from '@pro4all/shared/ui/messages';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/table';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';
import {
  useFieldContext,
  useFieldSidebarContext,
} from '../form-template/TemplateMutationContext';

import { getFieldDefinitionTypeInput } from './getFieldDefinitionTypeInput';
import { FormFields } from './Types';

export const useSubmit = ({
  data,
  isReusable,
  onClose,
  selectedField,
}: {
  data: FieldDefinitionTypesQuery;
  isReusable: boolean;
  onClose: () => void;
  selectedField?: FieldDefinition;
}) => {
  const { field } = useFieldSidebarContext();
  const { appendField, editField } = useFieldContext();
  const [createFieldDefinition] = useCreateFieldDefinitionMutation();
  const [updateFieldDefinition] = useUpdateFieldDefinitionMutation();
  const { templateType, templateService } = useMetaDataContext();
  const { showMutationMessage, showSingleError } = useShowMessages();
  const editMode = Boolean(selectedField);
  const { userId } = AuthService.getProfile();
  const userName = getAuthUserDisplayName();

  const {
    addItems,
    editItems,
    state: { items },
  } = useOptimisticResponseContext<FieldDefinition>();

  const selectedItem = items.find((item) => item.id === selectedField?.id);

  const getContextScopedOrganizationId = useContextScopedOrganizationId();
  const organizationId = getContextScopedOrganizationId();

  const { entityType } =
    featureConfig[templateService].fieldTypes[templateType] || {};

  const { params } = useRouting();
  const { projectId } = params;

  const {
    getLocalStorageItem: getPublishTemplatesAfterFieldUpdateLocalStorage,
  } = useLocalStorage<boolean>({
    key: StorageKeys.PUBLISH_TEMPLATES_AFTER_FIELD_UPDATE,
  });

  return async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
    const { description, displayDescription, displayName, name, type } = values;

    const fieldDefinitionTypeInput = getFieldDefinitionTypeInput({
      type: type.id,
      valueType: values,
    });

    const defaultProps = {
      description,
      displayDescription,
      displayName,
      name,
    };

    if (!isReusable) {
      const typeId = data.fieldDefinitionTypes?.find(
        (fieldDefinitionType) => type.id === fieldDefinitionType.label
      ).id;

      if (field) {
        editField({
          ...field,
          ...defaultProps,
          valueType: {
            ...fieldDefinitionTypeInput,
            id: typeId,
          },
        });
      } else {
        appendField({
          ...defaultProps,
          id: uuid(),
          linksAllowed: false,
          required: false,
          type: type.id,
          valueType: {
            ...fieldDefinitionTypeInput,
            id: typeId,
          },
        });
      }
      onClose();
      return;
    }

    if (editMode) {
      try {
        await updateFieldDefinition({
          variables: {
            ...defaultProps,
            autoPublish:
              getPublishTemplatesAfterFieldUpdateLocalStorage() || false,
            fieldDefinitionTypeInput,
            id: selectedItem.id,
            templateService,
            type: type.id,
          },
        });

        editItems([
          {
            ...selectedItem,
            description,
            displayDescription,
            displayName,
            name,
          },
        ]);

        onClose();
        showMutationMessage({
          action: MessageAction.Update,
          entityType,
          name,
        });
      } catch (e) {
        showSingleError(e);
      }
    } else {
      const scope = projectId ? ScopeType.Project : ScopeType.Organization;
      try {
        const response = await createFieldDefinition({
          variables: {
            ...defaultProps,
            fieldDefinitionTypeInput,
            id: projectId ? projectId : organizationId,
            scope,
            templateService,
            type: type.id,
          },
        });

        const { id } = response.data?.createFieldDefinition ?? {};

        addItems([
          {
            __typename: 'FieldDefinition',
            createdAt: new Date().toISOString(),
            createdBy: { displayName: userName, id: userId },
            id,
            name,
            scope: { type: scope },
            type: type.id,
          },
        ]);

        helpers.resetForm();
        showMutationMessage({
          action: MessageAction.Create,
          entityType,
          name,
        });
      } catch (e) {
        showSingleError(e);
      }
    }
  };
};
