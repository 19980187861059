import { getParentFolders } from '../helpers/getParentFolders';
import { unflattenFolders } from '../helpers/unflattenFolders';
import { State } from '../types';

export const documentUploadInFolderAction = ({
  payload,
  state,
}: {
  payload: string;
  state: State;
}) => {
  const folderWithDocumentUploads = state.folders.find(
    (folder) => folder.id === payload
  );
  const folders = state.folders.filter((folder) => folder.id !== payload);

  const parentFolders = getParentFolders({
    folderId: payload,
    folders: state.folders,
  });

  // If a document has been successfully uploaded in a chold folder, the prop `hasDocumentsInSubfolders` should be true for all parent folders.
  const parentFoldersUpdated = parentFolders.map((parentFolder) => ({
    ...parentFolder,
    hasDocumentsInSubfolders: true,
  }));

  const parentFolderIds = parentFolders.map((folder) => folder.id);
  const foldersWithoutParents = folders.filter(
    (folder) => !parentFolderIds.includes(folder.id)
  );

  const foldersUpdated = [
    { ...folderWithDocumentUploads, hasDocuments: true }, // Set the prop `hasDocuments` to true for the folder where the document has been uploaded.
    ...parentFoldersUpdated,
    ...foldersWithoutParents,
  ];

  return {
    ...state,
    folderTree: unflattenFolders(foldersUpdated),
    folders: foldersUpdated,
    foldersPrevious: state.folders,
  };
};
