import { useState } from 'react';

import { Dimensions } from '../types';

export function useHandleDimensionsChange() {
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  const handleDimensionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/^0+/, ''); // Remove leading zeros
    const numericValue =
      sanitizedValue === '' ? sanitizedValue : Number(sanitizedValue);
    if (numericValue > 0 || sanitizedValue === '') {
      setDimensions((prev) => ({
        ...(prev || { height: 100, width: 100 }),
        [name]: numericValue,
      }));
    }
  };

  return { dimensions, handleDimensionsChange };
}
