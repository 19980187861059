import React, { createContext, useContext, useMemo, useState } from 'react';

import { Document } from '@pro4all/graphql';

import { ShareSnagstreamModal } from './ShareSnagstreamModal';

export interface ShareSnagstreamContextValues {
  existingVersionIds: string[];
  selectedConnection?: string;
  selectedDocuments: Document[];
  setExistingVersionIds: (value: string[]) => void;
  setSelectedConnection?: (connectionId: string) => void;
  setSelectedDocuments?: (value: Document[]) => void;
  setValidationScreen: (value: boolean) => void;
  validationScreen: boolean;
}

const ShareSnagstreamContext = createContext<ShareSnagstreamContextValues>({
  existingVersionIds: [],
  selectedConnection: '',
  selectedDocuments: [],
  setExistingVersionIds: function (): void {
    throw new Error('Function not implemented.');
  },
  setValidationScreen: function (): void {
    throw new Error('Function not implemented.');
  },
  validationScreen: false,
});

export const useShareSnagstreamContext = () =>
  useContext<ShareSnagstreamContextValues>(ShareSnagstreamContext);

export const ShareSnagstreamContextProvider: React.FC = ({ children }) => {
  const [selectedConnection, setSelectedConnection] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState<Document[]>([]);
  const [existingVersionIds, setExistingVersionIds] = useState<string[]>([]);
  const [validationScreen, setValidationScreen] = useState(false);

  const value = useMemo(
    () => ({
      existingVersionIds,
      selectedConnection,
      selectedDocuments,
      setExistingVersionIds,
      setSelectedConnection,
      setSelectedDocuments,
      setValidationScreen,
      validationScreen,
    }),
    [
      existingVersionIds,
      selectedConnection,
      selectedDocuments,
      setExistingVersionIds,
      setSelectedConnection,
      setSelectedDocuments,
      setValidationScreen,
      validationScreen,
    ]
  );

  return (
    <ShareSnagstreamContext.Provider value={value}>
      {children}
      <ShareSnagstreamModal />
    </ShareSnagstreamContext.Provider>
  );
};
