import React from 'react';
import { useTranslation } from 'react-i18next';

import { TaskType } from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { Button } from '@pro4all/shared/ui/buttons';
import {
  EntityTypeTranslation,
  ItemChangedMessage,
  MessageAction,
} from '@pro4all/shared/ui/messages';

type GoToOptions = {
  params?: {
    projectId?: string;
  };
  searchParams?: {
    action: string;
    id: string;
    taskType: TaskType;
  };
};

type Props = {
  goTo: (route: keyof typeof Routes, options?: GoToOptions) => void;
  id: string;
  name: string;
  projectId?: string;
  type: TaskType;
};

const SubmitMessage: React.FC<Props> = ({
  name,
  type,
  id,
  projectId,
  goTo,
}) => {
  const { t } = useTranslation();
  return (
    <ItemChangedMessage
      action={
        <Button
          color="inherit"
          onClick={() =>
            goTo(
              type === TaskType.Document
                ? 'projectDocsTasks'
                : 'projectQualityControlTasks',
              {
                params: {
                  projectId: projectId ? projectId : undefined,
                },
                searchParams: { action: 'viewTask', id, taskType: type },
              }
            )
          }
          size="small"
          variant="text"
        >
          {t('View task')}
        </Button>
      }
      description={MessageAction.Create}
      entityName={name}
      entityTypeTranslation={EntityTypeTranslation.Task}
    />
  );
};

export default SubmitMessage;
